import { AssetDocument, AssetDocumentConverter } from '../asset';

export interface NameAssetValue {
  name: string;
}

export type NameAssetDocument = AssetDocument<NameAssetValue>;

class NameAssetDocumentConverter extends AssetDocumentConverter<NameAssetValue> {
  override assetValueFromFirestore(assetValue: any) : NameAssetValue {
    return { name: assetValue.name };
  }

  override renderLabel(value: NameAssetValue): string {
    return value.name;
  }
}

export const nameAssetConverter = new NameAssetDocumentConverter();
