import React, { FC, SyntheticEvent, useCallback, useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardProps,
  Flex,
  Spacer,
  Text,
  Progress,
  VStack,
} from '@chakra-ui/react';

import { AssetDocument } from 'src/db';
import { AssetExplanation } from 'src/components';
import { AssetOriginTag } from 'src/components/assets';
import { RemoveAssetIconButton, useSelectAsset, useUnselectAsset, useRemoveAsset } from 'src/components/assets/actions';
import { UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';

interface AssetCardProps<T extends AssetDocument> extends CardProps {
  value: T,

  // If true, will show a progress bar instead of a primary action
  isInProgress?: boolean;  // Default `false`

  // If true, will show a Select/Unselect button (whichever applies).
  includeSelection?: boolean;  // Default `false`
  // If unspecified, this will call select/unselect api (whichever applies)
  selectionAction?: ((asset: T) => void)|null;
  // Default 'Select' / 'Unselect' (whichever applies)
  selectionLabel?: string;

  // If true, will show a "remove" red x in top right
  includeRemove?: boolean; // Default `false`

  // If true, will the Brandi explanation
  includeExplanation?: boolean; // Default `false`

  UpdateTextAssetButton?: FC<UpdateTextAssetButtonProps>;

}
export function AssetCard<T extends AssetDocument>({
  value,
  isInProgress = false,
  includeSelection = false,
  selectionAction = null,
  selectionLabel = '',
  includeRemove = false,
  includeExplanation = false,
  UpdateTextAssetButton,
  children,
  ...cardprops
}: AssetCardProps<T>) {
  const isSelected = value.selected;
  const selectAsset = useSelectAsset();
  const unselectAsset = useUnselectAsset();
  const removeAsset = useRemoveAsset();
  const [ isSelectPending, setIsSelectPending ] = useState<boolean>(false);
  const [ isRemovePending, setIsRemovePending ] = useState<boolean>(false);
  const isAnythingPending = isInProgress || isSelectPending || isRemovePending;

  const handleSelection = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (isSelectPending) { return; }
    if (!!selectionAction) {
      selectionAction(value);
    } else {
      if (isSelected) {
        unselectAsset(value, setIsSelectPending);
      } else {
        selectAsset(value, setIsSelectPending);
      }
    }
  }, [
    value, isSelected, isSelectPending,
    selectionAction, selectAsset, unselectAsset
  ]);

  const handleRemoveAsset = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (isRemovePending) { return; }
    removeAsset(value, setIsRemovePending);
  }, [value, isRemovePending, removeAsset]);

  return (
    <Card
      position='relative'
      bg={isSelected ? 'secondary.light' : 'grayscale.white'}
      mb={8} p={4}
      {...cardprops}
    >
      <Flex justify='end' align='center' mb={2}>
        <AssetOriginTag origin={value.origin}/>
        <Spacer flex='1 0 0' />
        {UpdateTextAssetButton && <UpdateTextAssetButton asset={value} />}
        {!isSelected && !isAnythingPending && 
          <RemoveAssetIconButton onClick={handleRemoveAsset} />}
      </Flex>
      <CardBody>
        {children}
      </CardBody>
      <CardFooter>
        <VStack align='center' width='100%'>
          {isAnythingPending && 
            <Progress
              size='md'
              width='100%'
              isIndeterminate={true} 
              my={4}
            />
          }
          {!isAnythingPending && includeSelection &&
            <Button
              colorScheme='primary'
              variant={isSelected ? 'outline' : 'fill'}
              my={4}
              onClick={handleSelection}
            >{selectionLabel || (isSelected ? 'Unselect' : 'Select')}</Button>
          }
          {!isAnythingPending && includeExplanation && <>
            {value.origin === 'AI' ?
              <AssetExplanation asset={value} bg='transparent' shadow='none' mx='auto'/> :
              <Text textStyle='bodySmall' m={4}>(Created by you)</Text>
            }</>
          }
        </VStack>
      </CardFooter>
    </Card>
  );
}
