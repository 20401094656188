import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';

export interface BusinessDocument {
  id: string;
  description: string;
  isBrandKitLocked: boolean;
  businessDifferentiator?: string;
  brandKitZipInfo?: {
    storageId: string;
    zipUrl: string;
  };
  isClaimed: boolean;
}

export const businessConverter : FirestoreDataConverter<BusinessDocument> = {
  toFirestore (document: BusinessDocument) {
    return {
      businessDescription: document.description,
      isBrandKitLocked: document.isBrandKitLocked,
      brandKitZipInfo: document.brandKitZipInfo,
      isClaimed: document.isClaimed,
      businessDifferentiator: document.businessDifferentiator
    };
  },

  fromFirestore (snapshot: DocumentSnapshot, options: SnapshotOptions): BusinessDocument{
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      description: data.businessDescription,
      isBrandKitLocked: data.isBrandKitLocked,
      brandKitZipInfo: data.brandKitZipInfo,
      isClaimed: data.isClaimed,
      businessDifferentiator: data.businessDifferentiator,
    };
  },
};
