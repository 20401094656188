import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  BoxProps,
  Text,
} from '@chakra-ui/react'
import Markdown from 'react-markdown'

import { ConversationContext } from './context';
import { Message } from './Message';

interface BubbleProps extends BoxProps {
  message: Message;
}

const backgroundColorMap =  {
  'ai': 'primary.veryLight',
  'human': 'secondary.veryLight',
};

const bubbleSidePadding = 4;

export function Bubble({message, ...boxProps}: BubbleProps) {
  const conversation = useContext(ConversationContext);
  const source = message.source;
  const body = message.body;
  const specialUiType = message.specialUiType || 'none';
  const radius='xl';

  if (message.specialUiType === 'aiSuggestion') {
    return (<Box
      as='button'
      borderColor='primary.medium'
      borderStyle='solid'
      borderWidth='1px'
      borderRadius='md'
      boxShadow='card'
      width='100%'
      onClick={() => {
        conversation.sendMessage({
          source: 'human',
          body: message.body,
        });
      }}
      {...boxProps}
    >
      <Text
        textAlign='center'
        textStyle='chat'
        px={bubbleSidePadding} py={2}
        whiteSpace='pre-line'
      >{message.body}</Text>
    </Box>);
  }

  return (
    <Box
      bg={backgroundColorMap[source]}
      borderRadius={radius}
      borderTopLeftRadius={source === 'ai' ? 'none' : radius}
      borderBottomRightRadius={source === 'human' ? 'none' : radius}
      opacity={specialUiType === 'userPending' ? 0.6 : 'none'}
      boxShadow='card'
      px={4} py={2}
      {...boxProps}
    >
      {specialUiType === 'none' && 
        <Box whiteSpace='pre-line' textStyle='chat'>
          <Markdown>{body}</Markdown>
        </Box>
      }
      {specialUiType === 'userPending' && 
        <Text
          whiteSpace='pre-line'
          textStyle='chat'
          fontStyle='italic'
        >{body}</Text>
      }
      {specialUiType === 'aiThinking' && 
        <ThemedEllipsis />
      }      
    </Box>
  );
}
Bubble.sidePadding = bubbleSidePadding;

function ThemedEllipsis() {
  const [activePosition, setActivePosition] = useState<number>(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setActivePosition((previous) => {
        // Intentionally using > not >=,
        // so the last dot is active for an extra tick.
        if (previous > 2) {
          return 0;
        } else {
          return previous +1;
        }
      });
    }, 150);
    return () => {
      clearInterval(timerId);
    };
  });

  return  (<>
    <Box
      borderRadius='full'
      boxSize='6px'
      display='inline-block'
      bg={activePosition === 0 ? 'primary.dark' : 'secondary.dark'}
      m='2px'
    />
    <Box
      borderRadius='full'
      boxSize='6px'
      display='inline-block'
      bg={activePosition === 1 ? 'primary.dark' : 'secondary.dark'}
      m='2px'
    />
    <Box
      borderRadius='full'
      boxSize='6px'
      display='inline-block'
      bg={activePosition >= 2 ? 'primary.dark' : 'secondary.dark'}
      m='2px'
    />
  </>)
}
