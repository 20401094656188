import { useCallback, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

import { SearchParam, useSearchParamState } from 'src/nav';
import { logger, LogSource } from 'src/util/logger';

export interface CreateBusinessPrefill {
  // Description *is* required to create the business,
  // but its optional to allow writing the fields in any order
  description?: string;
  name?: string;
  email?: string;
  logoUrl?: string;
}

type Prefill = CreateBusinessPrefill|null;

function decodePrefill(prefill: string) : Prefill {
  try {
    const decoded: any = JSON.parse(atob(decodeURIComponent(prefill)));
    return {
      description: decoded.description || '',
      name: decoded.name || undefined,
      email: decoded.email || undefined,
      logoUrl: decoded.logoUrl || undefined,
    };
  } catch (e) {
    logger.error(LogSource.APP, `Unable to parse prefilled business details: ${prefill}`, e as Error);
    return null;
  }
}

function encodePrefill(prefill: CreateBusinessPrefill): string {
  const prefillValue = encodeURIComponent(btoa(JSON.stringify({
    description: prefill.description, name: prefill.name, email: prefill.email, logoUrl: prefill.logoUrl,
  })));
  logger.info(LogSource.APP, `Encoded Prefill: ${SearchParam.createBusinessPrefill}=${prefillValue}`);
  return prefillValue;
}

type UpdatePrefillFn = (prefill: Prefill) => Prefill;

const isSamePrefill = (newPrefill: Prefill, oldPrefill: Prefill): boolean => {
  if (newPrefill === oldPrefill) { return true; }
  if ((!!newPrefill && !oldPrefill) || (!newPrefill && !!oldPrefill)) { return false; }
  if (newPrefill!.description !== oldPrefill!.description) { return false; }
  if (newPrefill!.name !== oldPrefill!.name) { return false; }
  if (newPrefill!.email !== oldPrefill!.email) { return false; }
  if (newPrefill!.logoUrl !== oldPrefill!.logoUrl) { return false; }
  return true;
}

export function useCreateBusinessPrefill()
    : [Prefill, (newValueOrUpdate: Prefill|UpdatePrefillFn) => void] {
  const toast = useToast();
  const [ prefillValue, setPrefillValue ] = useSearchParamState(SearchParam.createBusinessPrefill);
  const currentPrefill = !!prefillValue ? decodePrefill(prefillValue): null;
  useEffect(() => {
    if (!!prefillValue && !currentPrefill) {
      toast({
        status: 'error',
        isClosable: true,
        description: 'Unable to parse prefilled business details',
      });
      setPrefillValue(null);
    }
  }, [toast, prefillValue, setPrefillValue, currentPrefill]);
  
  const setPrefill = useCallback((newValueOrUpdate: Prefill|UpdatePrefillFn) => {
    let newPrefill: Prefill;
    if (typeof newValueOrUpdate === 'function') {
      newPrefill = (newValueOrUpdate as UpdatePrefillFn)(currentPrefill);
    } else {
      newPrefill = (newValueOrUpdate as Prefill);
    }
    if (isSamePrefill(newPrefill, currentPrefill)) { return; }
    if (!!newPrefill) {
      setPrefillValue(encodePrefill(newPrefill));
    } else {
      setPrefillValue(null);
    }
  }, [setPrefillValue, currentPrefill]);
  return [currentPrefill, setPrefill];
}
