import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { DocumentChange, Query, QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore';

import { ApiResponse, useApi } from 'src/api';
import { FirestoreContext, AgentThreadDocument, agentThreadConverter, useProfileId, useBusinessId, convertAgentToMessage } from 'src/db';
import { useEffectLate } from 'src/util/async';
import { logger, LogSource } from 'src/util/logger';

import { ConversationInterface } from './context';
import { Message } from './Message'; 

import {
  ActionType,
  ConversationDispatchAction,
  MessageAction,
  MessagesAction,
  ModalAction,
  dispatchInitialMessages,
  dispatchSendMessage,
  dispatchAddMessage,
  dispatchThinkingDone,
  dispatchThinkingError,
  dispatchToggleModal,
} from './dispatcher';

// Helper for displaying non-interactive messages, that returns the same interface for convenience
export function useSimpleConversation(staticMessages: Message[]): ConversationInterface {
  return useMemo(() => {
    return {
      messages: staticMessages,
      pending: [],
      isAiThinking: false,
      isModalOpen: false, // No current use case to open a simple conversation
      sendMessage: () => {
        logger.warn(LogSource.APP, 'Simple Conversation cannot sendMessage');
      },
      addMessage: () => {
        logger.warn(LogSource.APP, 'Simple Conversation cannot addMessage');
      },
      toggleModal: () => {
        logger.warn(LogSource.APP,  'Simple Conversation cannot toggleModal');
      },
    };
  }, [staticMessages])
}

const removeFirstOccurence = (arr: string[], target: string) => {
  let removed = false;
  return arr.filter((item) => {
    if (!removed && item === target) {
      removed = true;
      return false;
    }
    return true;
  });
}

interface LocalDispatcherState {
  messages: Message[];
  // TODO: This could be optimized with a Set, but not really needed at UI scale.
  pending: string[];
  isOpen: boolean;
}

// When using local dispatcher, caller will have to watch for changes to to pendingMessages themselves,
// and call addMessage
export function useLocalConversation(initialMessages: Message[]): ConversationInterface {
  
  const [{messages, pending, isOpen}, dispatch] = useReducer(function (
      state: LocalDispatcherState, action: ConversationDispatchAction,
  ): LocalDispatcherState {
    switch (action.type) {
      case ActionType.sendMessage: {
        action = action as MessageAction;
        // Add new messages to pending.
        // Do nothing to main messages.
        return {
          ...state,
          pending: [...state.pending, action.message.body],
        } as LocalDispatcherState;
      }
      case ActionType.addMessage: {
        action = action as MessageAction;
        // Add new messages to main messages.
        // Remove message from pending if it came from user.
        if (action.message.source === 'human') {
          state.pending = removeFirstOccurence(state.pending, action.message.body);
        }
        return {
          ...state,
          messages: [...state.messages, action.message],
          pending: state.pending,
        } as LocalDispatcherState;
      }
      case ActionType.toggleModal: {
        action = action as ModalAction;
        // Toggle `isOpen` and change nothing else.
        return {
          ...state,
          isOpen: action.isOpen,
        } as LocalDispatcherState;
      }
      case ActionType.initialMessages:
      case ActionType.thinkingDone:
      case ActionType.thinkingError: {
          logger.warn(LogSource.APP, `Unused ActionType: ${action.type}`);
          return state;
      }
      default: {
        throw Error('Unknown ActionType: ' + action.type);
      }
    }
  }, {messages: initialMessages, pending: [], isOpen: false} as LocalDispatcherState);

  const sendMessage = useCallback((m: Message) => {
    dispatchSendMessage(dispatch, m);
  }, [dispatch]);

  const addMessage = useCallback((m: Message) => {
    dispatchAddMessage(dispatch, m);
  }, [dispatch]);

  const toggleModal = useCallback((isOpen: boolean) => {
    dispatchToggleModal(dispatch, isOpen);
  }, [dispatch]);

  return {
    messages,
    pending,
    // Always false because no API to talk to.
    // Can still be overidden be host component provider if needed for demo purposes.
    isAiThinking: false,
    isModalOpen: isOpen,
    sendMessage,
    addMessage,
    toggleModal,
  };
}

interface ExternalDispatcherState {
  messages: Message[];
  processing: string[];
  isOpen: boolean;
}

const initialExternalState: ExternalDispatcherState = {
  messages: [], processing: [], isOpen: false,
};

export function useActiveThreadId(): string|null {
  const fs = useContext(FirestoreContext);
  const profileId = useProfileId();
  const businessId = useBusinessId();
  const [threadId, setThreadId] = useState<string|null>(null);

  const activeSessionQuery: Query<AgentThreadDocument>|null = useMemo(() => {
    if (!profileId || !businessId) {return null;}
    const sessionsRef = collection(fs, 'agentThreads').withConverter<AgentThreadDocument>(agentThreadConverter);
    return query<AgentThreadDocument>(sessionsRef,
      where('profileId', '==', profileId),
      where('businessId', '==', businessId),
    );
  }, [fs, profileId, businessId]);

  useEffect(() => {
    if (!activeSessionQuery) { return; }
    const unsubscribe = onSnapshot(activeSessionQuery, (snapshot: QuerySnapshot<AgentThreadDocument>) => {
      snapshot.docChanges().forEach((change: DocumentChange<AgentThreadDocument>) => {
        switch(change.type) {
          case 'added':
            setThreadId(change.doc.data().id);
            break;
          default:
            logger.error(LogSource.FIRESTORE, `No handler for '${change.type}' ChatMessage`);
        }
      });
    });
    return unsubscribe;
  }, [activeSessionQuery]);

  return threadId;
}

const empyStateMessage: Message = {
  source: 'ai',
  body: 'Hello. I\'m Agent Brandi and I\'m excited to help you.'
    + ' Ask me any brand-related questions or requests!',
  createdAt: new Date().getTime(),
};

export function useExternalConversation(businessId: string, activeThreadId: string): ConversationInterface {
  const [api, surfaceKnownErrors] = useApi();
  const [hasInitialMessages, setHasInitialMessages] = useState<boolean>(false);
  const [{messages, processing, isOpen}, dispatch] = useReducer(function (
    state: ExternalDispatcherState, action: ConversationDispatchAction
  ): ExternalDispatcherState {
    switch (action.type) {
      case ActionType.initialMessages: {
        action = action as MessagesAction;
        // Completely reset the state,
        // then popupate the main messages.
        return {
          ...initialExternalState,
          messages: action.messages,
        } as ExternalDispatcherState;
      }
      case ActionType.sendMessage: {
        action = action as MessageAction;
        // Add the message, but also add it's ID to processing
        // (Assume all sent messages are human)
        return {
          ...state,
          messages: [...state.messages, action.message],
          processing: [...state.processing, action.message.id!],
        } as ExternalDispatcherState;
      }
      case ActionType.addMessage: {
        action = action as MessageAction;
        // Add the message directly to main messages w/o side affects.
        // (Caller assumes all responsibility)
        return {
          ...state,
          messages: [...state.messages, action.message],
        } as ExternalDispatcherState;
      }
      case ActionType.thinkingDone: {
        action = action as MessageAction;
        // Remove the ID from processing (API returned succes).
        // And add it to permenant messages.
        // (Assume all thinking actions are in response to human messages)
        return {
          ...state,
          processing: removeFirstOccurence(state.processing, action.message.id!),
        } as ExternalDispatcherState;
      }
      case ActionType.thinkingError: {
        action = action as MessageAction;
        const failedId = action.message.id!;
        // Remove an item from pending on error.
        // But do not add it to permanent messages.
        // TODO: Some kind of "message failed" state for UI could be used here.
        return {
          ...state,
          processing: removeFirstOccurence(state.processing, failedId),
          messages: messages.filter((message) => {
            return message.id !== failedId;
          }),
        } as ExternalDispatcherState;
      }
      case ActionType.toggleModal: {
        action = action as ModalAction;
        // Toggle `isOpen` and change nothing else.
        return {
          ...state,
          isOpen: action.isOpen,
        } as ExternalDispatcherState;
      }
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }, initialExternalState);

  const sendMessage = useCallback((m: Message) => {
    if (!activeThreadId || !businessId) {
      logger.error(LogSource.APP, 'Cannot send message. No Thread Exists');
    }
    m.id = m.id || `${Date.now()}`;
    m.createdAt = m.createdAt || Date.now();
    dispatchSendMessage(dispatch, m);
    api.sendAgentThreadMessage({
      businessId: businessId,
      agentThreadId: activeThreadId,
      content: m.body
    }, (e: ApiResponse<any>) => {
      dispatchThinkingError(dispatch, m);
      surfaceKnownErrors(e);
    }).then((response) => {
      dispatchThinkingDone(dispatch, m);
      dispatchAddMessage(dispatch, convertAgentToMessage(response.data.threadMessage));
    });
  }, [api, businessId, activeThreadId, dispatch, surfaceKnownErrors]);

  const addMessage = useCallback((m: Message) => {
    logger.warn(LogSource.APP, 'Adding UI only message through external dispatcher');
    dispatchAddMessage(dispatch, m);
  }, [dispatch]);

  const toggleModal = useCallback((isOpen: boolean) => {
    dispatchToggleModal(dispatch, isOpen);
  }, [dispatch]);

  const loadInitialMessages = useCallback(() => {
    if ( hasInitialMessages ) { return; }    
    setHasInitialMessages(true);
    api.loadAgentThreadMessages({
      businessId: businessId,
      agentThreadId: activeThreadId,
      amount: 20,
    }, (e: ApiResponse<any>) => {
      surfaceKnownErrors(e);
      // We could reset `hasInitialMessages` as false on failure here,
      // but its unlikely a second call would succeed.
      dispatchInitialMessages(dispatch, []);
    }).then((response) => {
      const initialMessages = response.data.threadMessages.map(convertAgentToMessage).sort((a, b) => {
        // The only messages w/o createdAt are UI-ONLY messages and can go to the end.
        return (a.createdAt || Infinity) - (b.createdAt || Infinity);
      });
      if (!!initialMessages.length) {
        dispatchInitialMessages(dispatch, initialMessages);
      } else {
        dispatchInitialMessages(dispatch, [empyStateMessage]);
      }
    });
  }, [hasInitialMessages, api, surfaceKnownErrors, businessId, activeThreadId]);
  useEffectLate(loadInitialMessages);

  return {
    messages,
    pending: [], // Cannot distiguish between pending/processing using external API
    isAiThinking: !!processing.length,
    isModalOpen: isOpen,
    sendMessage,
    addMessage,
    toggleModal,
  };
}
