import React from 'react';

import { BoxProps } from '@chakra-ui/react'

import { AutoScrollBox } from './AutoScrollBox';
import { MessageList } from './MessageList';

/**
 * A styled list of messages w/ avatar labels.
 */
export function MessageBox({...boxProps}: BoxProps) {
  return (<AutoScrollBox
    bg='grayscale.white'
    borderColor='primary.dark' 
    borderStyle='solid'
    borderRadius='md'
    borderWidth='1px'
    p={4}
    {...boxProps}
  >
    <MessageList />
  </AutoScrollBox>)
}
