import React from 'react';

import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Spacer,
  Tag,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';

import { HeaderMenu, Carousel } from 'src/components';
import { JoinWaitlist } from 'src/components/JoinWaitlist';
import {
  avatar, talawa,
  howItWorks1, howItWorks2, howItWorks3,
  landingBackground,
} from 'src/images';

import { Footer } from './layout/Footer'

const backgroundImageStyles = {
  backgroundImage: `url(${landingBackground})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: '64px',
};

export function WaitlistPage() {
  return (<>
    <Flex
      as='section'
      direction='column'
      justify='flex-start'
      minHeight='94vh' // <100vh to encourage scrolling
      px={8} py={4}
      boxShadow='section'
      sx={backgroundImageStyles}
    >
      <HeaderMenu hideLogo={true} />
      <Image
        src={talawa}
        alt='Talawa'
        width="50%"
        mixBlendMode='multiply' // remove white background
        mb={6}
      ></Image>
      <Text textStyle='display1' mb={4}>
        Transforming ideas into powerful brands
      </Text>
      <Text textStyle='body' mb={4}>
        Share your business idea and see it instantly transformed 
        wih our AI magic into logos, slogan and more. 
      </Text>
      <Text textStyle='bodySmall' mb={4}>
        Already have a logo? Want to re-brand? Talawa takes the parts of your 
        brand you already have and improves or includes them in your Brand Kit. 
      </Text>
      <Spacer flex='1' />
      <Text textStyle='direction' align='center' mb={12}>
        Tell Us About Your Business
      </Text>
      <Card mb={6} p={6} textAlign='center'>
        <Center
          position='absolute'
          top='-24px'
          left='0'
          marginLeft='auto'
          right='0'
          marginRight='auto'
          width='100%'>
          <Image
            src={avatar}
            alt='Avatar'
            boxSize='48px'
          />
        </Center>
        
        <Text color='text.placeholder' mb={2}>Something like:</Text>
        <Text textStyle='display4' color='text.placeholder'>
          I make and sell scarves for dogs from vintage screen printed fabrics
        </Text>
      </Card>
      <JoinWaitlist mb={6} />
    </Flex> {/* End of section 1 (Hero) */}

    <Box as='section' px={8} py={12}>
      <Text textStyle='display1' align='center'>
        How it works?
      </Text>

      <Carousel alignCarouselItems='start'>
        <VStack align='center'>
          <Image
            src={howItWorks1}
            alt='Build a brand'
            width="100%"
            maxWidth='400px'
            aspectRatio='1/1'    
            mb={6}
          ></Image>
          <Text textStyle='display3'  mb={1}>
            Build a brand that stands out from the crowd
          </Text>
          <Text textStyle='body'>
            No templates here. Let our AI-Agent create a one-of-a-kind 
            brand identity -- name, logo, colors and more 
            to set your business apart.
          </Text>
        </VStack>
        
        <VStack>
          <Image
            src={howItWorks2}
            alt='Attract customers'
            width="100%"
            maxWidth='400px'
            aspectRatio='1/1'        
            mb={6}
          ></Image>
          <Text textStyle='display3' align='center' mb={1}>
            Attract customers that value your products & services
          </Text>
          <Text textStyle='body' align='center'>
            Attract the right customers. We'll help you define your 
            target audience, values, and brand story so that 
            your business resonates with customers.
          </Text>
        </VStack>

        <VStack>
          <Image
            src={howItWorks3}
            alt='Show up'
            width="100%"
            maxWidth='400px'
            aspectRatio='1/1'      
            mb={6}
          ></Image>
          <Text textStyle='display3' mb={1}>
            Show up when it matters with predictive assets
          </Text>
          <Text textStyle='body'>
            Our AI will think ahead for you and generate what you need 
            - posts, designs and more - to keep your brand fresh 
            and relevant online.
          </Text>
        </VStack>
      </Carousel>
    </Box>  {/* End of section 2 (Carousel) */}

    <Box as='section' px={8} py={20} boxShadow='section'>
      <Text textStyle='display2' align='center' mb={4}>
        Free Trial
      </Text>
      <Text textStyle='display4' align='center' mb={4}>
        Start building your brand kit with just an idea...
      </Text>
      <Text textStyle='body' align='center' mb={6}>
        Get 5 AI-generated brand style ideas (logo, slogans and more) 
        for your brand kit completely free. 
      </Text>
      <Text textStyle='body2' align='center' mb={8}>
        Seriously. Download them, use them. They're all yours! 
        Have a name or logo you still want to use, simply 
        upload them and we'll include it in your kit!
      </Text>
      <JoinWaitlist mb={6} />

      <Text textStyle='display2' align='center' mb={6}>
        Want more?
      </Text>

      <Card p={3} mb={4}>
        <CardHeader>
          <Text textStyle='display3' mb={2}>
            1. Build Your Brand Kit
          </Text>
        </CardHeader>
        <CardBody>
          <Text textStyle='body2'>
            Our AI helps you create the strategic fundamentals of your brand for a 
          </Text>
          <Text textStyle='displayPrice'>
            one-time fee of $500. 
          </Text>
          <Wrap py={2}>
            <Tag>Name</Tag>
            <Tag>Logo</Tag>
            <Tag>Slogan</Tag>
            <Tag>Target Audience</Tag>
            <Tag>Fonts</Tag>
            <Tag>Colors</Tag>
            <Tag>More</Tag>
          </Wrap>
        </CardBody>
        {/* TODO:> Ready to start? */}
      </Card>

      <Card p={3} mb={4}>
        <CardHeader>
          <Text textStyle='display3' mb={2}>
            2. Get Ongoing access
          </Text>
        </CardHeader>
        <CardBody>
          <SimpleGrid spacing={2} templateColumns='1fr 1fr' mx={-1} mb={2}>
            <Card size='sm' m={1} overflow='hidden'>
              <CardHeader
                bg='primary.veryLight'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='flex-end'
                minHeight={68}
                pb={4}
              >
                <Text textStyle='display4'>Project</Text>
              </CardHeader>
              <CardBody p={2}>
                <Text textStyle='bodySmall' align='center' mb={4}>
                  Pay-per-project to leverage our AI platform to transform 
                  your creative ideas into anything like posts, packaging or 
                  product designs & more.
                </Text>
                <Text textStyle='displayPrice' align='center' mb={4}>
                  $20.00 & up
                </Text>
              </CardBody>
            </Card>
            <Card size='sm' m={1} overflow='hidden'>
              <CardHeader
                bg='primary.veryDark'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='flex-end'
                minHeight={68}
                pb={4}
              >
                <Text textStyle='legend' color='secondary.dark'>*Recommended*</Text>
                <Text textStyle='display4' color='grayscale.white'>Premium</Text>
              </CardHeader>
              <CardBody p={2}>
                <Text textStyle='bodySmall' align='center' mb={4}>
                  Create all the projects you want with our creative AI platform 
                  plus AI-Agent Brandi creates unique-to-you brand actions 
                  to grow your brand.
                </Text>
                <Text textStyle='displayPrice' align='center'  mb={4}>
                  $40.00/month
                </Text>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        
        <Text textStyle='reminder' align='center' mb={3}>
          Project and Premium plans require a Talawa Brand Kit
        </Text>

        <JoinWaitlist>View Pricing Info</JoinWaitlist>
      </Card>
    </Box> {/* End of section 2 (Pricing) */}

    <Footer />
  </>);
}
