
type Resolver = (value: unknown) => void;

interface CachedRequest {
  response?: unknown;
  resolvers: Resolver[];
  timeoutId?: number;
}

export class ApiCache {
  private cache = new Map<string, CachedRequest>();

  hasRequest(url: string, body: Object) : boolean {
    return this.cache.has(this.idempotentKey(url, body));
  }

  addNewRequest(url: string, body: Object) : void {
    const idempotentKey = this.idempotentKey(url, body);
    if (this.cache.has(idempotentKey)) { 
      throw new Error('Request already exists'); 
    }
    this.cache.set(idempotentKey, {resolvers: []});
  }

  awaitExistingRequest(url: string, body: Object) : Promise<any> {
    const idempotentKey = this.idempotentKey(url, body);
    if (!this.cache.has(idempotentKey)) {  
      throw new Error('No request exists'); 
    }

    const cachedRequest = this.cache.get(idempotentKey)!;
    if (!!cachedRequest.response) {
      this.removeRequestAfterTimeout(idempotentKey);
      return Promise.resolve(cachedRequest.response);
    } else {
      return new Promise((resolve) => {
        cachedRequest.resolvers.push(resolve);
      });
    }
  }

  /** Resolves any awaiting requests, and starts the expiration timer */
  publishResponse(url: string, body: Object, response: any): void {
    if (!this.hasRequest(url, body)) { return; }

    const idempotentKey = this.idempotentKey(url, body);
    const cachedRequest = this.cache.get(idempotentKey)!;

    cachedRequest.response = response;
    cachedRequest.resolvers.forEach((resolver) => {
      resolver(response);
    });
    this.removeRequestAfterTimeout(idempotentKey);
  }

  private idempotentKey(url: string, body: Object): string {
    return url+JSON.stringify(body, Object.keys(body).sort());
  }

  /** Similar to `publishResponse` Resolves any awaiting requests, 
   * but immediately clears the cache key
   **/
  publishError(url: string, body: Object, response: any) {
    if (!this.hasRequest(url, body)) { return; }

    const idempotentKey = this.idempotentKey(url, body);
    const cachedRequest = this.cache.get(idempotentKey)!;

    cachedRequest.response = response;
    cachedRequest.resolvers.forEach((resolver) => {
      resolver(response);
    });
    this.removeRequest(idempotentKey);
  };

  private removeRequestAfterTimeout(idempotentKey: string) {
    if (!this.cache.has(idempotentKey)) {  
      throw new Error('No request for timeout exists'); 
    }

    const cachedRequest = this.cache.get(idempotentKey)!;

    // Extend the cache life if previous schedule for removal.
    if (!!cachedRequest.timeoutId) {
      clearTimeout(cachedRequest.timeoutId);
    }

    cachedRequest.timeoutId = window.setTimeout(() => {
      this.removeRequest(idempotentKey);
    }, 200); 
    // 0.2 seconds; Enough to prevent UI re-render from executing
    // but not enough for deliberate user action to executing.
  };

  private removeRequest(idempotentKey: string) {
    this.cache.delete(idempotentKey)!;
  };
}