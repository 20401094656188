import { createContext } from 'react';

import { CheckoutItem } from 'src/api';

export interface PayWallDisclosure {
  isPayWallOpen: boolean;
  openPayWall: () => void;
  closePayWall: () => void;
}

export interface PurchaseDetails {
  checkoutItem?: CheckoutItem;
}

export interface PayWallProps extends PayWallDisclosure {
  details: PurchaseDetails;
  setDetails: (details: PurchaseDetails) => void;
}

export const PayWallContext = createContext<PayWallProps>({} as PayWallProps);
