import React from "react";

import { logger, LogSource } from 'src/util/logger';

import { RequirementProps, Nothing } from './Props';

/**
 * Base component for a Require wrappers.
 * 
 * Ensures children are only rendered if `metRequirement` is true.
 * Will autimatically log an error if requirement is not met and no OrElse is provided.
 */
export function Requirement({
  metRequirement,
  OrElse = Nothing,
  children,
} : RequirementProps) {
  if (metRequirement) {
    return <>{children}</>;
  } else {
    if (OrElse === Nothing) {
      logger.error(LogSource.APP, 'Business does not exist');
    }
    return OrElse;
  }
}
