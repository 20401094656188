import React from 'react';
import { BoxProps, Icon, Flex, Progress, Spacer, Text, VStack, HStack } from '@chakra-ui/react';
import { MdCheckCircleOutline } from 'react-icons/md';

import { AssetTypes, AssetDocument, useAssetCollection} from 'src/db';

export function AssetChecklist({...boxProps}: BoxProps) {
  const audiences = useAssetCollection<AssetDocument>(AssetTypes.audience);
  const colors = useAssetCollection<AssetDocument>(AssetTypes.color);
  const fonts = useAssetCollection<AssetDocument>(AssetTypes.font);
  const logos = useAssetCollection<AssetDocument>(AssetTypes.logo)
  const slogans = useAssetCollection<AssetDocument>(AssetTypes.slogan);
  const names = useAssetCollection<AssetDocument>(AssetTypes.name);
  const values = useAssetCollection<AssetDocument>(AssetTypes.value);

  return (
    <Flex direction='column' {...boxProps} width='100%'>
      <VStack align='stretch' mx='auto'>
        <CheckMark label='Names' isChecked={!!names.length} />
        <CheckMark label='Audiences' isChecked={!!audiences.length} />
        <CheckMark label='Slogans' isChecked={!!slogans.length} />
        <CheckMark label='Values' isChecked={!!values.length} />
        <CheckMark label='Logos' isChecked={!!logos.length} />
        <CheckMark label='Colors' isChecked={!!colors.length} />
        <CheckMark label='Fonts' isChecked={!!fonts.length} />
      </VStack>
      <Spacer flex='1 0 0' />
      <Progress size='lg' isIndeterminate width='80%' mx='auto' shadow='card' />
    </Flex>
  );
}

interface CheckMarkProps {
  label: string;
  isChecked: boolean;
}
function CheckMark({label, isChecked}: CheckMarkProps) {
  return (<HStack justify='left' align='end'>
    <Icon
      as={MdCheckCircleOutline}
      aria-label={isChecked ? 'complete' : 'not complete'}
      color={isChecked ? 'secondary.dark' : 'grayscale.medium'}
      boxSize='24px'
    />
    <Text
      textStyle='bodyLarge'
      color={isChecked ? 'text.veryDark' : 'grayscale.medium'}
    >{label}</Text>
  </HStack>);
}