import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { HeaderMenu } from 'src/components'
import { Path } from 'src/nav';
import { logger, LogSource } from 'src/util/logger';

export enum ErrorPageCode {
  NOT_FOUND = '404',
  PROFILE_DOES_NOT_EXIST = 'PROFILE_DOES_NOT_EXIST',
}

interface ErrorPageProps {
  errorCode?: string;
}
export function ErrorPage({ errorCode } : ErrorPageProps)  {
  const { state } = useLocation();
  if (!errorCode) {
    errorCode = state.errorCode;
  }
  return (<>
      <HeaderMenu />
      <main>
        <h1>Error: "{errorCode || 'Something went wrong'}"</h1>
        <ErrorPageContent errorCode={errorCode} />
      </main>
  </>);
}

function ErrorPageContent({ errorCode } : ErrorPageProps) {
  const contactSupportMessage = <p>
    Please contact <a href='mailto:support@talawa.ai'>support@talawa.ai</a>&nbsp;
    with your registered email address so that we can help you fix this issue.
  </p>;
  const defaultContent = <>
    <p>
      Please <Link to={"/logout"}>logout</Link> and retry.
      If the problem persists...
    </p>
    {contactSupportMessage}
  </>;

  if (!errorCode) { return defaultContent; }
  switch (errorCode) {
    case ErrorPageCode.NOT_FOUND:
      return <>
        <p>Page Not Found</p>
        <p><Link to={Path.landing}>Go Home</Link></p>
      </>
    case ErrorPageCode.PROFILE_DOES_NOT_EXIST:
      return <>
        <p>We couldn't find your profile.</p>
        {contactSupportMessage}
      </>
    default: {
      logger.error(LogSource.APP, `Unknown ErrorPageCode: ${errorCode}`);
      return defaultContent;
    }
  }
}

export function useSendToErrorPage(): (errorCode: string) => void {
  const navigate = useNavigate();
  return function (errorCode: string) {
    navigate(Path.error, {state: { errorCode }});
  }
}
