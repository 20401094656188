import { logger, LogSource } from 'src/util/logger';

import { Message } from './Message';

export enum ActionType {
  // Initial load of messages from the collection.
  initialMessages = 'INITIAL_MESSAGES',
  // Send a new message from user to the conversation (does not garaurantee receipt).
  // Typically, only the input box needs to call this.
  sendMessage = 'SEND_MESSAGE',
  // Add a new message to the conversation.
  // If using local, the host component has to call this.
  // If using external, it will be called on firestore changes.
  addMessage = 'NEW_MESSAGE',
  // The AI has finished forming its response.
  thinkingDone = 'THINKING_DONE',
  // An error occured before the AI finished forming its response.
  thinkingError = 'THINKING_ERROR',
  // Open or close the conversation modal
  toggleModal = 'TOGGLE_MODAL',
};

// An action with a single message input
export interface MessageAction {
  type: ActionType;
  message: Message;
}

// An action with with multiple messages input
export interface MessagesAction {
  type: ActionType;
  messages: Message[];
}

// An action that effects the Modal state.
export interface ModalAction {
  type: ActionType;
  isOpen: boolean;
}

// All possible action types for ConversationReducer/ConversationDispatch
export type ConversationDispatchAction = MessageAction|MessagesAction|ModalAction;

export type ConversationReducer =  (messages: Message[], action: ConversationDispatchAction) => Message[];
export type ConversationDispatch = (action: ConversationDispatchAction) => void;


export const dispatchInitialMessages = (
  dispatch: ConversationDispatch, messages: Message[]
) => {
  dispatch({
    type: ActionType.initialMessages,
    messages: messages,
  } as MessagesAction);
};

export const dispatchSendMessage = (
  dispatch: ConversationDispatch, m: Message
) => {
  if (m.source === 'ai') {
    logger.warn(LogSource.APP, 'Sent message was from AI', {message: m.toString()});
  }
  dispatch({
    type: ActionType.sendMessage, message: m,
  } as MessageAction);
};

export const dispatchAddMessage = (
  dispatch: ConversationDispatch, m: Message
) => {
  dispatch({
    type: ActionType.addMessage, message: m,
  } as MessageAction);
};

export const dispatchThinkingDone = (
  dispatch: ConversationDispatch, m: Message
) => {
  if (m.source === 'ai') {
    logger.warn(LogSource.APP, 'Thinking done on ai message', {message: m.toString()});
  }
  dispatch({
    type: ActionType.thinkingDone, message: m,
  } as MessageAction);
};

export const dispatchThinkingError = (
  dispatch: ConversationDispatch, m: Message
) => {
  if (m.source === 'ai') {
    logger.warn(LogSource.APP, 'Thinking error on ai message', {message: m.toString()});
  }
  dispatch({
    type: ActionType.thinkingError, message: m,
  } as MessageAction);
};

export const dispatchToggleModal = (
  dispatch: ConversationDispatch, isOpen: boolean
) => {
  dispatch({
    type: ActionType.toggleModal, isOpen
  } as ModalAction);
};
