import { ApiResponse } from './ApiResponse';

export enum CheckoutItem {
  TOP_UP = 'brand_kit_top_up',
  PAY_AS_YOU_GO = 'brand_kit_pay_as_you_go',
  SUBSCRIPTION = 'brand_kit_subscription',
}

export interface CreateCheckoutSessionRequest {
  businessId: string;
  paymentRedirectDomain: string;
  checkoutItem: CheckoutItem;
}

interface ResponseData {
  redirectUrl: string;
}

export type CreateCheckoutSessionResponse = ApiResponse<ResponseData>;
