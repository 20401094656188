import React from 'react';

import {
  BoxProps,
  Center,
} from '@chakra-ui/react';

import { AssetTypes, LogoAssetDocument, logoAssetConverter } from 'src/db';
import { Carousel } from 'src/components';
import { AddLogoAssetButton } from 'src/components/assets/addAsset';
import { LogoPreview } from 'src/components/assets'; 
import { Path } from 'src/nav';

import { AssetsProps, BrandKitCard } from './BrandKitCard'

export function LogoCard({...boxProps}: BoxProps) {


  const SuggestedLogos = ({assets}: AssetsProps<LogoAssetDocument>) => {
    return (<Carousel itemWidth={200} itemSpacing={16}>
      {assets.map((logo, idx) => {
        return <LogoPreview
          key='idx'
          logo={logo}
        />
      })}
    </Carousel>);
  }
  
  // Assume only 1 logo selected.
  const SelectedLogos = ({assets}: AssetsProps<LogoAssetDocument>) => {
    return (<Center>
      <LogoPreview
        key='idx'
        logo={assets[0]}
      />
    </Center>);
  }

  return (
    <BrandKitCard<LogoAssetDocument>
      title='Logos'
      assetType={AssetTypes.logo}
      firestoreConverter={logoAssetConverter}
      reviewPath={Path.logo}
      SelectedAssets={SelectedLogos}
      SuggestedAssets={SuggestedLogos}
      AddAssetButton={AddLogoAssetButton}
      {...boxProps}
    />
  );
}
