import React from 'react';

import { AssetTypes } from 'src/db';
import { AudienceAssetValue } from 'src/db/model/assets/audienceAsset';
import { UpdateTextAssetButton, UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';

const TypedUpdateTextAssetButton = UpdateTextAssetButton;

const getValue = ({targetAudience}: AudienceAssetValue): string => {
  return targetAudience;
}

const parseText = (text: string): AudienceAssetValue => {
  return {targetAudience: text};
};

export function UpdateAudienceAssetButton({asset, children, ...btnProps} : UpdateTextAssetButtonProps) {
  return (
    <TypedUpdateTextAssetButton
      assetId={asset.id}
      assetType={AssetTypes.audience}
      assetUiName='Audience'
      assetValue={getValue(asset.value as AudienceAssetValue)}
      parseText={parseText}
      {...btnProps}
    />
  );
}

