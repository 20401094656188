import { FirebaseApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';

/**
 * Use synchronously after initializing the firebase app.
 * 
 * (Therfore do not call inside of React)
 */
export function setupAuthForEnv(app: FirebaseApp): Auth {
  const auth = getAuth(app);
  // Turn off emulator permanently until Firestore can be emulated too.
  const shouldUseEmulator = false; // process.env.NODE_ENV === 'development';
  const emulatorURL = 'http://127.0.0.1:9099';
  if (shouldUseEmulator) {
    connectAuthEmulator(auth, emulatorURL);
    // Uncomment this to cleanup the screen while doing dev.
    // connectAuthEmulator(auth, emulatorURL, {disableWarnings: true});
  }
  return auth;
}
