import React, { useContext, useEffect } from 'react';
import { signOut } from 'firebase/auth';


import { Flex } from '@chakra-ui/react';

import { AuthContext } from 'src/auth';
import { Redirect, Path } from 'src/nav';

import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { RequireUser } from './wrappers/RequireUser';

export function LogoutPage() {
  return (<HeaderFooterPage pageTitle='Logout'>
    <Flex
      align='center'
      direction='column'
      justify='flex-start'
      my={8}
    >
      <RequireUser
        OrElse={<Redirect message='Successfully logged out' to={Path.landing}/>}
      >
        <LogoutPageContent />
      </RequireUser>
    </Flex>  
  </HeaderFooterPage>);
}

function LogoutPageContent() {
  const auth = useContext(AuthContext)!;
  useEffect(() => {
    signOut(auth);
  }, [auth]);
  return (<></>);
}
