import React, { SyntheticEvent, useCallback, useRef, useState, useEffect } from "react";
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Text,
  useDisclosure,
  IconButton,
  Icon,
  Tooltip,
  Textarea,
} from "@chakra-ui/react";
import { TbEditCircle } from "react-icons/tb";

import { useApi } from "src/api";
import {
  AssetTypes,
  useBusinessId,
  AssetDocument,
  AssetValueTypes,
} from "src/db";
import {
  UpdateAssetDataRequest,
  UpdateAssetDataResponse,
} from "src/api/messages/UpdateAssetData";

export interface UpdateTextAssetButtonProps extends ButtonProps {
  asset: AssetDocument;
}

type TextParser<V> = (text: string) => V;

export interface UpdateTextAssetProps<V extends AssetValueTypes>
  extends ButtonProps {
  assetId: string;
  assetValue: string;
  parseText: TextParser<V>;
  assetType: AssetTypes;
  assetUiName: string;
}
export function UpdateTextAssetButton<V extends AssetValueTypes>({
  assetId,
  assetValue,
  parseText,
  assetType,
  assetUiName,
  children,
  ...buttonProps
}: UpdateTextAssetProps<V>) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>(assetValue);

  const handleSubmit = useCallback(
    (text: string) => {
      if (isSubmitting) {
        return;
      }
      if (text === assetValue || !text) {
        onClose();
        return;
      }
      setIsSubmitting(true);
      const updateRequestData: UpdateAssetDataRequest = {
        businessId: businessId,
        assetId: assetId,
        assetType: assetType,
        assetData: {
          assetValue: {
            textToUpdate: text,
          },
        },
      };
      api
        .updateAssetData(updateRequestData, surfaceKnownErrors)
        .then((response: UpdateAssetDataResponse) => {
          // Select asset after editing
          api.selectAsset(
            {
              businessId: businessId,
              assetType: assetType,
              assetId: assetId,
              selectedForBrandKit: true,
            },
            surfaceKnownErrors
          );
          onClose();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [
      isSubmitting,
      api,
      assetValue,
      businessId,
      assetId,
      assetType,
      surfaceKnownErrors,
      onClose,
    ]
  );

  return (
    <>
      <Tooltip label="Update" placement="top">
        <IconButton
          aria-label="Update"
          color="secondary.dark"
          icon={<Icon as={TbEditCircle} boxSize="24px" />}
          height="fit-content"
          {...buttonProps}
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();
            onOpen();
          }}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        initialFocusRef={inputRef}
      >
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>
            <Center>
              <Text textStyle="bodyLarge">{`Update ${assetUiName}`}</Text>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={(e) => {
                // Prevent page refresh
                e.preventDefault();
                if (!!textValue) {
                  handleSubmit(textValue);
                }
              }}
            >
              <Flex
                direction="row"
                align="center"
                justify="space-between"
                wrap="wrap"
              >
                <FormControl flex="1 0 fit-content">
                  {/* <Textarea
                    ref={inputRef}
                    placeholder="Type here"
                    variant="filled"
                    value={textValue}
                    onChange={(e) => {
                      setTextValue(e.target.value);
                    }}
                    isDisabled={isSubmitting}
                  /> */}
                  <AutoAdjustTextarea
                    customOnChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setTextValue(e.target.value); return;
                    }} 
                    customValue={textValue} />
                </FormControl>
                <Spacer flex="0 0 8px" />
                <Button
                  type="submit"
                  variant="fill"
                  colorScheme="primary"
                  size="sm"
                  flex="0 0 fit-content"
                  isDisabled={!textValue}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}


function AutoAdjustTextarea({ customOnChange, customValue }: { customOnChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void, customValue: string}) {
  const [value, setValue] = useState(customValue);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // Update textarea height when content changes
    if (textareaRef.current !== null) {
      textareaRef.current.style.height = 'auto'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => { // Add type annotation to the event parameter
    const inputValue = e.target.value;
    setValue(inputValue);
    customOnChange(e);
  };

  return (
    <>
      <Textarea
        ref={textareaRef}
        value={value}
        onChange={handleInputChange}
        placeholder="Type here..."
        resize="none" // Disable manual resizing
        style={{ minHeight: '100px', overflowY: 'auto' }}
      />
    </>
  );
}