import React from 'react';

import { BoxProps } from '@chakra-ui/react';

import { MAX_SELECTED_AUDIENCES }  from 'src/api/constants';
import { AddAudienceAssetButton } from 'src/components/assets/addAsset';
import { UpdateAudienceAssetButton } from 'src/components/assets/updateAsset';
import { AssetTypes, AudienceAssetDocument, audienceAssetConverter } from 'src/db';
import { Path } from 'src/nav';

import { BrandKitCard } from './BrandKitCard'
import { ActionableTextAssets } from './ActionableTextAssets';

export function AudienceCard({...boxProps}: BoxProps) {
  return (
    <BrandKitCard<AudienceAssetDocument>
      title='Audiences'
      assetUiName='Audience'
      assetType={AssetTypes.audience}
      firestoreConverter={audienceAssetConverter}
      reviewPath={Path.audience}
      SelectedAssets={ActionableTextAssets}
      SuggestedAssets={ActionableTextAssets}
      selectionLimit={MAX_SELECTED_AUDIENCES}
      selectionLimitErrorMessage={
        `Limit of ${MAX_SELECTED_AUDIENCES} Audiences. Remove one before adding another`
      }
      AddAssetButton={AddAudienceAssetButton}
      UpdateAssetButton={UpdateAudienceAssetButton}
      {...boxProps}
    />
  );
}
