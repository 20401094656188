import React, { MutableRefObject, useCallback, useRef } from 'react';
import 'react-medium-image-zoom/dist/styles.css'
import { Textfit } from 'new-react-textfit';

import {
  Box,
  BoxProps,
  Flex,
  Image,
} from '@chakra-ui/react';

import { LogoAssetDocument } from 'src/db';
import { logger, LogSource } from 'src/util/logger';

// Serializes the rendered Logo into 
export type LogoSerializer = () => string;

const textPositionXMap: Record<string, string> = {
  'Left': 'start',
  'Center': 'center',
  'Right': 'end',
};

const textAlignXMap: Record<string, 'left'|'center'|'right'> = {
  'Left': 'left',
  'Center': 'center',
  'Right': 'right',
};

const textPositionYMap: Record<string, string> = {
  'Top': 'start',
  'Center': 'center',
  'Bottom': 'end',
};


interface LogoBaseProps {
  logo: LogoAssetDocument;
  alt?: string;
}
function LogoBase({logo, alt}: LogoBaseProps) {
  return (
    <Image
      src={logo.value.logo.imageInfo.imageUrl}
      alt={alt || logo.explanation}
      boxSize='100%'
      objectFit='contain'
      loading='eager'
    />
  );
}

interface LogoProps extends LogoBaseProps, BoxProps {
  // If true, render as a "DynamicLogo"
  isDynamic?: boolean; // default is false
  htmlSerializerRef?: MutableRefObject<LogoSerializer|null>,
}
export function Logo({
  logo, alt, isDynamic = false, 
  htmlSerializerRef,
  ...boxProps
}: LogoProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const serializer =  useCallback(() => {
    return serializeNode(inlineComputedStyles(elementRef.current!));
  }, [elementRef]);
  if (!!htmlSerializerRef) {
    htmlSerializerRef.current = serializer;
  }

  if (isDynamic && (!logo.value.logo.font || !logo.value.logo.name)) {
    logger.error(LogSource.FIRESTORE, 'Missing required values for Dyanmic Logo rendering');
    return (<Box {...boxProps}>
      <LogoBase logo={logo} alt={alt}/>
    </Box>);
  }
  if (!isDynamic) {
    return (<Box {...boxProps}>
      <LogoBase logo={logo} alt={alt}/>
    </Box>);
  } else {
    return (
    <Box {...boxProps} ref={elementRef} > 
      <LogoBase logo={logo} alt={alt} />
      <Flex
        position='absolute'
        boxSize='100%'
        top={0} bottom={0}
        right={0} left={0}
        direction='column'     
        align={ textPositionXMap[logo.value.logo.textPositionX] || 'center'} 
        justify={textPositionYMap[logo.value.logo.textPositionY] || 'end'}
      >
        <DynamicFontSample
          uniqueName={logo.id}
          fontUrl={logo.value.logo.font.url}
          fontWeight={logo.value.logo.font.weight}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '20%',
            width: '80%',
            textAlign: textAlignXMap[logo.value.logo.textPositionX] || 'center',
            justifyContent: 'center', // Always center within the box, for nicer padding
          }}
        >{logo.value.logo.name}</DynamicFontSample>
      </Flex>
    </Box>
    );
  }
}

interface DynamicFontSampleProps extends BoxProps {
  uniqueName: string;
  fontUrl: string;
  fontWeight?: string;
}
export function DynamicFontSample({
  uniqueName,
  fontUrl,
  fontWeight = '400',
  style,
  children,
  ...boxProps // boxProps must include fixed height/width
}: DynamicFontSampleProps) {
  const uniqueFontName = `custom-${uniqueName}`;

  return (<>
    <style>{`
      @font-face {
        font-family: ${uniqueFontName};
        src: url(${fontUrl});
      }
    `}</style>
    <Textfit 
      mode='multi'
      min={8}
      style={{
        fontFamily: uniqueFontName,
        fontWeight: fontWeight, 
        ...style
      }}
    >{children}</Textfit>
  </>);
}

function serializeNode(node: HTMLElement): string {
  if (!node) return '';
  const serializer = new XMLSerializer();
  return serializer.serializeToString(inlineComputedStyles(node));
}

function toHyphenCase(camel: string) {
  return camel.replace(/[A-Z]./g, (m) => `-${m.toLowerCase()}`);
}

function inlineComputedStyles(node: HTMLElement): HTMLElement {
  if (!node) return node;
  const computedStyles = window.getComputedStyle(node);
  const clone = node.cloneNode() as HTMLElement;
  Object.entries(computedStyles).forEach(([name, value]) => {
    if (!value) return;
    if (isNaN(parseInt(name))) {
      clone.style.setProperty(toHyphenCase(name), value);
    }
  });
  const childrenClones: Node[] = [];
  Array.from(node.childNodes).forEach((child) => {
    if (child instanceof HTMLElement) {
      childrenClones.push(inlineComputedStyles(child));
    } else {
      childrenClones.push(child.cloneNode(true));
    }
  });
  clone.replaceChildren(...childrenClones);
  return clone;
}
