import React from 'react';

import { AssetTypes } from 'src/db';
import { SloganAssetValue } from 'src/db/model/assets/sloganAsset';
import { UpdateTextAssetButton, UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';

const TypedUpdateTextAssetButton = UpdateTextAssetButton;

const getValue = ({slogan}: SloganAssetValue): string => {
  return slogan;
}

const parseText = (text: string): SloganAssetValue => {
  return {slogan: text};
};

export function UpdateSloganAssetButton({asset, children, ...btnProps} : UpdateTextAssetButtonProps) {
  return (
    <TypedUpdateTextAssetButton
      assetId={asset.id}
      assetType={AssetTypes.slogan}
      assetUiName='Slogan'
      assetValue={getValue(asset.value as SloganAssetValue)}
      parseText={parseText}
      {...btnProps}
    />
  );
}

