import React from 'react';

import { Center, Tag, TagLabel } from '@chakra-ui/react';

import { MAX_SELECTED_BRAND_STORIES }  from 'src/api/constants';
import { AddBrandStoryAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, BrandStoryAssetDocument } from 'src/db';

import { AssetProps, DetailPage } from './DetailPage';

export function BrandStoryPage() {
  return <DetailPage<BrandStoryAssetDocument>
    title='Brand Stories'
    assetUiName='Brand Story'
    assetType={AssetTypes.brandStory}
    SelectedAsset={SelectedBrandStory}
    SuggestedAsset={SuggestedBrandStory}
    selectionLimit={MAX_SELECTED_BRAND_STORIES}
    selectionLimitErrorMessage={
      `Maximum of ${MAX_SELECTED_BRAND_STORIES} selected Brand Stories. Unselect one before selecting another`
    }
    AddAssetButton={AddBrandStoryAssetButton}
  />
}

function SelectedBrandStory({ asset }: AssetProps<BrandStoryAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.brandStories}</TagLabel>
    </Tag>
  </Center>);
}

function SuggestedBrandStory({ asset }: AssetProps<BrandStoryAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.brandStories}</TagLabel>
    </Tag>
  </Center>);
}
