import React, { SyntheticEvent, useCallback, useState } from 'react';

import {
  BoxProps,
  Button,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from "react-icons/fa";

import { useApi } from 'src/api';
import { RegisterDomainActionDocument } from 'src/db';

interface EducationProps extends BoxProps {
  domain: RegisterDomainActionDocument;
}
export function RegisterDomain({ domain, ...boxProps }: EducationProps) {
  const { title, url } = domain.actionData;

  const [ api, surfaceKnownErrors ] = useApi();
  const [ isCompleting, setIsCompleting ] = useState<boolean>(false);

  const navigateToUrl = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (isCompleting) { return; }
    setIsCompleting(true);
    api.completeBrandAction({
      businessId: domain.businessId,
      brandActionId: domain.id,
      isCompleted: true,
    }, surfaceKnownErrors).finally(() => {
      setIsCompleting(false);
    });
    // Don't wait for API to complete.
    window.open(url, '_blank', 'noopener, noreferrer');
  }, [
    domain, url,
    api, surfaceKnownErrors, isCompleting
  ]);


  return (<VStack {...boxProps} >
    <Text textStyle="bodyLarge" align="left">{title}</Text>
    {!domain.isPreview && <Button
      variant='fill'
      colorScheme='primary'
      mx='auto'
      minWidth='60%'
      onClick={navigateToUrl}
    >
      <span>Setup</span>&nbsp;
      <Icon as={FaExternalLinkAlt} />
    </Button>}
  </VStack>);
}
