import React from 'react';

import {
  BoxProps,
  Flex,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { IoMdClose, IoMdHeart } from 'react-icons/io'

import { LogoAssetDocument } from 'src/db';

interface ThumbsProps extends BoxProps {
  logo: LogoAssetDocument;
  thumbsDownPending?: boolean;
  thumbsUpPending?: boolean;
  onThumbsUp?: () => void;
  onThumbsDown?: () => void;
}

export function Thumbs({
  logo,
  thumbsDownPending = false,
  thumbsUpPending = false,
  onThumbsUp = () => {},
  onThumbsDown = () => {},
  ...boxProps
}: ThumbsProps) {
  return (
    <Flex 
      direction='row'
      alignItems='baseline'
      justifyContent='space-between'
      {...boxProps}
    >
      <IconButton
        icon={<Icon
          as={IoMdClose}
          boxSize='48px'
          color={'grayscale.white'} 
          style={{strokeWidth: '1px'}}
        />}
        aria-label='Dislike'
        bg={'secondary.dark'}
        boxSize='80px'
        color='grayscale.white'
        isRound={true}
        isLoading={thumbsDownPending}
        onClick={onThumbsDown}
      ></IconButton>
      <IconButton
        icon={<Icon
          as={IoMdHeart}
          boxSize='48px'
          color={'grayscale.white'} 
          style={{strokeWidth: '1px'}}
        />}
        aria-label='Like'
        bg={'primary.light'}
        boxSize='80px'
        isRound={true}
        isLoading={thumbsUpPending}
        onClick={onThumbsUp}
      ></IconButton>
    </Flex>
  );
}
