import React from 'react';

import { AssetTypes } from 'src/db';
import { NameAssetValue } from 'src/db/model/assets/nameAsset';
import { UpdateTextAssetButton, UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';

const TypedUpdateTextAssetButton = UpdateTextAssetButton;

const getValue = ({name}: NameAssetValue): string => {
  return name;
}

const parseText = (text: string): NameAssetValue => {
  return {name: text};
};

export function UpdateNameAssetButton({asset, children, ...btnProps} : UpdateTextAssetButtonProps) {
  return (
    <TypedUpdateTextAssetButton
      assetId={asset.id}
      assetType={AssetTypes.name}
      assetUiName='Name'
      assetValue={getValue(asset.value as NameAssetValue)}
      parseText={parseText}
      {...btnProps}
    />
  );
}

