import React, { PropsWithChildren } from "react";
import { ConversationContext } from "src/components/chat";
import { useActiveThreadId, useExternalConversation } from "src/components/chat/hooks";
import { useBusinessId } from "src/db/hooks";

export function ConversationProvider({ children }: PropsWithChildren) {
  const businessId = useBusinessId();
  const threadId = useActiveThreadId();

  if (!businessId || !threadId) {
    // Not an error, just means the user is not signed in.
    return <>{children}</>
  } else {
    // Seperate component only rendered if thread exists, to reduce error handling. 
    return <ConversationWrapper businessId={businessId} threadId={threadId}> {children} </ConversationWrapper>;
  }
}
interface ConversationProps extends PropsWithChildren {
  businessId: string;
  threadId: string;
}

export function ConversationWrapper({ businessId, threadId, children }: ConversationProps) {
  const conversation = useExternalConversation(businessId, threadId);
  return <ConversationContext.Provider value={conversation}>
    {children}
  </ConversationContext.Provider>
}