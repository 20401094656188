import React from 'react';
import { ButtonProps } from '@chakra-ui/react';

import { AssetTypes } from 'src/db';
import { AudienceAssetValue } from 'src/db/model/assets/audienceAsset';
import { AddTextAssetButton } from './AddTextAsset';

const TypedAddTextAssetButton = AddTextAssetButton<AudienceAssetValue>;
const parseText = (text: string): AudienceAssetValue => {
  return {targetAudience: text};
};

export function AddAudienceAssetButton({ children, ...btnProps} : ButtonProps) {
  return (
    <TypedAddTextAssetButton
      assetType={AssetTypes.audience}
      assetUiName='Audience'
      parseText={parseText}
      {...btnProps}
    >{children}</TypedAddTextAssetButton>
  );
}

