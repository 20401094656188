import React from 'react';

import { BoxProps } from '@chakra-ui/react';

import { MAX_SELECTED_VALUES }  from 'src/api/constants';
import { AddValueAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, ValueAssetDocument, valueAssetConverter } from 'src/db';
import { Path } from 'src/nav';

import { BrandKitCard } from './BrandKitCard'
import { ActionableTextAssets } from './ActionableTextAssets';
import { UpdateValueAssetButton } from 'src/components/assets/updateAsset';

export function ValueCard({...boxProps}: BoxProps) {
  return (
    <BrandKitCard<ValueAssetDocument>
      title='Brand Values'
      assetUiName='Value'
      assetType={AssetTypes.value}
      firestoreConverter={valueAssetConverter}
      reviewPath={Path.value}
      SelectedAssets={ActionableTextAssets}
      SuggestedAssets={ActionableTextAssets}
      selectionLimit={MAX_SELECTED_VALUES}
      selectionLimitErrorMessage={
        `Limit of ${MAX_SELECTED_VALUES} Values. Remove one before adding another`
      }
      AddAssetButton={AddValueAssetButton}
      UpdateAssetButton={UpdateValueAssetButton}
      {...boxProps}
    />
  );
}
