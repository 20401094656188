import { createContext } from 'react';
import { Auth, User } from 'firebase/auth';

export type AuthOrNull = Auth|null;

export const AuthContext = createContext<AuthOrNull>(null);

export type UserOrNull = User|null;

export const UserContext = createContext<UserOrNull>(null);
