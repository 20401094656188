import React, { PropsWithChildren } from "react";
import { initializeApp, FirebaseOptions } from 'firebase/app';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { Analytics } from "firebase/analytics";

import { AnalyticsProvider, setupAnalyticsForEnv } from 'src/analytics';
import { ApiProvider } from 'src/api';
import { AuthProvider, setupAuthForEnv } from 'src/auth';
import { FirestoreProvider, setupDbForEnv } from 'src/db';
import { backendConfig } from 'src/firebaseAndBackendConfig';


interface AppProviderProps {
  auth: Auth;
  fs: Firestore;
  analytics: Analytics;
}

export function setupAppProviderProps(config: FirebaseOptions): AppProviderProps {
  const app = initializeApp(config);
  return {
    auth: setupAuthForEnv(app),
    fs: setupDbForEnv(app),
    analytics: setupAnalyticsForEnv(app),
  };
}

export function AppProvider({
  auth, fs, analytics, children
}: PropsWithChildren<AppProviderProps>) { 
  return (
    <AuthProvider auth={auth}>
      <ApiProvider apiBaseUrl={backendConfig.domainUrl} auth={auth}>
        <FirestoreProvider fs={fs}>
          <AnalyticsProvider analytics={analytics}>
            {children}
          </AnalyticsProvider>
        </FirestoreProvider>
      </ApiProvider>
    </AuthProvider>
  );
}
