import { FirebaseApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';

/**
 * Use synchronously after initializing the firebase app.
 * 
 * (Therfore do not call inside of React)
 */
export function setupDbForEnv(app: FirebaseApp): Firestore {
  const db = getFirestore(app);
  // TODO: Use emulator based on environment.
  return db;
}
