import React, { useCallback, useRef, useEffect } from 'react';

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Image,
  SimpleGrid,
  Tag,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom'

import { Path } from 'src/nav'
import { HeaderMenu, Carousel } from 'src/components';
import { ModalAction } from 'src/components/chat';
import {
  talawa, landingBackground, northStar,
  howItWorks1, howItWorks2, howItWorks3,
} from 'src/images';

import { CreateBusinessConversation } from './createBusiness/CreateBusinessConversation';
import { useCreateBusinessPrefill } from './createBusiness/hooks';
import { Footer } from './layout/Footer'

import { useBusiness } from 'src/db';

const backgroundImageStyles = {
  backgroundImage: `url(${landingBackground})`,
  backgroundSize: 'auto 420px',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: '80px',
};

export function LandingPage() {
  const navigate = useNavigate();
  const tryItBtn = useRef<HTMLButtonElement>(null);
  const toggleModal = useRef<ModalAction>(null);
  const hasPrefill = !!useCreateBusinessPrefill()[0];
  const business = useBusiness();

  useEffect(() => {
    if (business) {
      navigate(Path.home);
    } else {
      navigate(Path.urlOnboarding);
    }
  } , [business, navigate]);

  const openConversation = useCallback(() => {
    if (!!toggleModal.current) {
      toggleModal.current(true);
    }
  }, [toggleModal]);

  return (<>
    <Flex
      as='section'
      direction='column'
      justify='flex-start'
      px={8} py={4}
      boxShadow='section'
      sx={backgroundImageStyles}
    >
      <HeaderMenu hideLogo={true} />
      <Image
        src={talawa}
        alt='Talawa'
        width="50%"
        mixBlendMode='multiply' // remove white background
        mb={6}
      ></Image>
      <Text textStyle='display1' mb={4}>
        Transforming ideas into powerful brands
      </Text>
      <Text textStyle='body' mb={4}>
        Share your business idea and instantly see AI-generated ideas for logos, slogans and more. 
      </Text>
      <Text textStyle='bodySmall' mb={2}>
        Already have a logo? Want to re-brand? Talawa starts with what you have to craft your brand DNA and brand kit so you can unlock ai-supported actions to grow and transform your brand!  
      </Text>
      <Image
        src={northStar}
        role='presentation'
        boxSize='32px'
        mx='auto'
        mb={2}
      />
      <Text textStyle='direction' align='center' mb={2}>
        Tell Us About Your Business
      </Text>
      <CreateBusinessConversation
        finalFocusRef={tryItBtn}
        toggleModalRef={toggleModal}
        minHeight='200px'
        maxHeight='400px' 
        mx={2}
      />
      <Button
        variant='fill'
        ref={tryItBtn}
        onClick={openConversation}
      >{hasPrefill ? 'Create Your Brand' : 'Start Talawa Free'}</Button>
    </Flex> {/* End of section 1 (Hero) */}

    <Box as='section' px={8} py={12}>
      <Text textStyle='display1' align='center'>
        How it works?
      </Text>

      <Carousel duration={5000} alignCarouselItems='start'>
        <VStack align='start'>
          <Image
            src={howItWorks1}
            alt='Build a brand'
            aspectRatio='1/1'
            alignSelf='center'
            maxWidth='400px'
            width="100%"
            mb={6}
          ></Image>
          <Text textStyle='display3' mb={1}>
            Build a brand that stands out from the crowd
          </Text>
          <Text textStyle='body' >
            No templates here. Let our AI-Agent create a one-of-a-kind 
            brand identity -- name, logo, colors and more 
            to set your business apart.
          </Text>
        </VStack>
        
        <VStack align='start'>
          <Image
            src={howItWorks2}
            alt='Attract customers'
            aspectRatio='1/1'
            alignSelf='center'
            maxWidth='400px'
            width="100%"
            mb={6}
          ></Image>
          <Text textStyle='display3' mb={1}>
            Attract customers that value your offerings
          </Text>
          <Text textStyle='body'>
            Attract the right customers. We'll help you define your 
            target audience, values, and brand story so that 
            your business resonates with customers.
          </Text>
        </VStack>

        <VStack align='start'>
          <Image
            src={howItWorks3}
            alt='Show up'
            aspectRatio='1/1'
            alignSelf='center'
            maxWidth='400px'
            width="100%"
            mb={6}
          ></Image>
          <Text textStyle='display3' mb={1}>
            Reach the right people at the right time
          </Text>
          <Text textStyle='body'>
            With predictive insights get what you need daily - posts, actions and more - to stay engaged with customers.
          </Text>
        </VStack>
      </Carousel>
    </Box>  {/* End of section 2 (Carousel) */}

    <Box as='section' px={8} py={20} boxShadow='section'>
      <Text textStyle='display2' align='center' mb={4}>
        Start for Free
      </Text>
      <Text textStyle='display4' align='center' mb={4}>
        Start building your brand kit with just an idea...
      </Text>
      <Text textStyle='body' align='center' mb={6}>
        Immediately get some AI-generated branding ideas (logo, slogans and more) 
        for your brand completely free. 
      </Text>
      <Text textStyle='body2' align='center' mb={8}>
        Already have a name, slogan or another part of your brand
        you still want to use, simply add or upload them and we'll 
        include it in your kit!
      </Text>
      <Button
        variant='fill'
        mb={6}
        width='100%'
        onClick={openConversation}
      >Start Talawa Free</Button>

      <Text textStyle='display2' align='center' mb={6}>
        Want more?
      </Text>

      <Card p={3} mb={4}>
        <CardHeader>
          <Text textStyle='display3' mb={2}>
            1. Complete Your Brand Kit
          </Text>
        </CardHeader>
        <CardBody>
          <Text textStyle='body2'>
            Unlock even more ideas to create the strategic fundamentals of your brand.
          </Text>
          <Text textStyle='displayPrice'>
          Start a subscription or pay a one-time fee.
          </Text>
          <Wrap py={2}>
            <Tag>Name</Tag>
            <Tag>Logo</Tag>
            <Tag>Slogan</Tag>
            <Tag>Target Audience</Tag>
            <Tag>Fonts</Tag>
            <Tag>Colors</Tag>
            <Tag>More</Tag>
          </Wrap>
        </CardBody>
        {/* TODO:> Ready to start? */}
      </Card>

      <Card p={3} mb={4}>
        <CardHeader>
          <Text textStyle='display3' mb={2}>
            2. Get Ongoing access
          </Text>
        </CardHeader>
        <CardBody>
          <SimpleGrid spacing={2} templateColumns='1fr 1fr' mx={-1} mb={2}>
            <Card size='sm' m={1} overflow='hidden'>
              <CardHeader
                bg='primary.veryLight'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='flex-end'
                minHeight={68}
                pb={4}
              >
                <Text textStyle='display4'>Pay-as-you-go</Text>
              </CardHeader>
              <CardBody p={2}>
                <Text textStyle='bodySmall' align='center' mb={4}>
                  Pay-as-you-go to have our <b>AI Agent Brandi</b> complete your creative branding tasks 
                  like 'generate a social media post', 'design holiday packaging' or 
                  't-shirt design ideas' & more.
                </Text>
                <Text textStyle='displayPrice' align='center' mb={4}>
                  $25.00 & up
                </Text>
              </CardBody>
            </Card>
            <Card size='sm' m={1} overflow='hidden'>
              <CardHeader
                bg='primary.veryDark'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='flex-end'
                minHeight={68}
                p={2}
              >
                <Text textStyle='legend' color='secondary.dark'>*Recommended*</Text>
                <Text textStyle='display4' color='grayscale.white'>Subscription Plans</Text>
              </CardHeader>
              <CardBody p={2}>
                <Text textStyle='bodySmall' align='center' mb={4}>
                  Create unlimited projects with <b>AI Agent Brandi</b> PLUS get on-brand,
                  crafted-for-you daily actions (like ready-to-go social posts, tips or tasks) to grow your brand.
                </Text>
                <Text textStyle='displayPrice' align='center'  mb={4}>
                  from $40.00/month
                </Text>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        
        <Text textStyle='reminder' align='center' mb={3}>
          All plans require a Talawa Brand Kit (comes included with subscription packages).
        </Text>

        <Button 
          variant='fill' onClick={() => navigate(Path.pricing)}>View Plans & Pricing Info</Button>
      </Card>
    </Box> {/* End of section 2 (Pricing) */}

    <Footer />
  </>);
}
