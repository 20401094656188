import React, { useContext, useRef } from 'react';

import {
  Box,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'

import { avatar } from 'src/images';
import { appMaxWidth } from 'src/util/constants';

import { ConversationModalContent } from './chat/ConversationModalContent';
import { ConversationContext } from './chat/context';

/**
 * Permanent chat component available everywhere business is available.
 */
export function PermanentChat() {
  const fabRef = useRef<HTMLButtonElement>(null);
  const conversation = useContext(ConversationContext);
  if (Object.keys(conversation).length === 0) {
    // The user is not signed in.
    return <></>
  } else {
    return (
      <>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          position='fixed'
          bottom='0'
          width='100%'
          maxWidth={appMaxWidth}
          margin='0 auto'
        >
          <IconButton
            aria-label='Open Chat'
            ref={fabRef}
            mb='16px'
            mr='16px'
            bg='transparent'
            boxSize='fit-content'
            onClick={() => { conversation.toggleModal(true); }}
            icon={
              <Image
                src={avatar}
                alt='Talawa'
                boxSize="64px"
              />
            }
          />
        </Box>
        <Modal
          onClose={() => { conversation.toggleModal(false); }}
          finalFocusRef={fabRef}
          isOpen={conversation.isModalOpen}
          scrollBehavior='inside'>
          <ModalOverlay />
          <ConversationModalContent />
        </Modal>
      </>);
  }
}
