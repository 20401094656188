import { PropsWithChildren } from 'react';

// Convenient semantic name for empy element. 
export const Nothing = <></>;

// Base interface for a require wrapper.
export interface RequirementProps extends PropsWithChildren {
  // Whether the React children can be rendered 
  metRequirement?: boolean; // Default value is false.

  // The element to render if the requirement is not found.
  OrElse?: JSX.Element; // Default to `Nothing` with a logged error.
}

export interface RequireUserProps extends RequirementProps {
  // Also require the user to be non-anonymous.
  excludeAnonymous?: boolean; // Default to false.
}

export interface RequireProfileProps extends RequireUserProps {
  // Also require the profile to have accepted the Terms of Service and Privacy Policy
  withAcceptedTerms?: boolean; // Default to false.
}

export interface RequireBusinessProps extends RequireProfileProps {
  // FYI: A filter based locked status could go here.
}

