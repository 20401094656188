import React, { forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import { useSwipeable, SwipeEventData } from 'react-swipeable';

import {
  Box,
  BoxProps,
  Card,
  CardBody,
} from '@chakra-ui/react';

import { LogoAssetDocument } from 'src/db';
import { Logo } from 'src/components/assets';

// How spread apart are the different layers of the stack;
const SCALE_FACTOR = .96;

type OnSwipe = () => void;

interface ImageStackProps extends BoxProps {
  logos: LogoAssetDocument[];
  canSwipe?: boolean;
  onSwipeLeft?: OnSwipe;
  onSwipeRight?: OnSwipe;
}

type DropZone = 'middle'|'left'|'right';

export function ImageStack({
    logos,
    canSwipe = true,
    onSwipeLeft = () => {},
    onSwipeRight = () => {},
    ...boxProps
}: ImageStackProps) {
  const containerRef = useRef<HTMLDivElement>()
  const [containerWidth, setContainerWidth] = useState(0);
  const [stackHeight, setStackHeight] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [dropZone, setDropZone] = useState<DropZone>('middle');
  useEffect(() => {
    setDropZone('middle');
  }, [logos]);
  const swipeHandlers = useSwipeable({
    onSwiped: (e: SwipeEventData) => {
      setTranslateX(0);
      if (dropZone === 'left') {
        onSwipeLeft();
      } else if (dropZone === 'right') {
        onSwipeRight();
      } else {
        // Do nothing for middle.
        return;
      }
    },
    onSwipeStart: (e: SwipeEventData) => {
      setContainerWidth(containerRef.current!.clientWidth);
    },
    onSwiping: (e: SwipeEventData) => {
      if (!canSwipe) { return; }
      setTranslateX(e.deltaX);
      if (!containerWidth) { return; }
      
      const minDist = 0.4; // 40% "feels about right"
      if (e.deltaX > (minDist * containerWidth)) {
        setDropZone('right');
      } else if (e.deltaX < (minDist * -containerWidth)) {
        setDropZone('left');
      } else {
        setDropZone('middle');
      }
    },
    trackMouse: true,
  });

  // ResizeObserver is needed because react won't detect the image download causing resize
  const measuredRefWithPassThrough = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      const containerHeight = node.getBoundingClientRect().height;
      const lastCardScale = Math.pow(SCALE_FACTOR, logos.length);
      // Squared because the card is scaled then translated as a percentage of its new sacle
      const lastCardHeight = Math.pow((1 - lastCardScale), 2) * containerHeight;
      setStackHeight(containerHeight + lastCardHeight);
    });
    resizeObserver.observe(node);
    // Because swip handler needs the ref too.
    swipeHandlers.ref(node);
  }, [swipeHandlers, logos]);

  return (
    <Box
      ref={containerRef}
      position='relative'
      height={stackHeight}
      {...boxProps}
    >
      {logos.map((logoDoc, idx, arr) => {
        if (idx === 0) {
          return <ImageCard
            key={logoDoc.id}
            logo={logoDoc}
            offset={idx}
            stackSize={arr.length}
            translateX={translateX}
            dropZone={dropZone}
            imageStyles={{filter: canSwipe ? 'none' : 'grayscale(1)'}}
            {...swipeHandlers}
            ref={measuredRefWithPassThrough} 
          />          
        } else {
          return <ImageCard
            key={idx}
            logo={logoDoc}
            offset={idx}
            stackSize={arr.length}
          />
        }
      })}
    </Box>
  );
}

interface ImageCardProps extends BoxProps {
  logo: LogoAssetDocument;
  offset: number;
  stackSize: number;
  translateX?: number;
  dropZone?: DropZone;
  imageStyles?: Object;
}

const ImageCard = forwardRef<HTMLDivElement, ImageCardProps>(({
  logo,
  offset, stackSize,
  translateX = 0,
  dropZone = 'middle',
  imageStyles = {},
  ...boxProps
}, ref) => {
  translateX = translateX || 0;
  dropZone = dropZone || 'middle';
  const alt = `logo ${offset+1} of ${stackSize}`;

  const scale = Math.pow(SCALE_FACTOR, offset);
  const translateY = `-${(1 - scale) * 100}%`;

  const dropZoneOutlines: Record<DropZone, string> = {
      'middle': 'none',
      'left': '4px solid var(--chakra-colors-secondary-dark)',
      'right': '4px solid var(--chakra-colors-primary-light)',
  };

  const baseStyles = {
    transform: `scale(${scale}) translate(${translateX}px, ${translateY})`,
    zIndex: stackSize-offset,
  };
  const topStyles = {
    outline : dropZoneOutlines[dropZone],
    touchAction : 'pan-y',
  };
  const styles = offset === 0 ? 
    {...baseStyles, ...topStyles}
    : {...baseStyles};

  return (
    <Card
      ref={ref}
      position='absolute'
      width='100%'
      bottom='0'
      style={styles}
      {...boxProps}
    >
      <CardBody p={4}>
        {/* For aspect ratio */}
        <Box position='relative' width='100%' pt='100%'>
          <Logo
            logo={logo}
            alt={alt}
            isDynamic={logo.origin !== 'USER'}
            position='absolute'
            top={0} bottom={0} left={0} right={0}
            boxSize='100%'
            style={imageStyles}
          />
        </Box>
      </CardBody>
    </Card>
  );
});
