import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
// Not currently using analyitcs, but saving this for refence later
// import { getAnalytics } from "firebase/getAnalytics";
// const analytics = getAnalytics(app);

import App from 'src/App';
import { AppProvider, setupAppProviderProps } from 'src/AppProvider';
import { ScrollToTop } from 'src/components';
import { ErrorBoundary } from 'src/util/ErrorBoundary'
import { setupLoggerForEnv, logger, LogSource } from 'src/util/logger';

import { firebaseConfig } from './firebaseAndBackendConfig';

// IMPORTANT: Setup the logger before any other executable code.
setupLoggerForEnv();

window.onerror = function(message, url, lineNumber) {
  logger.error(LogSource.OTHER, `${message}\n${url}:${lineNumber}`);
}

const appProps = setupAppProviderProps(firebaseConfig);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <ScrollToTop />
      <AppProvider {...appProps}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AppProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app...
reportWebVitals((metric) => {
  logger.info(LogSource.APP, JSON.stringify(metric), {skipConsole: true});
});
