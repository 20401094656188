import React from 'react';
import GoogleButton from 'react-google-button';
import { GoogleAuthProvider, signInWithPopup, linkWithPopup } from 'firebase/auth';

import { useToast, UseToastOptions } from '@chakra-ui/react';

import { useAuth } from 'src/auth';

const POPUP_CLOSED_BY_USER = 'auth/popup-closed-by-user';
const PROVIDER_ALREADY_LINKED = 'auth/provider-already-linked';
const CREDENTIAL_ALREADY_IN_USE = 'auth/credential-already-in-use';

interface AuthButtonProps {
  isSignup?: boolean;
}
export function GoogleAuthButton({isSignup = false}: AuthButtonProps) {
  const [auth] = useAuth();
  const toast = useToast();
  const errorProps: UseToastOptions = {
    status: 'error',
    isClosable: true,
    duration: null,
  };
  const label = isSignup ?
      'Signup with Google' : 'Login with Google';
  const doSignIn = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    if (!!auth && !!auth.currentUser) {
      if (isSignup) {
        try {
          await linkWithPopup(auth.currentUser, googleAuthProvider);
        } catch (e: any) {
          if (e.code === POPUP_CLOSED_BY_USER) {
            // ignore
          }
          else if (e.code === PROVIDER_ALREADY_LINKED) {
            toast({
              ...errorProps,
              description: 'You are already linked to a Google account.',
              status: 'error'
            });
          }
          else if (e.code === CREDENTIAL_ALREADY_IN_USE) {
            toast({
              ...errorProps,
              description: 'This account already exists, please use a different one.',
              status: 'error'
            });
          }
        }
      } else {
        toast({
          ...errorProps,
          description: 'If you login with a different account, you will lose your current progress. Try signing up instead.',
          status: 'error'
        });
      }
    } else {
      try {
        await signInWithPopup(auth, new GoogleAuthProvider());
      } catch (e: any) {
        if (e.code === 'auth/popup-closed-by-user') {
          // ignore
        }
      }
    }
  };

  return (<GoogleButton
    style={{minWidth: '50%'}}
    type='light'
    label={label}
    onClick={doSignIn}
  />);
}
