import React from 'react';

import {
  Box,
  BoxProps,
  Card,
  Flex,
  VStack,
} from '@chakra-ui/react';

import { AssetOriginTag } from 'src/components/assets';
import { AssetActions } from 'src/components/assets/actions';
import { AssetTypes, FontAssetDocument, fontAssetConverter } from 'src/db';
import { FontSample } from 'src/components/assets';
import { Path } from 'src/nav';

import { AssetsProps, BrandKitCard } from './BrandKitCard'

export function FontCard({...boxProps}: BoxProps) {

  const SuggestedFonts = ({assets}: AssetsProps<FontAssetDocument>) => {
    return (<Box width='100%'>
      {assets.map((font) => {
        return (<Card key={font.id} width='100%' p={4} mb={2}>
          <VStack justify='start' align='stretch'>
            <FontSample font={font} size='24px' width='100%'/>
            <Flex direction='row' justify='space-between'> 
              <AssetOriginTag origin={font.origin}/>
              <AssetActions asset={font}/>
            </Flex>
            
          </VStack>
        </Card>);
      })}
    </Box>);
  };
  
  // No difference in display on the asset itself.
  const SelectedFonts = SuggestedFonts;

  return (
    <BrandKitCard<FontAssetDocument>
      title='Font'
      assetType={AssetTypes.font}
      firestoreConverter={fontAssetConverter}
      reviewPath={Path.font}
      SelectedAssets={SelectedFonts}
      SuggestedAssets={SuggestedFonts}
      {...boxProps}
    />
  );
}
