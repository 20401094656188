const sansFamily = '"Nunito", sans-serif';
const sansFamilyWider = '"Inter", sans-serif';
const sansFamilyFuller = '"Poppins", sans-serif';
const defaultFont = {
  fontFamily: sansFamily,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: 'normal',
  lineHeight: 'normal',
};

const textStyles = {
  /**
   * STANDARD TEXT
   * 
   * Default texts without any font weight for standard information.
   */

  // Primary body text.
  // The default font for information.
  body: {
    ...defaultFont,
  },
  // Secondary body text.
  // Used for longer passages, or to create heirarchy below the primary body.
  body2: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '14px',
  },
  // Larger body text.
  // Used to create heirarchy above standard buttons/links.
  bodyLarge: {
    ...defaultFont,
    fontSize: '18px',
  },
  // Smaller body text for supplemental information.
  bodySmall: {
    ...defaultFont,
    fontSize: '12px',
  },

  /**
   * EMPHASIZED BODY TEXT
   * 
   * Emphasized parodies of the body text sizes, to convey more imperative information.
   */

  // An emphasized parody of the primary body
  // Used for providing explanation or instructions when more than a title is needed.
  direction: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '20px',
    fontWeight: '700',
  },
  // A larger caption typically used for labeling images.
  label: {
    ...defaultFont,
    fontSize: '15px',
    fontWeight: '500',
  },
  // An even larger caption typically used for labeling zoomed in images.
  largeLabel: {
    ...defaultFont,
    fontSize: '23px',
    fontWeight: '700',
  },
  // An emphasized parody of the smaller body
  // Used for information that is supplemental but still needs attention drawn.
  caption: {
    ...defaultFont,
    fontSize: '12px',
    fontWeight: '600',
  },
  // A very small caption for labeling axes on graphical widgets
  legend: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '10px',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  // An emphasized text style for non-distracting information.
  reminder: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'italic',
  },

  /**
   * CHAT COMPONENTS
   * 
   * Typography used within elements of the chat components
   */

  // The size of text in chat bubbles
  chat: {
    ...defaultFont,
    fontSize: '14px',
  },
  // The size of text for input elements
  input: {
    // TODO: Ask for example of this in new UI spec.
    // For now, just mirroing the chat text.
    ...defaultFont,
    fontSize: '14px',
  },
  // The size of text for labeling the chat avatars
  avatar: {
    ...defaultFont,
    fontSize: '12px',
    fontWeight: '700',
  },

  /**
   * BUTONS AND TAGS
   * 
   * Typography sizes for buttons and tags
   */

  // Standard button size
  button: {
    ...defaultFont,
    fontSize: '16px',
    fontWeight: '600',
  },
  // Smaller button size for denser options, or supplental actions
  buttonSmall: {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '600',
  },
  // Larger buttons size for special buttons (uncommon)
  buttonLarge: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '20px',
  },
  // Extra-large buttons size (very uncommon)
  // Used for emphasized actions of the nav menu.
  buttonXLarge: {
    ...defaultFont,
    fontSize: '24px',
    fontWeight: '500',
  },

  // Standard chip size
  chip: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '16px',
  },
  // Smaller chip size for denser options
  // UNUSED, reserving name for future.
  chipSmall: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '14px',
  },

  /**
   * DISPLAY TEXTS
   * 
   * Larger than normal text. Primary used on the landing page
   */ 

  display1: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '36px',
    fontWeight: '600',
    lineHeight: '110%',
  },
  display2: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '30px',
    fontWeight: '600',
  },
  display3: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '24px',
    fontWeight: '700',
  },
  display4: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '20px',
    fontWeight: '500',
  },
  displayPrice: {
    ...defaultFont,
    fontFamily: sansFamilyWider,
    fontSize: '16px',
    fontWeight: '600',
  },
  displayCreditsIcon: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '10px',
    fontWeight: '500',
  },
  displayCreditsLarge: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '22px',
    fontWeight: '500',
  },

  /**
   * TITLE TEXTS
   * 
   * Titles for different types of containers.
  */

  // Largest Title
  // Should never be more than one on screen.
  titleForPage: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '26px',
    fontWeight: '600',
  },
  // Title for medium to large sections
  // May also be used as a page title for continuity when a
  // sections expands into a page. 
  titleForSection: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '20px',
    fontWeight: '600',
  },
  // Title within a section
  // Used for Tabs or above a group of paragraphs. 
  titleForGroup: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '18px',
    fontWeight: '500',
  },
  // Title for smaller/supplemental widgets
  titleForWidget: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '14px',
    fontWeight: '600',
  },
  // Title for Brand Action cards
  titleForAction: {
    ...defaultFont,
    fontFamily: sansFamilyFuller,
    fontSize: '10px',
    fontWeight: '400',
  },
};
export default textStyles;
