import React, { PropsWithChildren, useState, useEffect } from "react";

import {
  Auth,
  AuthError,
  UserCredential,
  browserSessionPersistence,
  getRedirectResult,
  onAuthStateChanged, 
  setPersistence,
} from 'firebase/auth';

import { logger, LogSource } from 'src/util/logger';

import { AuthContext, UserContext, AuthOrNull, UserOrNull } from './contexts';

interface AuthProviderProps extends PropsWithChildren {
  auth: Auth;
};

export function AuthProvider({ auth, children }: AuthProviderProps) {
    const [isAuthReady, setIsAuthReady] = useState<boolean>(false);
    const [isPersistenceReady, setIsPersistenceReady] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<UserOrNull>(null);
    const loadedAuth: AuthOrNull = (isAuthReady && isPersistenceReady) ? auth : null;

    useEffect(() => {
      // From this level, *only log* redirect results/errors, to catch unexpected events.
      // Let individual pages process the results or toast the errors,
      // when they are expected to occur.
      getRedirectResult(auth).then((cred: UserCredential|null) => {
        if (!!cred) {
          logger.info(LogSource.AUTH, `Redirect Result: ${cred}`, {silent: true});
        }
      }).catch((e: AuthError) => {
        logger.error(LogSource.AUTH, 'Redirect Error', e);
      });

      setPersistence(auth, browserSessionPersistence).then(() => {
        setIsPersistenceReady(true);
      });

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        logger.updateUser(user);
        logger.info(LogSource.AUTH, `onAuthStateChanged: ${user}`, {silent: true});
        setCurrentUser(user);
        setIsAuthReady(true);
      });
      return unsubscribe;
    }, [auth]); 
    
    return (
      <AuthContext.Provider value={loadedAuth}>
        <UserContext.Provider value={currentUser}>
          {children}
        </UserContext.Provider>
      </AuthContext.Provider>
    );
}
