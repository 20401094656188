import React, { useEffect, useRef } from 'react';

import {
  Box,
  BoxProps,
} from '@chakra-ui/react'

export function AutoScrollBox ({children, ...boxProps}: BoxProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  // scroll smoothly on change of children
  useEffect(() => {
    const containerDiv = containerRef.current!;
    const scrollHeight = containerDiv.scrollHeight;

    containerDiv.scrollTo({
      top: scrollHeight,
      left: 0,
      behavior: "smooth"
    });
  }, [children]);
  
  return (
    <Box
      ref={containerRef}
      {...boxProps}
      overflowY='auto'
    >{children}</Box>
  )
};
