import React, { useContext } from "react";

import { ProfileContext } from 'src/db';

import { AwaitProfile } from './AwaitProfile';
import { Requirement } from './Requirement';
import { RequireProfileProps } from './Props';
import { RequireUser } from './RequireUser';

/**
 * Ensures children will have access to a loaded and non-null ProfileContext
 */
export function RequireProfile({...requireUserProps} : RequireProfileProps) {
  return (
    <RequireUser {...requireUserProps}>
      <AwaitProfile>
        <RequireProfileInner {...requireUserProps} />
      </AwaitProfile>
    </RequireUser>
  );
}

function RequireProfileInner({withAcceptedTerms, ...requirementProps} : RequireProfileProps) {
  const profile = useContext(ProfileContext).profile;
  const hasProfile = !!profile;
  const hasTerms = hasProfile && profile.termsOfService.accepted && profile.privacyPolicy.accepted;
  
  return <Requirement {...requirementProps} metRequirement={withAcceptedTerms ? hasTerms : hasProfile}  />
}
