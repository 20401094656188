import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** 
 * Include this inside the router to scroll to the top of the page
 * whenever the Path changes.
 */
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
