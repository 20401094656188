import { useEffect } from 'react';
/*
  Use this hook to schedule an effect to run on the next event loop.

  This is the preferred way to call an API insdie of a useEffect.
  Example:
  ```
    const createBusiness = useCallback(() => {
      if (shouldCreateBusiness) {
        api.createBusiness({...})
      }
    }, [shouldCreateBusiness, api]);

    useEffectLate(createBusiness);
  ```

  In this example, `shouldCreateBusiness` should be responsive to the ApiPending set,
  to prevent an infinite loop.
*/
export function useEffectLate(callback: () => void) {
  useEffect(() => {
    const timerId = setTimeout(callback, 0);
    return (() => { clearTimeout(timerId); });
  }, [callback])
}
