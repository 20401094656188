import React from 'react';
import { ButtonProps } from '@chakra-ui/react';

import { AssetTypes } from 'src/db';
import { NameAssetValue } from 'src/db/model/assets/nameAsset';
import { AddTextAssetButton } from './AddTextAsset';

const TypedAddTextAssetButton = AddTextAssetButton<NameAssetValue>;
const parseText = (text: string): NameAssetValue => {
  return {name: text};
};

export function AddNameAssetButton({ children, ...btnProps} : ButtonProps) {
  return (
    <TypedAddTextAssetButton
      assetType={AssetTypes.name}
      assetUiName='Name'
      parseText={parseText}
      {...btnProps}
    >{children}</TypedAddTextAssetButton>
  );
}

