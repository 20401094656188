import React, { useState } from 'react';
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdLockOutline } from 'react-icons/md';

import { useApi } from 'src/api';
import { useBusinessId } from 'src/db';
import { Path, useNavigateWithParams } from 'src/nav';

export function UnlockBrandKitButton({ ...buttonProps }: ButtonProps) {
  const [api, surfaceKnownErrors] = useApi();
  const businessId = useBusinessId()!;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isUnlocking, setIsUnlocking] = useState<boolean>(false);
  const navigate = useNavigateWithParams();

  const unlockBrandKit = () => {
    setIsUnlocking(true);
    api.unlockBrandKit({ businessId }, surfaceKnownErrors).finally(() => {
      setIsUnlocking(false);
      navigate({ to: Path.home});
      onClose();
    })
  };

  return (<>
    <Button
      variant='link'
      colorScheme='primary'
      {...buttonProps}
      onClick={() => { onOpen() }}
    >Unlock Brand Kit</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true} 
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Icon
              aria-label='Unlock Brand Kit'
              as={MdLockOutline}
              color='secondary.dark'
              boxSize='64px'
            />
          </Center>
        </ModalHeader>
        <ModalBody>
          <Text align='center' textStyle='body2'>
            Unlocking your brand allows you to make changes if needed. You can re-lock your brand later 
            on.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex width='100%' direction='row' align='center' wrap='wrap'>
            <Button
              variant='outline'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isDisabled={isUnlocking}
              onClick={onClose}
            >Not for now</Button>
            <Button
              variant='fill'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isLoading={isUnlocking}
              loadingText='Unlocking...'
              onClick={() => {unlockBrandKit();}}
            >Yes, Unlock Brand</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
}
