export enum Path {
  // Account Managaement Page
  account = '/account',
  // Credit Managaement Page
  credits = '/credits',

  // Signup & Login
  signup = '/signup',
  login = '/login',
  logout = '/logout',

  // Handle a Firebase action from an email link.
  handleAction = 'handleAction',

  // Landing Page (for unsigned users)
  landing = '/',
  newLanding = 'https://talawa.ai', // New landing page on external site
  // Create a new business
  create = '/create',
  // Pricing Page (for signed/unsigned users)
  pricing = '/pricing',
  newPricing = 'https://talawa.ai/pricing', // New pricing page on external landing page

  // Home Page (only for signed users)
  home = '/home',

  // Brandkit Dashboard
  brandkit = '/brandkit',
  brandactions = '/actions',
  brandreports = '/reports',
  brandprints = '/prints',  // Not Impomented!

  // Detail Pages for Brandkit
  audience = '/audience',
  color = '/color',
  font = '/font',
  logo = '/logo',
  name = '/name',
  slogan = '/slogan',
  brandStory = '/brandStory',
  value = '/value',

  // Alternative onboarding
  updateBrandStory = '/updateBrandStory',
  updateBusinessDifferentiator = '/updateBusinessDifferentiator',
  urlOnboarding = '/urlOnboarding',

  // Error Path
  error = '/error'
}

export enum Param {
  businessId = 'businessId',
}

export enum SearchParam {
  redirectTo = 'redirectTo',
  // Used to get response from checkout session.
  checkoutResponse = 'checkoutResponse',
  // Used to focus on a specific section of the page.
  focusSection = 'focusSection',
  // Used to to prefill the CreateBusinessConversation component
  // Value is base64 and urlsafe encoded, in the shape of:
  // {name: string; description: string} (after decoding) 
  createBusinessPrefill = 'businessPrefill',
  // Used to share access to an unclaimed business in the URL
  claimBusiness = 'claimBusiness',
  // Used to show congratulation popup on brandkit redirect
  congratulations = 'congratulations',

  // These values are created by Firebase Auth. Do not rename.
  handleActionMode = 'mode', // 'resetPassword'|'recoverEmail'|'verifyEmail'
  handleActionCode = 'oobCode',
  handleActionContinueUrl = 'continueUrl',
  handleActionLang = 'lang',
}
