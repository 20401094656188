import { AssetDocument, AssetDocumentConverter } from '../asset';

export interface FontAssetValue {
  font: {
    name: string;
    url: string;
    weight: string;
  }
}

export type FontAssetDocument = AssetDocument<FontAssetValue>;

class FontAssetDocumentConverter extends AssetDocumentConverter<FontAssetValue> {
  override assetValueFromFirestore(assetValue: any) : FontAssetValue {
    return { 
      font: {
        name: assetValue.font.name,
        url: assetValue.font.url,
        weight: assetValue.font.weight,
      }
    };
  }
}

export const fontAssetConverter = new FontAssetDocumentConverter();
