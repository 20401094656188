import React from 'react';

import {
  BoxProps,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';

import { ColorsAssetDocument } from 'src/db';
import { getTextColor } from 'src/util/color';

interface ColorGridProps extends BoxProps {
  colors: ColorsAssetDocument
}
export function ColorGrid({colors, ...boxProps}: ColorGridProps) {
  const hexCodes = colors.value.color.hexCodes;

  return (
    <Grid
      templateColumns={`repeat(${hexCodes.length}, 1fr)`}
      templateRows='repeat(1, 1fr)'
      gap={0}
      {...boxProps}
    >
      {hexCodes.map((hexCode, i) => {
        return <GridItem
          key={`${hexCode}-${i}`}
          rowSpan={1}
          colSpan={1}
          minWidth='16px'
          minHeight={10}
          bg={hexCode}
          aria-label={hexCode}
        >
          <Text textStyle='legend' color={getTextColor(hexCode) === "white" ? 'grayscale.light' : 'grayscale.dark'} aria-hidden={true}>{hexCode}</Text>
        </GridItem>
      })}
    </Grid>
  );
}
