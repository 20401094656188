import React from 'react';

import {
  BoxProps,
  Center,
  Text,
  VStack,
} from '@chakra-ui/react'

export function Footer({ ...boxProps }: BoxProps) {
  return (
    <VStack
      as='footer'
      align='left'
      background='primary.veryDark'
      color='grayscale.white'
      p={8}
      {...boxProps}
    >
      <Text textStyle='bodyLarge'  mb={4}>
        Connect with us at <a href="mailto:support@talawa.ai"><em>support@talawa.ai</em></a>
      </Text>
      <Center mb={6}>
        <Text textStyle='body2' color='primary.medium2'>
          © 2024 Talawa Inc., All Rights reserved
        </Text>
      </Center>
    </VStack>
  );
}
