import React, { SyntheticEvent, FormEvent, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Text,
  useDisclosure,
  Input,
} from '@chakra-ui/react';

import { useApi } from 'src/api';
import { useBusinessId } from 'src/db';

export function AddFontAssetButton({ children, ...buttonProps }: ButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isSubmitting) { return; }
    setIsSubmitting(true);
    const data = new FormData(e.target as HTMLFormElement);
    api.addFontAsset(data, surfaceKnownErrors).then(() => {
      onClose();
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (<>
    <Button
      {...buttonProps}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        onOpen();
      }}
    >{children}</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader>
          <Center>
            <Text textStyle='bodyLarge'>Add Font (.ttf)</Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Flex
              direction='row'
              align='center'
              justify='space-between'
              wrap='wrap'
            >
              <Text>Font name:</Text>
              <Input name='fontName'
                ref={inputRef}
                type='text'
                placeholder='Enter font name or upload one'
                width='100%'
                marginBottom={2}
                isRequired
              />
              <input type='hidden' name='businessId' value={businessId} />

              <Spacer flex='12px 0 8px' />
              <Input type='file' name='file'/>
              <Spacer flex='0 0 8px' />
              <Button
                type='submit'
                variant='fill'
                colorScheme='primary'
                size='sm'
                flex='0 0 fit-content'
                isLoading={isSubmitting}
              >Add</Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>);
}
