import React from 'react';
import { ButtonProps } from '@chakra-ui/react';

import { AssetTypes } from 'src/db';
import { SloganAssetValue } from 'src/db/model/assets/sloganAsset';
import { AddTextAssetButton } from './AddTextAsset';

const TypedAddTextAssetButton = AddTextAssetButton<SloganAssetValue>;
const parseText = (text: string): SloganAssetValue => {
  return {slogan: text};
};

export function AddSloganAssetButton({ children, ...btnProps} : ButtonProps) {
  return (
    <TypedAddTextAssetButton
      assetType={AssetTypes.slogan}
      assetUiName='Slogan'
      parseText={parseText}
      {...btnProps}
    >{children}</TypedAddTextAssetButton>
  );
}

