import React, { useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  RadioGroup,
  Radio,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Text,
  VStack,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

import { useClipboardWithToast } from 'src/components'; 
import { logger, LogSource } from 'src/util/logger';

// Run this check before rendering button, to minimize surfaced errors
export function canUseNavigatorShare(): boolean {
  const navigator = window.navigator;
  return !!navigator.canShare;
};

interface ShareData {
  url?: string 
  text?: string;
  title?: string;
  files?: File[];
}

// TODO: Add more options. This is sufficient for MVP2.
enum ShareFormat {
  IMAGE_ONLY = 'IMAGE_ONLY',
  IMAGE_AND_CAPTION = 'IMAGE_AND_CAPTION',
}

interface MetaData {
  type?: string;
}

/**
 * This component is a workaround for the `download` attribute not being respected
 * by certain browsers. It fetches the image to create an ObjectUrl from the blob instead. 
 */
interface ShareImageButtonProps extends ButtonProps {
  imageUrl: string;
  caption?: string;
  // Function to run after download finishes.
  onComplete?: () => void;
}
export function ShareImageButton({
  imageUrl, caption = '', onComplete,
  children, ...buttonProps
}: ShareImageButtonProps) {
  const toast = useToast();
  const { onCopy } = useClipboardWithToast(caption, 'Caption copied'); 
  const popoverShareButton = useRef<HTMLButtonElement>(null);  
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [shareFormat, setShareFormat] = useState<string>(ShareFormat.IMAGE_ONLY);
  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover
  } = useDisclosure();

  const downloadImageFromUrl = () => {
    if (isFetching) { return; } 
    setIsFetching(true);
    // Must copy before async logic, to be a part of the onClick context.
    if (shareFormat === ShareFormat.IMAGE_ONLY) {
      onCopy();
    }
    const extenstion = (imageUrl.match(/\.[0-9a-z]+$/i) || ['.png'])[0];
    const metaData: MetaData = {};
    fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
    }).then((response) => {
      metaData.type = response.type;
      return response.blob();
    }).then((blob) => {
      const fileName = `image${extenstion}`;
      const imageFile = new File([blob], fileName, metaData);

      const shareData: ShareData = {};
      switch (shareFormat) {
        case ShareFormat.IMAGE_ONLY:
          shareData.files = [imageFile];
          break;
        case ShareFormat.IMAGE_AND_CAPTION:
          shareData.files = [imageFile];
          shareData.text = caption;
          break;
        default:
          throw Error(`Invalid ShareFormat: ${shareFormat}`);
      }

      const navigator = window.navigator;
      if (!!navigator.canShare && navigator.canShare(shareData)) {
        return navigator.share(shareData)
      } else {
        if (!navigator.canShare) {
          throw new Error('`Navigator#canShare` is not defined');
        } else {
          throw new Error('`Navigator` cannot `#share` data');
        }
      }
    }).then(() => {
      logger.info(LogSource.APP, 'Successfully shared', {silent: true});
    }).catch((e) => {
      toast({
        status: 'error',
        description: `${e}`,
      });
    }).finally(() => {
      setIsFetching(false);
      closePopover();
      if (!!onComplete) {
        onComplete();
      }
    });
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      onOpen={openPopover}
      onClose={closePopover}
      initialFocusRef={popoverShareButton}
      placement='top'
    >
      <PopoverTrigger>
        <Button
          variant='fill'
          colorScheme='primary'
          {...buttonProps}
        >
          {children || 'Share'}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader textStyle='titleForGroup'>
          Select a format:
        </PopoverHeader>
        <PopoverBody>
        <RadioGroup
          value={shareFormat}
          onChange={setShareFormat}
        >
          <VStack align='left'>
            <Radio value={ShareFormat.IMAGE_ONLY} defaultChecked={true}>
              <Text textStyle='titleForWidget'>Image Only</Text>
              <Text textStyle='bodySmall'>
                For Instagram Post<br />Caption will be copied to clipboard separately.
              </Text>
            </Radio>
            {!!caption && <Radio value={ShareFormat.IMAGE_AND_CAPTION}>
              <Text textStyle='titleForWidget'>Image and Text</Text>
            </Radio>}
          </VStack>
        </RadioGroup>
        </PopoverBody>
        <PopoverFooter>
          <Button
            variant='fill'
            colorScheme='primary'
            display='block'
            mx='auto'
            minWidth='50%'
            isLoading={isFetching}
            onClick={() => {
              downloadImageFromUrl();
            }}
          >Share</Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
