import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';
import { ExploreEducationActionData } from './actions/exploreEducationAction';
import { RegisterDomainActionData } from './actions/registerDomainAction';
import { ShareSocialMediaPostActionData } from './actions/shareSocialMediaPostAction';

export enum ActionTypes {
  exploreEducation = 'exploreEducation',
  registerDomain = 'registerDomain',
  shareSocialMediaPost = 'shareSocialMediaPost',
  shareSocialMediaPostPreview = 'shareSocialMediaPostPreview',
};
export type ActionDataTypes = RegisterDomainActionData|ExploreEducationActionData|ShareSocialMediaPostActionData;

/**
 * All Action Datas For Convenient Reference:
 * 
 *  value: {
 *    shareSocialMediaPost?: {...};
 *    exploreEducation?: {...};
 *    registerDomain?: {...};
 *  }
 */

export interface ActionDocument<D = ActionDataTypes> {
  id: string;
  businessId: string;
  actionType: ActionTypes;
  isPreview: boolean;
  
  hasAvailability: boolean;
  availableDate: number;
  dueDate: number;
  isCompleted: boolean;
  completedDate: boolean;

  actionData: D;
}

export abstract class ActionDocumentConverter<D = ActionDataTypes>
    implements FirestoreDataConverter<ActionDocument<D>> {
  
  protected actionDataFromFirestore(brandActionData: any) : D {
    throw new Error("Not Implemented");
  }

  protected actionDataToFirestore(actionData: any) : any {
    throw new Error("Not Implemented");
  }

  toFirestore (action: ActionDocument<D>) {
    return {
      businessId: action.businessId,
      brandActionType: action.actionType,
      isPreview: action.isPreview,
      hasAvailability: action.hasAvailability,
      availableDate: action.availableDate,
      dueDate: action.dueDate,
      isCompleted: action.isCompleted,
      completedDate: action.completedDate,
      brandActionData: this.actionDataToFirestore(action.actionData),
    }
  }

  fromFirestore (snapshot: DocumentSnapshot, options: SnapshotOptions): ActionDocument<D> {
    const snapshotData = snapshot.data(options)!;
    const actionData = this.actionDataFromFirestore(snapshotData.brandActionData)
    return {
      id: snapshot.id,
      businessId: snapshotData.businessId,
      actionType: snapshotData.brandActionType,
      isPreview: snapshotData.isPreview,
  
      hasAvailability: snapshotData.hasAvailability,
      availableDate: snapshotData.availableDate,
      dueDate: snapshotData.dueDate,
      isCompleted: snapshotData.isCompleted,
      completedDate: snapshotData.completedDate,

      actionData: actionData,
    }
  }
}
