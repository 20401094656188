import React, { PropsWithChildren, useContext } from "react";

import { ProfileContext } from 'src/db';

import { AwaitUser } from './AwaitUser';
import { LargeProgress } from './LargeProgress';

/**
 * Ensures children will have access to a loaded ProfileContext
 * However, the context may be null.
 */
export function AwaitProfile({children} : PropsWithChildren) {
  const profileContext = useContext(ProfileContext);

  return <AwaitUser>
    {profileContext.isLoading ?  <LargeProgress /> : children }
  </AwaitUser>
}
