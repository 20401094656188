import React, { Component, ErrorInfo, ReactNode } from 'react';

import { logger, LogSource } from 'src/util/logger';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    logger.severe(LogSource.APP, 'ErrorBoundary Reached', error)
  }

  public render() {
    if (this.state.hasError) {
      return <p>Something went wrong. Please contact support</p>
    }

    return this.props.children;
  }
}
