import React, {ChangeEvent, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleAuthProvider, UserCredential, getRedirectResult, sendEmailVerification } from 'firebase/auth';

import {
  Avatar,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  BoxProps,
  Button,
  Card,
  CardHeader,
  CardBody,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  StackDivider,
  Text,
  TextProps,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { ApiMethods, useApi, useApiPending } from 'src/api';
import { ToUpdateProfileInfo } from 'src/api/messages/UpdateProfile';
import { useAuth, useUser } from 'src/auth';
import { DeleteUserButton, GoogleAuthButton, ResetPasswordButton } from 'src/components/auth';
import { ProfileTier, useProfileId, useProfileInfo, useProfileTier } from 'src/db';
import { Path, useNavigateWithParams } from 'src/nav';
import { useEffectLate } from 'src/util/async';
import { logger, LogSource } from 'src/util/logger';

import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { RequireProfile } from './wrappers/RequireProfile';
import { stripeConfig } from 'src/firebaseAndBackendConfig';

import { MdCheck, MdClose, MdEdit, MdPayment, MdPerson, MdWarning, MdVerified } from 'react-icons/md';
import { FaGoogle } from "react-icons/fa6";

export function AccountPage() {
  return (
    <HeaderFooterPage pageTitle='Account' >
      <RequireProfile excludeAnonymous={true} OrElse={<NoAccount/>}>
        <AccountPageContent/>
      </RequireProfile>
    </HeaderFooterPage>
  );
}

function NoAccount() {
  return (<Alert status='error'>
    <AlertIcon />
    <AlertTitle>Account Not Found</AlertTitle>
    <AlertDescription>No user is logged in, or no profile was created</AlertDescription>
  </Alert>);
}

const tabStyles = {
  border: 'none',
  borderBottom: '2px solid',
  borderColor: 'primary.light',
  color: 'primary.dark',
  textStyle: 'titleForGroup',
  fontWeight: 'normal',
};
const selectedTabStyles = {
  color: 'primary.veryDark',
  borderColor: 'secondary.dark',
  fontWeight: 'bold',
};

const openCustomerPortal = (email: string | undefined) => {
  let redirectLink = stripeConfig.customerPortalBaseLink;
  if(email) {
    redirectLink = redirectLink + `?prefilled_email=${email}`;
  }

  window.open(redirectLink, '_blank');
};

function AccountPageContent() {
  const profileInfo = useProfileInfo()!;
  const profileTier = useProfileTier()!;
  const displayName = profileInfo.name || '';
  const avatarDisplayName = profileInfo.name || '';
  const photoUrl = profileInfo.profileImageInfo?.imageUrl || '';
  const navigate = useNavigateWithParams();
  
  const goToCredits = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    navigate({ to: Path.credits });
  }, [navigate]);

  return (<>
    <UpdateProfileOnRedirectResult />
    <Card p={4} mb={8}>
      <CardHeader display='flex' flexDirection='row' alignItems='flex-start'>
        <Text flex='1 0 0' textStyle='display4' mt={4}>Hi {displayName}</Text>
        <Avatar boxSize='80px' name={avatarDisplayName} src={photoUrl} ml={2}/>
      </CardHeader>
      <CardBody>
        <Text textStyle='body'>Current Plan</Text>
        <Card p={2} mb={2}
          variant='outline'
          bg='primary.veryLight'
          border='1px solid' borderColor='primary.dark' 
        >
          <PlanDetails tier={profileTier}/>
        </Card>
      </CardBody>
      <Button
        colorScheme='primary'
        variant='outline'
        onClick={goToCredits}
      >View or Add Credits</Button>
    </Card>

    <Tabs variant='enclosed' align='center' isFitted={true}>
      <TabList>
        <Tab {...tabStyles} _selected={selectedTabStyles}>
          <HStack>
            <Icon as={MdPerson}/>
            <span>Account Info</span>
          </HStack>
        </Tab>
        <Tab {...tabStyles}  _selected={selectedTabStyles}>
          <HStack>
            <Icon as={MdPayment}/>
            <span>Payment</span>
          </HStack>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <VStack align='stretch' spacing='16px'>
            <Text textStyle='titleForGroup'>Account Info</Text>
            <VStack align='stretch' divider={<StackDivider borderColor='grayscale.medium' />}>
              <VerifyableEmail />
              <ResettablePassword />
            </VStack>
            <Text textStyle='titleForGroup'>Contact Info</Text>
            <VStack align='stretch' divider={<StackDivider borderColor='grayscale.medium' />}>
              <EditableName key='name' />
              <EditablePhone key='phone' />
              <EditableAddress key='address' />
            </VStack>
            <Text textStyle='titleForGroup'>Linked Accounts</Text>
            <VStack align='stretch' divider={<StackDivider borderColor='grayscale.medium' />} mb='32px'>
              <LinkableGoogle key='google'/>
            </VStack>
            <DeleteUserButton width='fit-content' mx='auto'/>
          </VStack>
        </TabPanel>
        <TabPanel>
          <Button
            variant='outline'
            colorScheme='primary'
            onClick={() => openCustomerPortal(profileInfo.email)}
          >Manage Payment</Button>
        </TabPanel>
      </TabPanels>
    </Tabs>  
  </>);
}

type InputOrTextarea = HTMLInputElement|HTMLTextAreaElement;


function VerifyableEmail() {
  const errorHandler = useAuth()[1];
  const toast = useToast();
  const user = useUser(true)!;
  const email = user.email || '(anonymous)';
  const isVerified = user.emailVerified;
  const [isSendingVerification, setIsSendingVerification] = useState<boolean>(false);

  const sendVerification = () => {
    if (!user || isSendingVerification) {return false;}
    setIsSendingVerification(true);
    sendEmailVerification(user).then(() => {
      toast({
        status: 'info',
        description: <p>Sent verification email to:&nbsp;<em>{email}</em></p>,
      });
    }).catch(errorHandler).finally(() => {
      setIsSendingVerification(false);
    });
  };

  return (<Flex direction='row' align='stretch'>
    <VStack flex='1 0 0' align='start'>
      <Text as='span' textStyle='titleForWidet'>Email</Text>
      <Text as='span' textStyle='body'>
        <span>{email}&nbsp;</span>
        {isVerified ?
          <Popover size='sm'>
            <PopoverTrigger>
              <span><Icon as={MdVerified} color='green.500' tabIndex={0} cursor='pointer'/></span>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Verified Email Address</PopoverHeader>
            </PopoverContent>
          </Popover> : 
          <span>
            <Button
              isLoading={isSendingVerification}
              mx='auto'
              onClick={() => {
                sendVerification();
              }}
            ><Icon as={MdWarning} color='orange.500' />Verify Email</Button>
          </span>
        }
      </Text>
    </VStack>
    {/* TODO: Change email Button */}
  </Flex>);
}

function ResettablePassword() {
  const email = useUser(true)!.email || '';

  return (<Flex direction='row' align='stretch'>
    <VStack flex='1 0 0' align='start'>
      <Text as='span' textStyle='titleForWidet'>Password</Text>
      <ResetPasswordButton email={email} variant='ghost' m={0} p={0} height='fit-content' />
    </VStack>
  </Flex>);
}

// TODO: Move this into shared components. Social Media Post has a similiar function.
interface EditableFieldProps {
  label: string;
  multiline: boolean;
  startingValue: string;
  placeholder?: ReactNode;
  handleSave: (input: string) => Promise<any>;
}
function EditableField({
  label, multiline, startingValue, placeholder = '', handleSave 
}: EditableFieldProps) {
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [currentValue, setCurrentValue] = useState<string>(startingValue);
  const canSubmit = currentValue !== startingValue;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const enterEditMode = useCallback(() => {
    setIsEditMode(true);
    if (multiline) {
      setTimeout(() => {
        textareaRef.current!.focus();
      }, 0);
    } else {
      setTimeout(() => {
        inputRef.current!.focus();
      }, 0);
    }
    
  }, [multiline, inputRef, textareaRef, setIsEditMode]);

  const exitEditMode = useCallback(() => {
    setIsEditMode(false);
    setTimeout(() => {
      editButtonRef.current?.focus();
    }, 0);
  }, [editButtonRef, setIsEditMode]);

  const handleInputChange = useCallback((e: ChangeEvent<InputOrTextarea>) => {
    setCurrentValue(e.target.value);
  }, [setCurrentValue]);

  const saveValue = useCallback(() => {
    if (!canSubmit) { return; }
    setIsSaving(true);
    handleSave(currentValue).finally(() => {
      setIsSaving(false);
      exitEditMode();
    });
  }, [handleSave, currentValue, canSubmit, setIsSaving, exitEditMode]);

  const textStyleProps: TextProps = useMemo(() => {
    return {
      align: 'left',
      textStyle: 'body',
      width: '100%',
      whiteSpace: 'pre-line',
    };
  }, []);
  
  return (<Flex direction='row' align='stretch'>
    <VStack flex='1 0 0' align='start'>
      <Text as='span' textStyle='titleForWidet'>{label}</Text>
      {isEditMode ?
        (multiline ?
          <Textarea ref={textareaRef} value={currentValue} onChange={handleInputChange} /> :
          <Input ref={inputRef} value={currentValue} onChange={handleInputChange} />
        ) :
        <Text
          {...textStyleProps}
          fontStyle={!!currentValue ? 'inherit' : 'italic'}
        >{currentValue || placeholder}</Text>
      }
    </VStack>
    <HStack flex='0 0 fit-content' gap={2} align='start'>
      {isEditMode ? <>
        <IconButton
          variant='ghost'
          aria-label='Submit'
          fontSize='24px'
          icon={<MdCheck />}
          isDisabled={!canSubmit}
          isLoading={isSaving}
          onClick={() => { saveValue(); }}
        />
        <IconButton
          variant='ghost'
          aria-label='Cancel'
          fontSize='24px'
          icon={<MdClose />}
          onClick={() => { exitEditMode(); }}
        />
      </> :
        <IconButton
          variant='ghost'
          aria-label='Edit'
          fontSize='24px'
          icon={<MdEdit />}
          onClick={() => { enterEditMode(); }}
        />
      }
    </HStack>
  </Flex>);
}

function EditableName() {
  const [ api, surfaceKnownErrors ] = useApi();
  const profileId = useProfileId()!;
  const profileInfo = useProfileInfo()!;
  const handleSave = (newValue: string) => {
    return api.updateProfile({
      profileId,
      toUpdateProfileInfo: {
        name: newValue,
      },
    }, surfaceKnownErrors);
  };

  return (<EditableField
    label='Name'
    startingValue={profileInfo.name || ''}
    multiline={false}
    handleSave={handleSave}
  />);
}

function EditablePhone() {
  const [ api, surfaceKnownErrors ] = useApi();
  const profileId = useProfileId()!;
  const profileInfo = useProfileInfo()!;
  const handleSave = (newValue: string) => {
    return api.updateProfile({
      profileId,
      toUpdateProfileInfo: {
        phone: newValue,
      },
    }, surfaceKnownErrors);
  };

  return (<EditableField
    label='Phone'
    startingValue={profileInfo.phone || ''}
    placeholder='Ex: (123) 456-7890'
    multiline={false}
    handleSave={handleSave}
  />);
}

function EditableAddress() {
  const [ api, surfaceKnownErrors ] = useApi();
  const profileId = useProfileId()!;
  const profileInfo = useProfileInfo()!;
  const handleSave = (newValue: string) => {
    return api.updateProfile({
      profileId,
      toUpdateProfileInfo: {
        address: newValue,
      },
    }, surfaceKnownErrors);
  };

  return (<EditableField
    label='Address'
    startingValue={profileInfo.address || ''}
    placeholder={<>
      <span>Example:</span><br />
      <span>123 Street</span><br />
      <span>City, State</span><br />
      <span>12345</span><br />
    </>}
    multiline={true}
    handleSave={handleSave}
  />);
}

function LinkableGoogle() {
  const user = useUser()!;
  const googleUserInfo = user.providerData.find((userInfo) => {
    return userInfo.providerId === GoogleAuthProvider.PROVIDER_ID;
  });

  return (
    <VStack flex='1 0 0' align='start'>
      <HStack align='center'>
        <Icon as={FaGoogle} aria-label='Google Logo' />
        <Text textStyle='body'>Google</Text>
        {!!googleUserInfo ? <>
            <Icon as={MdVerified} color='green.500' aria-label='linked' />
            <Text textStyle='body2'>
              (Connected to <em>{googleUserInfo?.email}</em>)
            </Text>
          </> :
          <Text textStyle='body2'>(Not connected)</Text>
        }
      </HStack>
      {!googleUserInfo && <GoogleAuthButton /> }
    </VStack>
  );
}

interface PlanDetailsProps {
  tier: ProfileTier;
}
function PlanDetails({ tier } : PlanDetailsProps) {
  const headerProps: BoxProps = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    mb: 1,
  };

  const inlineProps: TextProps = {
    as: 'span',
    display: 'inline',
    textStyle: 'bodySmall',
  };

  const bodyProps: BoxProps = {
    textStyle: 'body2',
  };

  switch (tier) {
    case ProfileTier.FREE:
      return (<>
        <CardHeader {...headerProps}>
          <Text textStyle='titleForGroup'>Free Trial</Text>
          <Text textStyle='displayPrice'>No Cost</Text>
        </CardHeader>
        <CardBody {...bodyProps}>
          Explore our Brand Kit offering & get 25 assets for free
        </CardBody>
      </>);
    case ProfileTier.PAY_AS_YOU_GO:
      return (<>
        <CardHeader {...headerProps}>
          <Text textStyle='titleForGroup'>Brand Lite</Text>
          <Text textStyle='displayPrice'>
            $250&nbsp;<Text {...inlineProps}>flat fee</Text>
          </Text>
        </CardHeader>
        <CardBody {...bodyProps}>
          Define the strategic fundamentals of your brand with our AI-powered agent
        </CardBody>
      </>);
    case ProfileTier.SUBSCRIPTION:
      return (<>
        <CardHeader {...headerProps}>
          <Text textStyle='titleForGroup'>Brand Turbo</Text>
          <Text textStyle='displayPrice'>
            $40&nbsp;<Text {...inlineProps}>monthly subscription</Text>
          </Text>
        </CardHeader>
        <CardBody {...bodyProps}>
          Brief language here that motivates the user to subscribe to this plan
        </CardBody>
      </>);
    default:
      logger.error(LogSource.FIRESTORE, `Unknown profile tier: ${tier}`);
      return <></>;
  }
}

function UpdateProfileOnRedirectResult() {
  const [ auth ] = useAuth();
  const [ credential, setCredential ] = useState<UserCredential|null>(null);
  const [api, surfaceKnownErrors] = useApi();
  const isUpdatingProfile = useApiPending(ApiMethods.UPDATE_PROFILE);
  const user = useUser(true);
  const profileId = useProfileId();
  const profileInfo = useProfileInfo();
  const canUpdateProfile = !isUpdatingProfile && !!user && !!profileId && !!profileInfo && !!credential; 
  const toast = useToast();

  const updateProfileFromCredential = useCallback(() => {
    if (!canUpdateProfile) { return; }
    const newInfo: ToUpdateProfileInfo = {};
    if (!!user.displayName && !profileInfo?.name) {
      newInfo.name = user.displayName;
    }
    if (!!user.photoURL && !profileInfo?.profileImageInfo?.imageUrl) {
      newInfo.profileImageUrl = user.photoURL;
    }
    if (!Object.keys(newInfo).length) { return; }
    api.updateProfile({ 
      profileId, toUpdateProfileInfo: newInfo,
    }, surfaceKnownErrors).then(() => {
      toast({
        status: 'info',
        description: 'Updated profile!',
      });
    });
  }, [api, surfaceKnownErrors, canUpdateProfile, user, profileInfo, profileId, toast]);

  useEffect(() => {
    getRedirectResult(auth).then((cred: UserCredential|null) => {
      setCredential(cred);
    });
  }, [auth]);
  useEffectLate(updateProfileFromCredential);

  return <></>;
}
