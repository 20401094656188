import React from 'react';

import {
  BoxProps,
  Text
} from '@chakra-ui/react';

import { FontAssetDocument } from 'src/db';

interface FontSampleProps extends BoxProps {
  font: FontAssetDocument
  size?: number|string;
}
export function FontSample({
  font, size = '12px', children, ...boxProps
}: FontSampleProps) {
  const fontFamily = font.value.font.name;
  const url = font.value.font.url;
  const weight = font.value.font.weight;

  return (<>
    <style>{`
      @font-face {
        font-family: ${font.id};
        src: url(${url});
      }
    `}</style>
    <Text fontSize={size} {...boxProps}
      fontFamily={`${font.id}`}
      sx={{
        fontWeight: weight, 
        textWrap: 'wrap',
      }}
    >{children || fontFamily}</Text>
  </>);
}
