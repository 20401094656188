import { useContext } from 'react';
import { Auth, AuthError } from 'firebase/auth';
import { useToast, UseToastOptions } from '@chakra-ui/react';

import { logger, LogSource } from 'src/util/logger';
import { AuthContext, AuthOrNull, UserContext, UserOrNull } from './contexts';

export type AuthErrorHandler = (e: AuthError) => void;

export function useNullableAuth() : AuthOrNull {
  return useContext(AuthContext);
}

export function useAuth() : [Auth, AuthErrorHandler] {
  const auth = useNullableAuth();
  if (!auth) {
    throw new Error('useAuth called before Auth is available');
  }
  const errorHandler = useAuthErrorHandler();
  return [auth!, errorHandler];
}

export function useUser(allowAnonymous: boolean = false) : UserOrNull {
  const user = useContext(UserContext);
  if (!user) { return null; }
  if (!allowAnonymous && user.isAnonymous) { return null; }
  return user;
}

// Return true if there is no user, OR the user is anonymous.
export function useIsUserAnonymous() : boolean {
  const user = useUser(false);
  return !user;
}

// Return true if there is a user, AND the user is not anonymous.
export function useIsUserNonAnonymous() : boolean {
  const user = useUser(false);
  return !!user;
}

// MUST match firebase spec exactly.
const EMAIL_EXISTS = 'auth/email-already-in-use';
const WEAK_PASSWORD = 'auth/weak-password';
const INVALID_EMAIL = 'auth/invalid-email';
const INVALID_PASSWORD = 'auth/wrong-password';

function useAuthErrorHandler() : AuthErrorHandler {
  const toast = useToast();
  const errorProps: UseToastOptions = {
    status: 'error',
    isClosable: true,
    duration: null,
  };
  return (e: AuthError) => {
    if (e.code === WEAK_PASSWORD) {
      toast({
        ...errorProps,
        description: 'Weak password: Make it at least 6 characters',
      });
    } else if (e.code === EMAIL_EXISTS) {
      toast({
        ...errorProps,
        description: 'Email already in use: Try logging in',
      });
    } else if (e.code === INVALID_PASSWORD) {
      toast({
        ...errorProps,
        description: 'Invalid credentials. Try again.',
      });
    } else if (e.code === INVALID_EMAIL) {
      toast({
        ...errorProps,
        description: 'Invalid email. Try again.',
      });
    } else {
      toast({
        ...errorProps,
        title: `Uknown Auth Error: ${e.code}`,
        description: `${e.message}`,
      });
      logger.severe(LogSource.AUTH, 'Uknown Auth Error', e);
    }
  }
}
