import React, { PropsWithChildren } from "react";

import { AnalyticsContext, AnalyticsOrNull } from './contexts';
import { Analytics } from "firebase/analytics";

interface AnalyticsProviderProps extends PropsWithChildren {
  analytics: Analytics;
};

export function AnalyticsProvider({ analytics, children }: AnalyticsProviderProps) {   
    const loadedAnalytics: AnalyticsOrNull = analytics;
    return (
      <AnalyticsContext.Provider value={loadedAnalytics}>
          {children}
      </AnalyticsContext.Provider>
    );
}
