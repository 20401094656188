import React, { PropsWithChildren, SyntheticEvent, useCallback, useEffect, useMemo, useState, useRef} from 'react';
import { Box, BoxProps, HStack, Icon, IconButton, Flex } from '@chakra-ui/react';
import { IoMdArrowDroprightCircle, IoMdArrowDropleftCircle } from "react-icons/io";
import './Carousel.css';

const carrotSize = 24;

/**
 * Full Width Carousel widget
 * 
 * Usage: Add children elements as normal. Each top level child will be wrapped
 * in its own item container that will have the same width as the carousel element.
 * 
 * `alignCarouselItems` (optional) specifies the vertical alignment of the carousel items via
 * the CSS 'alignItems' property on containg element of the items. Default value is 'center'.
 * 
 * `duration` (optional) if set, specifies a duration (in ms) to pause on each item before 
 * automatically sliding to the next. Default value is `null` (no automatic sliding).
 * Regardless, the user can always scroll manually of navigate with the tabs/carats.
 * 
 * `itemWidth` (optional) specifies the width of the items (in px).
 * If ommited, they will be the full width of the carousel parent element (minus spacing).
 * 
 * `itemSpacing` (optional) the width of the gap (in px) between items. Default is 0.
 * The spacing left of the first item and right of the last item will be half of this.
 * 
 */
interface CarouselProps extends BoxProps {
  alignCarouselItems?: string;
  duration?: number|null;
  itemWidth?: number;
  itemSpacing?: number;
}
export function Carousel({
  alignCarouselItems = 'center',
  duration = null,
  itemWidth,
  itemSpacing = 0,
  children,  ...boxProps
}: PropsWithChildren<CarouselProps>) {
  const hostContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const [maxItemWidth, setMaxItemWidth]= useState<number>(itemWidth || 0);

  // Gaurantees React children are iterable.
  const childrenArray = useMemo(() => {
    if (Array.isArray(children)) {
      return children;
    } else {
      return [children];
    }
  }, [children]);

  // If element at the index is not in view,
  // scroll just enough right or left to ensure it is.
  const scrollTo = useCallback((idx: number) => {
    if (idx === selectedIndex) { return; }
    if (idx < selectedIndex) {
      // Left scroll: Ensure the right side is in view.
      const offsetLeft = idx * maxItemWidth;
      // Only scroll if the item is too far left.
      if (scrollContainerRef.current!.scrollLeft > offsetLeft) {
        scrollContainerRef.current!.scrollLeft = offsetLeft;
      } else {
        setSelectedIndex(idx);
      }
    } else {
      // Right scroll: Ensure the right side is in view.
      const offsetRight = (idx+1) * maxItemWidth;
      const hostWidth = hostContainerRef.current!.clientWidth;
      // Only scroll if the item is too far right.
      if (scrollContainerRef.current!.scrollLeft + hostWidth < offsetRight) {
        scrollContainerRef.current!.scrollLeft = offsetRight - hostWidth;
      } else {
        setSelectedIndex(idx);
      }
    }
    // Note: `setSelectedIndex` will already be called *if scrolling actually happens*
    // as part of `syncSelectedTab` in the scroll handler.
  }, [selectedIndex, maxItemWidth])

  const goToNext = useCallback((loop = false) => {  
    let nextIndex = selectedIndex + 1;
    if (nextIndex >= childrenArray.length) {
      if (!loop) { return; }
      nextIndex = 0;
    }
    scrollTo(nextIndex);
  }, [childrenArray, selectedIndex, scrollTo]);

  const goToPrev = useCallback((loop = false) => {
    let prevIndex = selectedIndex - 1;
    if (prevIndex < 0) {
      if (!loop) { return; }
      prevIndex = childrenArray.length -1;
    }
    scrollTo(prevIndex);
  }, [childrenArray, selectedIndex, scrollTo]);

  // Ensure the selected tab matches the scroll amount.
  // If the last scroll was to the right,
  //  selected the right most element fully in view.
  // If the last scroll was to the left,
  //  selected the left most element fully in view.
  const syncSelectedTab = useCallback(() => {
    const scrollContainer = scrollContainerRef.current!;
    const newScrollLeft = scrollContainer.scrollLeft;
    
    // Can happen when a scroll is attempted already at the end.
    if (newScrollLeft === lastScrollLeft) { return }

    // Right Scroll, set to the right most item in view.
    if (newScrollLeft > lastScrollLeft) {
      // Ensure the right side of the item is in view.
      // +1 to avoid floating point rounding errors
      const hostWidth = hostContainerRef.current!.clientWidth;
      const newIndex = Math.floor((newScrollLeft + hostWidth +1) / maxItemWidth);
      // Math.max to prevent stepping down if user changes direction mid-item
      setSelectedIndex(Math.max(newIndex-1, selectedIndex));

    // Right Scroll, set to the left most item in view.
    } else {
      // Ensure the left side of the item is in view.
      // +1 to avoid floating point rounding errors
      const newIndex = Math.ceil((newScrollLeft -1) / maxItemWidth);
      // Math.max to prevent stepping up if user changes direction mid-item
      setSelectedIndex(Math.min(newIndex, selectedIndex))
    }
    setLastScrollLeft(newScrollLeft);
  }, [lastScrollLeft, maxItemWidth, selectedIndex]);

  // Setup resize observer on host container.
  useEffect(() => {
    if (!hostContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const rect = hostContainerRef.current!.getBoundingClientRect();
      setMaxItemWidth((itemWidth || rect.width) + itemSpacing);
    });
    resizeObserver.observe(hostContainerRef.current);
    return () => { resizeObserver.disconnect(); }
  }, [itemWidth, itemSpacing]);

  // Ensure sync happens on resize (syncSelectedTab has `maxItemWidth` as a dep).
  useEffect(() => {
    syncSelectedTab();
  }, [syncSelectedTab]);

  // Setup automatic transition to the next slide after a duration.
  useEffect(() => {
    if (!duration) { return; }
    const intervalId = setInterval(() => {
      goToNext(true);
    }, duration);
    return () => {
      clearInterval(intervalId);
    }
  }, [duration, goToNext]);

  return (<Box {...boxProps} className='carousel-host' ref={hostContainerRef}>
    <HStack
      className='scroll-container'
      ref={scrollContainerRef}
      align={alignCarouselItems}
      py={2}
      px={`${itemSpacing/2}px`}
      spacing={`${itemSpacing}px`}
      scrollPadding={`0 ${itemSpacing/2}px`}
      onScroll={() => {syncSelectedTab()}}
    >
      {(childrenArray).map((child, idx) => {
        return <Box
          key={idx}
          className='panel'
          role='tabpanel'
          flexBasis={`${maxItemWidth - itemSpacing}px`}
        >{child}</Box>
      })}
      {(selectedIndex < childrenArray.length -1) && <IconButton
        aria-label='show next'
        icon={<Icon as={IoMdArrowDroprightCircle} boxSize={`${carrotSize}px`}/>}
        position='absolute'
        alignSelf='center'
        right={`-${carrotSize/2}px`}
        onClick={(e: SyntheticEvent) => { 
          e.stopPropagation();
          goToNext();
        }}
      />}
      {(selectedIndex > 0) && <IconButton
        aria-label='show previous'
        icon={<Icon as={IoMdArrowDropleftCircle} boxSize={`${carrotSize}px`}/>} 
        position='absolute'
        alignSelf='center'
        left={`-${carrotSize/2}px`}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          goToPrev();
        }}
      />}
    </HStack>
    {(childrenArray.length > 1) && <Flex role='tablist'>
      {(childrenArray).map((_child, idx) => {
        return (<Box
          role='tab'
          key={idx}
          bg={idx === selectedIndex ? 'primary.veryDark' : 'grayscale.medium'}
          height='6px'
          border='0.1px solid'
          borderColor='grayscale.dark'
          width={`${100/childrenArray.length}%`}
          aria-label={`Panel ${idx}`}
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();
            scrollTo(idx);
          }}
          sx={{ cursor: 'pointer' }}
        ></Box>);
      })}
    </Flex>}
  </Box>);
}
