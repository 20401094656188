import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"

type Size = 'md'|'sm'|'lg'|'xl';

// Note: Chakra UI doesn't appear to offer any type checking
// for custom color schemes or variants

// `colorScheme` is either 'primary' or 'secondary'. 'primary' is default.
// Currently no implementation for system ('error'|'warn'|'success') schemes.

// `variants` are one of 'fill'|'outline'|'ghost'|'link'.

function height(size: Size) {
  switch(size) {
    case 'sm':
      return '32px';
    case 'lg':
    case 'xl':
      // No current use case for lg/xl button components.
      return 'fit-content'; 
    case 'md': 
    default:
      return '46px'
  }
}

function textStyle(size: Size) {
  switch(size) {
    case 'sm':
      return 'buttonSmall';
    case 'lg':
      return 'buttonLarge';
    case 'xl':
      return 'buttonXLage';
    case 'md': 
    default:
      return 'button';
  }
}

const sizes = {
  sm: defineStyle({
    height: height('sm'),
    textStyle: textStyle('sm'),
  }),
  md: defineStyle({
    height: height('md'),
    textStyle: textStyle('md'),
  }),
  lg: defineStyle({
    height: height('lg'),
    textStyle: textStyle('lg'),
  }),
}

const fillVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: `${c}.dark`,
    color: 'grayscale.white',
    borderRadius: 'full',
    px: '16px',
    transition: 'background 0.15s ease-out',
    _hover: {
      bg: `${c}.darkHover`,
    },

    _active: {
      bg: `${c}.darkActive`,
    },
  }
});

const outlineVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: 'grayscale.white',
    color: `${c}.dark`,
    borderColor: `${c}.dark`,
    borderRadius: 'full',
    borderStyle: 'solid',
    borderWidth: '1px',
    px: '16px',
    transition: 'background 0.15s ease-out',
    _hover: {
      bg: `${c}.lightHover`,
    },
    _active: {
      bg: `${c}.lightActive`,
    },
  }
});

const outlineFilledVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: `${c}.veryLight`,
    color: `${c}.dark`,
    borderColor: `${c}.dark`,
    borderRadius: 'full',
    borderStyle: 'solid',
    borderWidth: '1px',
    px: '16px',
    transition: 'background 0.15s ease-out',
    _hover: {
      bg: `${c}.lightHover`,
    },
    _active: {
      bg: `${c}.lightActive`,
    },
  }
});

const ghostVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: 'transparent',
    color: `${c}.dark`,
    transition: 'background 0.15s ease-out',
    _hover: {
      bg: `${c}.lightHover`,
    },
    _active: {
      bg: `${c}.lightActive`,
    },
  }
});

const linkVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: 'transparent',
    color: `${c}.dark`,
    transition: 'color 0.15s ease-out',
    textDecoration: 'underline',
    _hover: {
      color: `${c}.darkHover`,  
    },
    _active: {
      color: `${c}.darkActive`,
    },
  }
});

const baseStyle = defineStyle({
  px: '8px',
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  _loading: {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
});

export const Button = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    fill: fillVariant,
    ghost: ghostVariant,
    outline: outlineVariant,
    outlineFilled: outlineFilledVariant,
    link: linkVariant,
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
    variant: 'ghost',
  },
});
