import React from 'react';

import { AssetTypes } from 'src/db';
import { ValueAssetValue } from 'src/db/model/assets/valueAsset';
import { UpdateTextAssetButton, UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';

const TypedUpdateTextAssetButton = UpdateTextAssetButton;

const getValue = ({brandValue}: ValueAssetValue): string => {
  return brandValue;
}

const parseText = (text: string): ValueAssetValue => {
  return {brandValue: text};
};

export function UpdateValueAssetButton({asset, children, ...btnProps} : UpdateTextAssetButtonProps) {
  return (
    <TypedUpdateTextAssetButton
      assetId={asset.id}
      assetType={AssetTypes.value}
      assetUiName='Value'
      assetValue={getValue(asset.value as ValueAssetValue)}
      parseText={parseText}
      {...btnProps}
    />
  );
}

