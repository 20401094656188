import React, { useCallback, useState } from 'react';
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdLockOutline, MdOutlineRocketLaunch } from 'react-icons/md';

import { useApi } from 'src/api';
import { useBusinessId } from 'src/db';
import { Path, SearchParam, useNavigateWithParams, useSearchParamState } from 'src/nav';

export function LockBrandKitButton({ ...buttonProps }: ButtonProps) {
  const [api, surfaceKnownErrors] = useApi();
  const businessId = useBusinessId()!;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLocking, setIsLocking] = useState<boolean>(false);
  const navigate = useNavigateWithParams();

  const lockBrandKit = () => {
    setIsLocking(true);
    api.lockBrandKit({ businessId }, surfaceKnownErrors).finally(() => {
      setIsLocking(false);
      navigate({ to: Path.home});
      onClose();
    })
  };

  return (<>
    <Button
      variant='fill'
      colorScheme='primary'
      {...buttonProps}
      onClick={() => { onOpen() }}
    >Click to Finalize Brand Kit</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true} 
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Icon
              aria-label='Lock Brand Kit'
              as={MdLockOutline}
              color='secondary.dark'
              boxSize='64px'
            />
          </Center>
        </ModalHeader>
        <ModalBody>
          <Text align='center' textStyle='bodyLarge' mb={4}>
            Your brand must be locked to download your brand assets.
          </Text>
          <Text align='center' textStyle='body2'>
            Locking your brand allows us to make tailored, strategic recommendations 
            for your business. You can still change your brand as often as you like.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex width='100%' direction='row' align='center' wrap='wrap'>
            <Button
              variant='outline'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isDisabled={isLocking}
              onClick={onClose}
            >No, I'm Not Ready</Button>
            <Button
              variant='fill'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isLoading={isLocking}
              loadingText='Locking...'
              onClick={() => {lockBrandKit();}}
            >Yes, Lock Brand</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
}

export function CongratulationsModal() {
  const [api, surfaceKnownErrors] = useApi();
  const businessId = useBusinessId()!;
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const [isLocking, setIsLocking] = useState<boolean>(false);
  const navigate = useNavigateWithParams();
  const [, setCongratulations] = useSearchParamState(SearchParam.congratulations);
  const cleanup = useCallback(() => {
    onClose();
    setCongratulations(null);
  }, [onClose, setCongratulations]);
  const lockBrandKit = () => {
    setIsLocking(true);
    api.lockBrandKit({ businessId }, surfaceKnownErrors).finally(() => {
      setIsLocking(false);
      navigate({ to: Path.home});
      cleanup();
    })
  };

  return (<>
    <Modal
      isOpen={isOpen}
      onClose={cleanup}
      isCentered={true} 
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Icon
              aria-label='Lock Brand Kit'
              as={MdOutlineRocketLaunch}
              color='secondary.dark'
              boxSize='64px'
            />
          </Center>
        </ModalHeader>
        <ModalBody>
          <Text align='center' textStyle='bodyLarge' mb={4}>
            Congratulations! Your AI Brand Kit is complete!
          </Text>
          <Text align='center' textStyle='body2'>
            Click to finalize your brand identity and unlock daily actions to grow your brand.
            You can update your brand later by unlocking it.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex width='100%' direction='row' align='center' wrap='wrap'>
            <Button
              variant='outline'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isDisabled={isLocking}
              onClick={cleanup}
            >Finalize later</Button>
            <Button
              variant='fill'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isLoading={isLocking}
              loadingText='Locking...'
              onClick={() => {lockBrandKit();}}
            >Finalize</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
}
