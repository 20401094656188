import {
  Card,
  CardBody,
  VStack,
  Center,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  ButtonProps,
  useDisclosure,
} from '@chakra-ui/react';

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "./wrappers/RequireBusiness";
import { Path, useNavigateWithParams } from "src/nav";
import { ReportPreview } from 'src/components/ReportPreview';
import { useBusinessId, useReportCollection } from 'src/db/hooks';
import { ReportDocument, ReportType } from 'src/db/model/report';
import { MdOutlineCreate } from 'react-icons/md';
import { useState } from 'react';
import { useApi } from 'src/api';

export function BrandReportsPage() {
  return (
    <HeaderFooterPage
      pageTitle="Brand Reports"
      backTo={Path.home}
      backLabel="Brand Hub"
    >
      <RequireBusinessWithSignup>
        <BrandReportsPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function BrandReportsPageContent() {
  const reports: ReportDocument[] = useReportCollection();
  return (
    <VStack align="stretch" position='relative' spacing={2}>
      <ReportListByType reportType={ReportType.MARKET_RESEARCH_ANALYSIS} reports={reports} title='Market Research Analysis'></ReportListByType>
      <ReportListByType reportType={ReportType.BUSINESS_PLAN} reports={reports} title='Business Plan'></ReportListByType>
      <ReportListByType reportType={ReportType.SWOT_ANALYSIS} reports={reports} title='SWOT Analysis'></ReportListByType>
    </VStack>
  );
}

interface GenerateReportByTypeButtonProps extends ButtonProps {
  reportType: ReportType;
}

interface ReportByTypeProps extends ButtonProps {
  reports: ReportDocument[];
  reportType: ReportType;
  title: string;
}

export function ReportListByType({ reports, reportType, title }: ReportByTypeProps) {
  const reportsOfType = reports.filter(report => report.report_type === reportType);
  return (<>
    <Card mb={8} p={6}>
      <Text as='h2' textStyle='titleForSection' mb={4}>
        <Center>{title}</Center>
      </Text>
      <CardBody>
        {!!reportsOfType.length &&
          (
            <VStack align="center">
              {reportsOfType.map((report, idx) => (
                <ReportPreview
                  key={report.id}
                  report={report}
                  isOpen={idx === 0}
                  mb={6}
                  border={"0.1px solid"}
                  rounded="lg"
                  padding={"2"}
                  margin={"2"}
                />
              ))}
            </VStack>
          )
        }
        <Center><GenerateReportByTypeButton reportType={reportType}>Generate</GenerateReportByTypeButton></Center>

      </CardBody>
    </Card></>)
}
export function GenerateReportByTypeButton({
  reportType,
  children,
  ...buttonProps
}: GenerateReportByTypeButtonProps) {
  const [api, surfaceKnownErrors] = useApi();
  const businessId = useBusinessId()!;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const navigate = useNavigateWithParams();

  const generateReports = () => {
    setIsGenerating(true);
    api.generateReports({
      businessId,
      reportType,
    }, surfaceKnownErrors).finally(() => {
      setIsGenerating(false);
      navigate({ to: Path.home });
      onClose();
    })
  };

  return (<>
    <Button
      colorScheme='primary'
      variant='fill'
      minWidth='200px'
      mx='auto'
      {...buttonProps}
      onClick={() => { onOpen() }}
    >
      {children}
    </Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Icon
              aria-label='Generate Report'
              as={MdOutlineCreate}
              color='secondary.dark'
              boxSize='64px'
            />
          </Center>
        </ModalHeader>
        <ModalBody>
          <Text align="center" textStyle="body2">
            A new report will be generated to help you enhance your brand.
            You will be redirected to the home page while reports are generated in the background (may take a few minutes).
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex width='100%' direction='row' align='center' wrap='wrap'>
            <Button
              variant='outline'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isDisabled={isGenerating}
              onClick={onClose}
            >Not for now</Button>
            <Button
              variant='fill'
              colorScheme='primary'
              size='sm'
              m={2}
              flex='1 0 0'
              isLoading={isGenerating}
              loadingText='Generating...'
              onClick={() => { generateReports(); }}
            >Yes, Generate Report</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
}