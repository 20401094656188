import React, { useCallback, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

import { SearchParam, useSearchParamState} from 'src/nav';

const SUCCESS = 'success';
const CANCELED = 'canceled';

export function HandleCheckoutResponse() {
  const toast = useToast();
  const [checkoutResponse, setCheckoutResponse] = useSearchParamState(SearchParam.checkoutResponse);
  const removeCheckoutResponse = useCallback(() => {
    setCheckoutResponse(null);
  }, [setCheckoutResponse]);

  useEffect(() => {
    if (!checkoutResponse) { return }
    if (checkoutResponse === SUCCESS) {
      toast({
        title: 'Payment success',
        status: 'success',
        onCloseComplete: removeCheckoutResponse,
      });
    } else if (checkoutResponse === CANCELED) {
      toast({
        title: 'Payment canceled',
        status: 'warning',
        onCloseComplete: removeCheckoutResponse,
      });
    } else {
      toast({
        title: 'Payment Error',
        description: 'Unknown `checkoutResponse`',
        status: 'error',
        onCloseComplete: removeCheckoutResponse,
      });
    }   
  }, [toast, checkoutResponse, removeCheckoutResponse]);

  return (<></>);
}
