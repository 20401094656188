import { FirebaseApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';

/**
 * Use synchronously after initializing the firebase app.
 * 
 * (Therefore do not call inside of React)
 */
export function setupAnalyticsForEnv(app: FirebaseApp): Analytics {
  const analytics = getAnalytics(app);
  return analytics;
}
