import { ActionDocument, ActionDocumentConverter } from '../action';

export interface ExploreEducationActionData {
  title: string
  image: string
  description: string
  url: string
}

export type ExploreEducationActionDocument = ActionDocument<ExploreEducationActionData>;

class ExploreEducationActionDocumentConverter extends ActionDocumentConverter<ExploreEducationActionData> {
  override actionDataToFirestore(actionData: any) : any {
    return {
      exploreEducation: {
        title: actionData.tile,
        image: actionData.image,
        description: actionData.description,
        url: actionData.url,
      },
    };
  }

  override actionDataFromFirestore(brandActionData: any) : ExploreEducationActionData {
    return {
      title: brandActionData.exploreEducation!.title,
      image: brandActionData.exploreEducation!.image,
      description: brandActionData.exploreEducation.description,
      url: brandActionData.exploreEducation!.url,
    };
  }
}

export const exploreEducationActionConverter = new ExploreEducationActionDocumentConverter();
