import React, { useState } from 'react';
import {
  Button,
  ButtonProps,
} from '@chakra-ui/react';

import { logger, LogSource } from 'src/util/logger';

/**
 * This component is a workaround for the `download` attribute not being respected
 * by certain browsers. It fetches the image to create an ObjectUrl from the blob instead. 
 */
interface DownloadImageButtonProps extends ButtonProps {
  imageUrl: string;
  fileName?: string;
  // Function to run after download finishes.
  onComplete?: () => void;
}
export function DownloadImageButton({
  imageUrl, fileName, onComplete,
  children, ...buttonProps
}: DownloadImageButtonProps) {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const downloadImageFromUrl = () => {
    setIsFetching(true);
    const extenstion = (imageUrl.match(/\.[0-9a-z]+$/i) || ['.png'])[0];
    fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
    }).then((response) => {
      return response.blob();
    }).then((blob) => {
      const urlCreator = window.URL || window.webkitURL;
      const blobUrl = urlCreator.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `${fileName || 'media'}${extenstion}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((e) => {
      logger.error(LogSource.APP, 'Failed to create Download Link', e);
    }).finally(() => {
      setIsFetching(false);
      if (!!onComplete) {
        onComplete();
      }
    });
  }

  return (<Button
    variant='fill'
    colorScheme='primary'
    {...buttonProps}
    isLoading={isFetching}
    onClick={() => {
      downloadImageFromUrl();
    }}
  >
    {children || 'Download'}
  </Button>);
}
