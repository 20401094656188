import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Divider,
  Progress,
  Center,
  Icon,
  HStack,
} from '@chakra-ui/react';

import Zoom from 'react-medium-image-zoom'
import { AssetProps } from './DetailPage';

import { ApiMethods, useApi, useApiPending } from 'src/api';
import { UpdateEditedLogoRequest } from 'src/api/messages/UpdateEditedLogo';
import { AssetCard, AssetExplanation } from 'src/components';
import { Logo } from 'src/components/assets';
import { useSelectAsset, useUnselectAsset } from 'src/components/assets/actions';
import { AddLogoAssetButton } from 'src/components/assets/addAsset';
import { LogoSerializer } from 'src/components/assets/Logo';
import {
  AssetTypes, UserReviews,
  LogoAssetDocument,
  useBusinessId, useAssetContext
} from 'src/db';
import { Path, useNavigateWithParams } from 'src/nav';

import { HowItWorksButton } from './logo/HowItWorks';
import { ImageStack } from './logo/ImageStack';
import { Thumbs } from './logo/Thumbs';
import { VerticalLogoList } from './logo/VerticalLogoList';

import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { RequireBusinessWithSignup } from './wrappers/RequireBusiness'; 

import { AddSimilarLogoAsset } from 'src/components/assets/addAsset/AddSimilarLogoAsset';
import { IoArrowBackSharp, IoArrowForwardSharp } from 'react-icons/io5';

const SIDE_PADDING = 4;

export function LogoPage() {
  return (
    <HeaderFooterPage
      pageTitle='Logo'
      backTo={Path.brandkit}
      backLabel='Brand Kit'
      topRightContent={<HowItWorksButton />}
    >
      <RequireBusinessWithSignup>
        <LogoPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function LogoPageContent() {
  const [api, surfaceKnownErrors] = useApi();
  const selectAsset = useSelectAsset();
  const unselectAsset = useUnselectAsset();
  const isGeneratingAny = useApiPending(ApiMethods.GENERATE_ASSETS);
  const businessId = useBusinessId()!;
  const [isPositiveReviewPending, setIsPositiveReviewPending] = useState<boolean>(false);
  const [isNegativeReviewPending, setIsNegativeReviewPending] = useState<boolean>(false);
  const [pendingSelections, setPendingSelections] = useState<Set<string>>(new Set<string>());
  const serializerRef = useRef<LogoSerializer>(null);
  const navigate = useNavigateWithParams();
  const previousPage = Path.font;
  const nextPage = Path.name;


  const addPendingSelection = useCallback((idToAdd: string) => {
    if (pendingSelections.has(idToAdd)) { return; }
    pendingSelections.add(idToAdd);
    setPendingSelections(new Set(pendingSelections));
  }, [pendingSelections]);

  const removePendingSelection = useCallback((idToRemove: string) => {
    if (!pendingSelections.has(idToRemove)) { return; }
    pendingSelections.delete(idToRemove);
    setPendingSelections(new Set(pendingSelections));
  }, [pendingSelections]);

  const {
    collection: logoAssets,
    isLoading: logoAssetsAreLoading,
    error: collectionError,
  } = useAssetContext<LogoAssetDocument>(AssetTypes.logo);
  const [logoAssetsAreGenerating, setLogoAssetsAreGenerating] = useState<boolean>(false);

  let selectedLogo: LogoAssetDocument|null = null;
  const unselectedLogos = (logoAssets || []).filter((logo: LogoAssetDocument) => {
    if (logo.selected) {
      // Assuming there will at most be one.
      selectedLogo = logo;
      return false;
    }
    return true;
  }).sort((a: any, b: any) => {
    return b.createdAt - a.createdAt;
  });

  const newLogos = unselectedLogos.filter((logo) => {
    return logo.userReview === 'INITIAL';
  }).sort((a: any, b: any) => {
    return b.createdAt - a.createdAt;
  });

  const likedLogos = unselectedLogos.filter((logo) => {
    return logo.origin !== 'USER' && logo.userReview === 'POSITIVE';
  });

  const dislikedLogos = unselectedLogos.filter((logo) => {
    return logo.origin !== 'USER' && logo.userReview === 'NEGATIVE';
  });

  const updateAfterSelected = useCallback(async (newSelected: LogoAssetDocument) => {
    if (!serializerRef.current) { return ;}
    await api.updateEditedLogo(
      createUpdateEdittedLogoRequest(newSelected, serializerRef.current()),
      surfaceKnownErrors,
    ).then(() => {
      navigate({ to: nextPage });
    });
  }, [api, surfaceKnownErrors, navigate, nextPage]);

  const uploadedLogos = unselectedLogos.filter((logo) => {
    return logo.origin === 'USER';
  });

  const makeReviewRequest = (review: UserReviews) => {
    return {
      businessId,
      assetType: AssetTypes.logo,
      assetId: newLogos[0].id,
      review: review
    };
  }

  const reviewNegative = () => {
    const request = makeReviewRequest('NEGATIVE');
    setIsNegativeReviewPending(true);
    api.reviewAsset(request, surfaceKnownErrors).then(() => {
      setIsNegativeReviewPending(false);
    }, surfaceKnownErrors);
  };

  const reviewPositive = () => {
    const request = makeReviewRequest('POSITIVE');
    setIsPositiveReviewPending(true);
    api.reviewAsset(request, surfaceKnownErrors).then(() => {
      setIsPositiveReviewPending(false);
    }, surfaceKnownErrors);
  };

  const onLogoSelection = useCallback((newLogo: LogoAssetDocument) => {
    // Replace Currently Selected Asset.
    if (!!selectedLogo) {
      const oldLogoId = selectedLogo.id;
      if (pendingSelections.has(newLogo.id) || pendingSelections.has(oldLogoId)) { return; }
      unselectAsset(selectedLogo, (isPending: boolean) => {
        if (isPending) {
          addPendingSelection(newLogo.id);
          addPendingSelection(oldLogoId);
        } else {
          removePendingSelection(oldLogoId);
        }
      }).then(() => {
        selectAsset(newLogo, (isPending: boolean) => {
          if (!isPending) {
            removePendingSelection(newLogo.id);
          }
        }).then(() => {
          updateAfterSelected(newLogo);
        });
      });

    // Select Asset Only
    } else {
      if (pendingSelections.has(newLogo.id)) { return; }
      selectAsset(newLogo, (isPending: boolean) => {
        if (isPending) {
          addPendingSelection(newLogo.id);
        } else {
          removePendingSelection(newLogo.id);
        }
      }).then(() => {
        updateAfterSelected(newLogo);
      });
    }
  }, [
    selectedLogo, selectAsset, unselectAsset, updateAfterSelected,
    pendingSelections, addPendingSelection, removePendingSelection,
  ]);

  function SelectedLogo({asset}: AssetProps<LogoAssetDocument>) {
    return <AssetCard<LogoAssetDocument>
        value={asset}
        isInProgress={pendingSelections.has(asset.id)}
        includeSelection={true}
        includeExplanation={asset.origin !== 'USER'}
        p={4}
      >
        <Center>
          <Zoom>
            <Logo
              logo={asset} isDynamic={asset.origin === 'AI'} alt={`Selected Logo`}
              htmlSerializerRef={serializerRef}
            />
          </Zoom>
        </Center>
    </AssetCard>
  }

  const generateLogos = () => {
    if (logoAssetsAreGenerating) {return ;}
    setLogoAssetsAreGenerating(true);
    api.generateAssets({
      businessId,
      assetType: AssetTypes.logo,
      numberToGenerate: 3,
    }, surfaceKnownErrors).finally(() => {
      setLogoAssetsAreGenerating(false);
    });
  }

  if (collectionError) {
    return <em>Could Not Find Assets</em>;
  } 

  // TODO: Preload the images too, before hiding the progress bar
  if (logoAssetsAreLoading || logoAssetsAreGenerating) {
    return (<>
      <Box py={8} px={SIDE_PADDING}>
        <Progress size='lg' isIndeterminate={true} />
      </Box>
    </>);
  }

  const BackButton = () => {
    return <Button
      colorScheme='primary'
      variant='fill'
      onClick={() => navigate({ to: previousPage })}
    >
      <Icon as={IoArrowBackSharp} aria-hidden={true} />&nbsp;<span>Back</span>
    </Button>;
  }
  const NextButton = () => {
    return <Button
      colorScheme='primary'
      variant='fill'
      onClick={() => navigate({ to: nextPage })}
    >
      <span>Next</span>&nbsp;<Icon as={IoArrowForwardSharp} aria-hidden={true} />
    </Button>;
  }

  const ReviewSection = () => {
    return (<>
      <Box px={SIDE_PADDING}>
        <ImageStack
          logos={newLogos}
          canSwipe={!isNegativeReviewPending && !isPositiveReviewPending}
          onSwipeLeft={reviewNegative}
          onSwipeRight={reviewPositive}
          m={4}
        />
        {newLogos.length === 0 ? null :
          <AssetExplanation asset={newLogos[0]} mb={4} />
        }

        <Thumbs
          logo={newLogos[0]}
          onThumbsDown={reviewNegative}
          onThumbsUp={reviewPositive}
          thumbsDownPending={isNegativeReviewPending}
          thumbsUpPending={isPositiveReviewPending}
          m={8}
        />
      </Box>
    </>)
  }

  return (<>
    <Center mb={8}>
      <HStack
        spacing={2}
      >
        <BackButton />
        <NextButton />
      </HStack>
    </Center>
    {!!selectedLogo && <SelectedLogo asset={selectedLogo} idx={0}/>}

    {<Center>
      <AddLogoAssetButton
        variant='ghost'
        colorScheme='primary'
      >+&nbsp;Add Your Own</AddLogoAssetButton>
      {!!newLogos.length && <AddSimilarLogoAsset
        variant='ghost'
        colorScheme='primary'
        logoAsset={newLogos[0]}
      >+&nbsp;Generate Similar</AddSimilarLogoAsset>}
    </Center>}

    {!!newLogos.length ? <ReviewSection /> :
      <Flex
        align='center'
        direction='column'
        justify='start'
        px={SIDE_PADDING}
        mb={4}
      >
        <Text mb={2}>
          Out of Generated Logos
        </Text>
        <Button
          colorScheme='secondary'
          variant='fill'
          isDisabled={isGeneratingAny}
          onClick={generateLogos}
        >Suggest More</Button>
      </Flex>
    }

    <Flex
      align='center'
      direction='column'
      justify='start'
      px={SIDE_PADDING}
      mb={4}
    >
      <Divider borderColor='secondary.light' mb={8} />
      <Text textStyle='titleForSection'>Your Logos</Text>
    </Flex>
    <Tabs
      defaultIndex={1}
      variant='unstyled'
      align='center'
      isFitted={true}
    >
      <TabList>
        <StyledTab>{`Disliked (${dislikedLogos.length})`}</StyledTab>
        <StyledTab>{`Liked (${likedLogos.length})`}</StyledTab>
        <StyledTab>{`Uploaded (${uploadedLogos.length})`}</StyledTab>
      </TabList>
      <TabPanels>
        <TabPanel p={2}>
          <VerticalLogoList
            logos={dislikedLogos}
            onLogoSelect={onLogoSelection}
            pendingSelections={pendingSelections}
          />
        </TabPanel>
        <TabPanel p={2}>
          <VerticalLogoList
            logos={likedLogos}
            onLogoSelect={onLogoSelection}
            pendingSelections={pendingSelections}
          />
        </TabPanel>
        <TabPanel p={2}>
          <VerticalLogoList
            logos={uploadedLogos}
            onLogoSelect={onLogoSelection}
            pendingSelections={pendingSelections}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
    <NextButton />
  </>);
}

const unSelectedTabStyle = {
  borderBottom: '1px solid',
  borderColor: 'grayscale.medium',
};

const selectedTabStyle = {
  bg: 'primary.veryLight',
  fontWeight: 'bold',
  borderBottom: '2px solid',
  borderColor: 'primary.veryDark',
};

function StyledTab({ children }: PropsWithChildren) {
  return (<Tab
    sx={unSelectedTabStyle}
    _selected={selectedTabStyle}
  >
    <Text textStyle='buttonSmall' sx={{fontWeight: 'inherit'}}>{children}</Text>
  </Tab>);
}

const cssReset = `
  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

function createUpdateEdittedLogoRequest (asset: LogoAssetDocument, serialized: string) : UpdateEditedLogoRequest {
  return {
    businessId: asset.businessId,
    assetId: asset.id,
    editedLogoInfo: {
      html: serialized,
      css: cssReset,
      googleFonts: '',
    }
  };
}
