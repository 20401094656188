import React, { PropsWithChildren, useContext } from "react";

import { AuthContext } from 'src/auth';

import { LargeProgress } from './LargeProgress';

/**
 * Ensures children will have access to a loaded non-null AuthContext; 
 */
export function AwaitUser({children} : PropsWithChildren) {
  const auth = useContext(AuthContext);

  // If no auth is non-null here, then user has had time to load.
  if (!auth) {
    return <LargeProgress />
  } else {
    return <>{children}</>;
  }  
}
