import React, { useContext, useMemo } from 'react';

import {
  Flex,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react'

import  {avatar } from 'src/images';

import { Bubble } from './Bubble';
import { Message, Source } from './Message';
import { ConversationContext } from './context';

function AiName() {
  return (<Flex
    direction='row'
    justify='start'
    align='end'
    position='relative'
  >
    <Image
      src={avatar}
      alt='Agent Brandi'
      boxSize={10}
      mb={-2}
    />
    <Text textStyle='avatar'>Agent Brandi</Text>
  </Flex>)
}

// Used to distinish backend messages from UI messages.
const hasId = (obj: any) => {
  return !!obj.id && (typeof obj.id === 'string');
}

const getId = (obj: any): string => {
  return obj.id! as string;
}

const addSpecialUiMessages = (messages: Message[], pending: string[], isAiThinking: boolean): Message[] => {
  // Add pending messages, or AI is thinking, but not both
  if (pending.length > 0) {
    return [
      ...messages,
      ...pending.map<Message>((body) => {
        return {source: 'human', body, specialUiType: 'userPending'};
      }),
    ];
  } else if (isAiThinking) {
    return [
      ...messages,
      {source: 'ai', body: '', specialUiType: 'aiThinking'}
    ];
  } else {
    return messages;
  }
};

export function MessageList() {
  const {
    messages,
    pending,
    isAiThinking,
    isModalOpen,
  } = useContext(ConversationContext);
  const memoElements = useMemo(() => {
    const elements: JSX.Element[] = [];
    let lastSource: Source = 'human';
    addSpecialUiMessages(messages, pending, isAiThinking).forEach((message, i) => {
      if (message.source === 'ai' && lastSource !== 'ai') {
        elements.push(<AiName key={`name-${i}`}/>);
      }
      lastSource = message.source;
      const key = hasId(message) ?
        `message-${getId(message)}` :
        `uiMessage-${i}`;

      if (message.specialUiType === 'aiSuggestion') {
        if (isModalOpen && i === messages.length-1) {
          elements.push(
            <Flex key={key}>
              <Bubble mb={2} message={message}/>
            </Flex>
          );
        }
      } else {
        elements.push(
          <Flex key={key} px={4}>
            {message.source === 'human' ? <Spacer minWidth={8}/> : null}
            <Bubble mb={2} message={message}/>
            {message.source === 'ai' ? <Spacer minWidth={8}/> : null}
          </Flex>
        );
      }
      
    });
    return elements;
  }, [messages, pending, isAiThinking, isModalOpen]);
  return (<>{memoElements}</>);
};
