import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  ActionCodeInfo,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
} from 'firebase/auth';

import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputProps,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { useAuth, useUser } from 'src/auth';
import { Path, SearchParam, useNavigateWithParams, withOutSearchParams } from 'src/nav';
import { logger, LogSource } from 'src/util/logger';

import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { AwaitUser } from './wrappers/AwaitUser';

// The actionCode param passed into the URL.
// MUST match firebase spec.
enum ActionMode {
  RESET = 'resetPassword',
  VERIFY = 'verifyEmail',
}

// The actionCode on the ActionInfo object
// MUST match firebase space
enum ActionOperation {
  RESET = 'PASSWORD_RESET',
  VERIFY = 'VERIFY_EMAIL',
}

const removeActionParms = withOutSearchParams([
  SearchParam.handleActionMode, SearchParam.handleActionCode, SearchParam.handleActionContinueUrl
]);


const modeMatchesOperation = (mode: ActionMode, operation: ActionOperation) : boolean => {
  if (mode === ActionMode.RESET && operation === ActionOperation.RESET) {
    return true;
  } else if (mode === ActionMode.VERIFY && operation === ActionOperation.VERIFY) {
    return true;
  } else {
    return false;
  }
}

const titleForMode = (mode: ActionMode): string => {
  switch (mode) {
    case ActionMode.RESET: 
      return 'Reset Password';
    case ActionMode.VERIFY:
      return 'Verify Email'
    default:
      throw Error(`Unsupported Action: ${mode}`);
  }
}

export function HandleActionPage() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get(SearchParam.handleActionMode)! as ActionMode;
  const oobCode = searchParams.get(SearchParam.handleActionCode)!;
  const continueUrl = searchParams.get(SearchParam.handleActionContinueUrl) || Path.home;

  if (!mode) {
    logger.error(LogSource.AUTH, `Missing SearchParam: ${SearchParam.handleActionMode}`);
  }

  return (<HeaderFooterPage pageTitle={titleForMode(mode)} pb={4}>
    <AwaitUser>
      <HandleActionContent
        mode={mode}
        oobCode={oobCode}
        continueUrl={continueUrl}
      />
    </AwaitUser>
  </HeaderFooterPage>);
}

interface HandleActionContentProps {
  mode: ActionMode,
  oobCode: string;
  continueUrl: string // Currently unused, but can be supplied.
}

export function HandleActionContent({mode, oobCode}: HandleActionContentProps) {
  const [auth, errorHandler] = useAuth();
  const toast = useToast();
  const [isCheckingCode, setIsCheckingCode] = useState<boolean>(false);

  useEffect(() => {
    if (!isCheckingCode) { return; }
    checkActionCode(auth, oobCode).then((info: ActionCodeInfo) => {
      const operation = info.operation as ActionOperation;
      if (modeMatchesOperation(mode, operation)) {
        toast({
          status: 'warning',
          isClosable: true,
          duration: null,
          description: 'Operation code does not match requested mode',
        });
      } else {
        setIsCheckingCode(false);
      }
    }).catch(errorHandler);
  }, [mode, oobCode, auth, errorHandler, toast, isCheckingCode, setIsCheckingCode]);

  if (isCheckingCode) {
    return (<Text display='block'>
      <em>Checking Action Code...</em>
    </Text>);
  }

  if (mode === ActionMode.VERIFY) {
    return <VerifyEmail oobCode={oobCode} />
  } else if (mode === ActionMode.RESET) {
    return <ResetPassword oobCode={oobCode} />
  } else {
    return (<Alert status='error'>
      <AlertIcon />&nbsp;Unknown Action type:&nbsp;{mode}
    </Alert>);
  }
}

const inputStyleProps: InputProps = {
  variant: 'outline',
  bg: 'grayscale.white',
}

interface VerifyEmailProps {
  oobCode: string;
  continuePath?: Path;
}
export function VerifyEmail({ oobCode, continuePath = Path.home }: VerifyEmailProps) {
  const [auth, errorHandler] = useAuth();
  const navigate = useNavigateWithParams();
  const toast = useToast();
  const email = useUser(true)?.email || '';
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const verifyEmail = () => {
    if (isVerifying) { return; }
    setIsVerifying(true);
    applyActionCode(auth, oobCode).then(() => {
      toast({
        status: 'success',
        description: 'Email verified!'
      });
      navigate({to: continuePath, modifySearch: removeActionParms});
    }).catch(errorHandler).finally(() => {
      setIsVerifying(false);
    });
  };

  return (<VStack>
    <FormControl isRequired={true} isInvalid={!oobCode} width='100%'>
      <FormLabel>Action Code</FormLabel>
      <Input
        type='text'
        {...inputStyleProps}
        value={oobCode}
        readOnly={true}
      />
      <FormErrorMessage>Action code is required</FormErrorMessage>
    </FormControl>
    <FormControl isRequired={true} isInvalid={!email}  width='100%'>
      <FormLabel>Email</FormLabel>
      <Input
        type='email'
        {...inputStyleProps}
        value={email}
        readOnly={true}
      />
      <FormErrorMessage>
        <Button
          variant='link'
          size='small'
          onClick={() => {
            navigate({to: Path.login});
          }}
        >Sign in</Button>
        &nbsp;to verify email.
      </FormErrorMessage>
    </FormControl>
    <Button
      variant='fill'
      colorScheme='primary'
      width='100%'
      isLoading={isVerifying}
      onClick={() => verifyEmail()}
    >Verify Email</Button>
  </VStack>);
}

interface ResetPasswordProps {
  oobCode: string;
  continuePath?: Path;
}
export function ResetPassword({ oobCode, continuePath = Path.home }: ResetPasswordProps) {
  const [auth, errorHandler] = useAuth();
  const navigate = useNavigateWithParams();
  const toast = useToast();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [confirmedNewPassword, setConfirmedNewPassword] = useState<string>('');
  const [confirmedNewPasswordError, setConfirmedNewPasswordError] = useState<string>('');
  
  const resetErrors = () => {
    setNewPasswordError('');
    setConfirmedNewPasswordError('');
  };

  const validate = () => {
    if (!newPassword) {
      setNewPasswordError('Password is required');
      return false;
    }
    if (newPassword !== confirmedNewPassword) {
      setConfirmedNewPasswordError('Passwords do not match');
      return false;
    }
    return true;
  }

  const confirmNewPassword = () => {
    if (isConfirming) { return; }
    resetErrors();
    if (!validate()) { return; }
    setIsConfirming(true);
    confirmPasswordReset(auth, oobCode, newPassword).then(() => {
      toast({
        status: 'success',
        description: 'Password Reset!'
      });
      navigate({to: continuePath, modifySearch: removeActionParms });
    }).catch(errorHandler).finally(() => {
      setIsConfirming(false);
    });
  };

  return (<VStack>
    <FormControl isRequired={true} isInvalid={!oobCode} width='100%'>
      <FormLabel>Action Code</FormLabel>
      <Input
        type='text'
        {...inputStyleProps}
        value={oobCode}
        readOnly={true}
      />
      <FormErrorMessage>Action code is required</FormErrorMessage>
    </FormControl>
    <FormControl isRequired={true} isInvalid={!!newPasswordError} width='100%'>
      <FormLabel>New Password</FormLabel>
      <Input
        type='password'
        {...inputStyleProps}
        value={newPassword}
        onChange= {(e) => {
          setNewPassword(e.target.value);
        }}
      />
      <FormErrorMessage>{newPasswordError}</FormErrorMessage>
    </FormControl>
    <FormControl isRequired={true} isInvalid={!!confirmedNewPasswordError} width='100%'>
      <FormLabel>Confirm New Password</FormLabel>
      <Input
        type='password'
        {...inputStyleProps}
        value={confirmedNewPassword}
        onChange= {(e) => {
          setConfirmedNewPassword(e.target.value);
        }}
      />
      <FormErrorMessage>{newPasswordError}</FormErrorMessage>
    </FormControl>
    <Button
      variant='fill'
      colorScheme='primary'
      width='100%'
      isLoading={isConfirming}
      onClick={() => confirmNewPassword()}
    >Confirm New Password</Button>
  </VStack>);
}
