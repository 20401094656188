import React, { useContext } from "react";

import { UserContext } from 'src/auth';

import { AwaitUser } from './AwaitUser';
import { Requirement } from './Requirement';
import { RequireUserProps } from './Props';



/**
 * Ensures children will have access to a loaded non-null UserContext; 
 */
export function RequireUser(props : RequireUserProps) {
  return (
    <AwaitUser>
      <RequireUserInner {...props}/>
    </AwaitUser>
  );
}

function RequireUserInner({
  excludeAnonymous = false,
  ...requirementProps
}: RequireUserProps) {
  const user = useContext(UserContext);
  const hasUser = !excludeAnonymous ? !!user : !user?.isAnonymous || false;

  return <Requirement {...requirementProps} metRequirement={hasUser} />;
}
