import { AssetDocument, AssetDocumentConverter } from '../asset';

export interface ValueAssetValue {
  brandValue: string;
}

export type ValueAssetDocument = AssetDocument<ValueAssetValue>;

class ValueAssetDocumentConverter extends AssetDocumentConverter<ValueAssetValue> {
  override assetValueFromFirestore(assetValue: any) : ValueAssetValue {
    return { brandValue: assetValue.brandValue };
  }

  override renderLabel(value: ValueAssetValue): string {
    return value.brandValue;
  }
}

export const valueAssetConverter = new ValueAssetDocumentConverter();
