import React from 'react';

import {
  Icon,
  Stack,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { IoSparklesSharp } from "react-icons/io5";

import { AssetCard } from 'src/components';
import { LogoPreview } from 'src/components/assets';
import { LogoAssetDocument } from 'src/db';
import { AddSimilarLogoAsset } from 'src/components/assets/addAsset/AddSimilarLogoAsset';

type HandleLogo = (logoDoc: LogoAssetDocument) => void;

interface VerticalLogoListProps extends StackProps {
  logos: LogoAssetDocument[];
  onLogoSelect?: HandleLogo;
  pendingSelections?: Set<string>;
}

export function VerticalLogoList({
  logos,
  onLogoSelect,
  pendingSelections = new Set(),
  ...stackProps
}: VerticalLogoListProps) {
  return (
    <Stack {...stackProps}>
      {logos.map((logo, idx, arr) => {
        return (
          <AssetCard
            key={logo.id}
            value={logo}
            isInProgress={pendingSelections.has(logo.id)}
            includeSelection={!!onLogoSelect}
            selectionAction={onLogoSelect}
            selectionLabel='Select This Logo'
            includeRemove={false}
            includeExplanation={true}
          >
            <VStack align='center'>
              <LogoPreview logo={logo} />
              <AddSimilarLogoAsset
                logoAsset={logo}
                variant='ghost'
                colorScheme='secondary'
              >
                <Icon as={IoSparklesSharp} aria-hidden={true} />&nbsp;
                <span>Generate Similar</span>
              </AddSimilarLogoAsset>
            </VStack>
          </AssetCard>
        );
      })}
    </Stack>
  );
}
