import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';

// Credits Document
export interface UserCreditsDocument {
  id: string;
  profileId: string; // foreign
  amount: number;
}

export const userCreditsConverter: FirestoreDataConverter<UserCreditsDocument> = {
  toFirestore (document: UserCreditsDocument) {
    return {
      profileId: document.profileId,
      amount: document.amount,
    };
  },

  fromFirestore (snapshot: DocumentSnapshot, options: SnapshotOptions): UserCreditsDocument{
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      profileId: data.profileId,
      amount: data.amount,
    };
  },
};
