import { AssetDocument, AssetDocumentConverter } from '../asset';

export interface SloganAssetValue {
  slogan: string;
}

export type SloganAssetDocument = AssetDocument<SloganAssetValue>;

class SloganAssetDocumentConverter extends AssetDocumentConverter<SloganAssetValue> {
  override assetValueFromFirestore(assetValue: any) : SloganAssetValue {
    return { slogan: assetValue.slogan };
  }

  override renderLabel(value: SloganAssetValue): string {
    return value.slogan;
  }
}

export const sloganAssetConverter = new SloganAssetDocumentConverter();
