import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"

// Prop Notes:
//
// Only the current use cases in Figma have been implemented.
//
// The only implemented variant is `solid` (the default).
// The only implemented size is 'md' (the default).

// Note: Only implemented size is md (the default)
const sizes = {
  md: defineStyle({
    borderWidth: '1px',
  }),
}

const solidVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    borderStyle: 'solid',
    borderColor: `${c}.veryDark`,
  }
});

const baseStyle = defineStyle({});

export const Divider = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    solid: solidVariant,
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
    variant: 'solid',
  },
});
