import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';
import { Message } from 'src/components/chat';

export interface AgentThreadDocument {
  id: string; // AKA agentThreadId
  profileId: string, // foreign
  businessId: string, // foreign
}

export const agentThreadConverter: FirestoreDataConverter<AgentThreadDocument> = {
  toFirestore (document: AgentThreadDocument) {
    return {
      agentThreadId: document.id,
      profileId: document.profileId,
      businessId: document.businessId,
    };
  },

  fromFirestore (snapshot: DocumentSnapshot, options: SnapshotOptions): AgentThreadDocument {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      profileId: data.profileId,
      businessId: data.businessId,
    };
  },
};

export interface AgentThreadMessage {
  content: string;
  created_at: number;
  id: string;
  role: `assistant` | `user`
  thread_id: string
}

const roleToSource = (role: string): 'ai'|'human' => {
  switch(role) {
    case 'assistant':
      return 'ai';
    case 'user':
      return 'human';
    default:
      throw new Error(`Unknown role: ${role}`);
  }
}

export function convertAgentToMessage(agent: AgentThreadMessage): Message {
  return {
    id: agent.id,
    body: agent.content,
    source: roleToSource(agent.role),
    createdAt: agent.created_at,
  };
}
