import { AssetDocument, AssetDocumentConverter } from '../asset';

export interface ColorsAssetValue {
  color: {
    baseColor: string;
    hexCodes: string[];
  }
}

export type ColorsAssetDocument = AssetDocument<ColorsAssetValue>;

class ColorsAssetDocumentConverter extends AssetDocumentConverter<ColorsAssetValue> {
  override assetValueFromFirestore(assetValue: any) : ColorsAssetValue {
    return {
      color: {
        baseColor: assetValue.color.baseColor,
        hexCodes: assetValue.color.hexCodes,
      }
    }
  }
}

export const colorsAssetConverter = new ColorsAssetDocumentConverter();
