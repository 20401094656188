import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";

import { Api } from './Api';
import { ApiMethods, ApiPending } from './ApiMethods'
import { ApiContext, ApiPendingContext } from './contexts';
import { Auth } from 'firebase/auth';

interface ApiProviderProps extends PropsWithChildren {
  auth: Auth,
  apiBaseUrl: string;
};

export function ApiProvider({apiBaseUrl, auth, children}: ApiProviderProps) {
  const [apiPending, setApiPending] = useState<ApiPending>(new Set());

  const notifyPending = useCallback((method: ApiMethods) => {
    setApiPending((previous: ApiPending) => {
      if (previous.has(method)) { return previous; }
      previous.add(method);
      return new Set(previous);
    });
  }, [setApiPending]);
  const notifyComplete = useCallback((method: ApiMethods) => {
    setApiPending((previous: ApiPending) => {
      if (!previous.has(method)) { return previous; }
      previous.delete(method);
      return new Set(previous);
    });
  }, [setApiPending]);

  const api = useMemo(() => {
    return new Api(apiBaseUrl, auth, notifyPending, notifyComplete);
  }, [apiBaseUrl, auth, notifyPending, notifyComplete])

  return (
    <ApiContext.Provider value={api}>
      <ApiPendingContext.Provider value={apiPending}>
        {children}
      </ApiPendingContext.Provider>
    </ApiContext.Provider>
  );
}
