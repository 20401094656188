import React from 'react';
import { ButtonProps } from '@chakra-ui/react';

import { AssetTypes } from 'src/db';
import { BrandStoryAssetValue } from 'src/db/model/assets/brandStoryAsset';
import { AddTextAssetButton } from './AddTextAsset';

const TypedAddTextAssetButton = AddTextAssetButton<BrandStoryAssetValue>;
const parseText = (text: string): BrandStoryAssetValue => {
  return {brandStories: text};
};

export function AddBrandStoryAssetButton({ children, ...btnProps} : ButtonProps) {
  return (
    <TypedAddTextAssetButton
      assetType={AssetTypes.brandStory}
      assetUiName='Brand Story'
      parseText={parseText}
      {...btnProps}
    >{children}</TypedAddTextAssetButton>
  );
}

