import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useApi } from 'src/api';
import { AssetTypes, AssetValueTypes, useBusinessId } from 'src/db';

type TextParser<V> = (text: string) => V;

export interface AddTextAssetProps<V extends AssetValueTypes> extends ButtonProps {
  assetType: AssetTypes;
  assetUiName: string;
  parseText: TextParser<V>; 
}
export function AddTextAssetButton<V extends AssetValueTypes>({
  assetType, assetUiName, parseText, children, ...buttonProps
}: AddTextAssetProps<V>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const businessId = useBusinessId()!;
  const [ api, surfaceKnownErrors ] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');

  const handleSubmit = useCallback((text: string) => {
    if (isSubmitting) { return; }
    const assetValue = parseText(text);
    setIsSubmitting(true);
    api.addAsset({
      businessId, assetType,
      assetData: { assetValue },
    }, surfaceKnownErrors).then(() => {
      onClose();
    }).finally(() => {
      setIsSubmitting(false);
    });
  }, [
    api, surfaceKnownErrors, isSubmitting,
    assetType, businessId, parseText, onClose,
  ]);

  return (<>
    <Button
      {...buttonProps}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        onOpen();
      }}
    >{children}</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader>
          <Center>
            <Text textStyle='bodyLarge'>{`Add ${assetUiName}`}</Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={(e) => {
            // Prevent page refresh
            e.preventDefault();
            if (!!textValue) {
              handleSubmit(textValue);
            }
          }}>
            <Flex
              direction = 'row'
              align='center'
              justify='space-between'
              wrap='wrap'
            >
              <FormControl flex='1 0 fit-content'>
                <Input
                  ref={inputRef}
                  type='text'
                  placeholder='Type here'
                  variant='filled'
                  onChange={(e) => {
                    setTextValue(e.target.value);
                  }}
                />
              </FormControl>
              <Spacer flex='0 0 8px' />
              <Button
                type='submit'
                variant='fill'
                colorScheme='primary'
                size='sm'
                flex='0 0 fit-content'
                isDisabled={!textValue}
                isLoading={isSubmitting}
              >Add</Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>);
}
