import { AssetDocument, AssetDocumentConverter } from '../asset';
import { ImageInfo } from '../common';

import { FontAssetValue } from './fontAsset';
import { NameAssetValue } from './nameAsset';

interface DynamicLogoDetails extends FontAssetValue, NameAssetValue {
  imageInfo: ImageInfo;
  textPositionX: 'Top' | 'Center' | 'Bottom';
  textPositionY: 'Left' | 'Center' | 'Right';
}

export interface LogoAssetValue {
  logo: DynamicLogoDetails;
}

export type LogoAssetDocument = AssetDocument<LogoAssetValue>;

class LogoAssetDocumentConverter extends AssetDocumentConverter<LogoAssetValue> {
  override assetValueFromFirestore(assetValue: any) : LogoAssetValue {

    const value: LogoAssetValue = {
      logo: {
        imageInfo: assetValue.logo.imageInfo,
        font: assetValue.logo.font || {
          name: '',
          url: '',
          weight: '',
        },
        name: assetValue.logo.name || '',
        textPositionX: assetValue.logo.textPositionX || '',
        textPositionY: assetValue.logo.textPositionT || '',
      },
    };

    return value;
  }
}

export const logoAssetConverter = new LogoAssetDocumentConverter();
