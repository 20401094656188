import React, { SyntheticEvent, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import {
  Button,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { MdNavigateBefore, MdNavigateNext, MdOutlineArrowDropDown } from "react-icons/md";

import { useSelectedDate } from "src/db";

export function DatePicker({ ...flexProps } : FlexProps) {
  const [ selectedDate, setSelectedDate ] = useSelectedDate();

  const goToNextDate = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    const nextDate = new Date(selectedDate);
    nextDate.setUTCDate(selectedDate.getUTCDate() + 1);
    setSelectedDate(nextDate);
  }, [selectedDate, setSelectedDate])

  const goToPrevDate = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    const prevDate = new Date(selectedDate);
    prevDate.setUTCDate(selectedDate.getUTCDate() - 1);
    setSelectedDate(prevDate);
  }, [selectedDate, setSelectedDate])

  return (
    <Flex
      direction='row'
      justify='space-between'
      align='center'
      {...flexProps}
    >
      <IconButton
        aria-label='Previous'
        flex='0 0 fit-content'
        mx={4}
        boxSize='24px'
        isRound={true}
        bg='grayscale.white'
        icon={<Icon as={MdNavigateBefore} color='primary.dark' boxSize='24px'/>}
        onClick={goToPrevDate}
        _focus={{ bg: 'grayscale.white' }}
      />
      <Popover>
        <PopoverTrigger>
          <Button
            flex='1 0 0'
            variant='ghost'
            color='white'
            _focus={{ bg: 'transparent' }}
          >
            {selectedDate.toDateString()}
            &nbsp;<MdOutlineArrowDropDown />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <ThemedCalendar />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <IconButton
        aria-label='Next'
        flex='0 0 fit-content'
        mx={4}
        boxSize='24px'
        isRound={true}
        bg='grayscale.white'
        icon={<Icon as={MdNavigateNext} color='primary.dark' boxSize='24px'/>}
        onClick={goToNextDate}
        _focus={{ bg: 'grayscale.white' }}
      />
    </Flex>
  )
}

function ThemedCalendar() {
  const [ selectedDate, setSelectedDate ] = useSelectedDate();

  return <Calendar
    value={selectedDate}
    onChange={(newValue) => {
      setSelectedDate(newValue as Date);
    }}
    prev2Label={null}
    next2Label={null}
  />
}
