import React from 'react';

import { BoxProps, Button, Card, Flex, Image, useDisclosure } from '@chakra-ui/react';

import { SimpleMessageBox } from 'src/components';
import { AssetDocument } from 'src/db';
import { avatar } from 'src/images';


interface AssetExplanationProps extends BoxProps {
  asset: AssetDocument;
}
export function AssetExplanation({ asset, ...boxProps }: AssetExplanationProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const explanation = asset.explanation || '(No explanation available)';

  return (<Card bg='grayscale.offwhite' {...boxProps} width='100%'>
    {isOpen ? 
      <Flex direction='column' align='center' width='100%'>
        <SimpleMessageBox bg='transparent'
          messages={[
            { source: 'ai', body: explanation }
          ]}
          border='none'
        />
        <Button variant='link' onClick={() => { onClose(); }}>
          (Hide explanation)
        </Button>
      </Flex> :
      <Flex
        direction='row'
        justify='center'
        align='end'
        position='relative'
        width='100%' p={4}
      >
        <Image
          src={avatar}
          alt='Agent Brandi'
          boxSize='30px'
        />
        <Button variant='link' onClick={() => { onOpen(); }} size="sm">
          Why this recommendation?
        </Button>
      </Flex>
    }
  </Card>);
}
