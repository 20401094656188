import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    bg: 'grayscale.white',
    color: 'text.body',
    borderRadius: 16,
    boxShadow: 'card',
  },
});

const sizes = {
  sm: definePartsStyle({
    container: {
      borderRadius: 8,
    },
  }),
}

export const Card = defineMultiStyleConfig({ baseStyle, sizes });
