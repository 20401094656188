import React from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { IoArrowUpCircleOutline, IoSparklesSharp } from "react-icons/io5";

import { AssetOrigins } from 'src/db';

export interface OriginProps {
  origin: AssetOrigins;
}

// The Full Tag, with icon and text.
export function AssetOriginTag({ origin }: OriginProps) {
  const isGenerated = origin !== 'USER';
  return (
    <HStack gap={1} color={isGenerated ? 'secondary.dark' : 'primary.dark'}>
      <AssetOriginIcon origin={origin} />
      <Text textStyle='caption'>
        {isGenerated ? 'AI Generated' : 'Uploaded'}
      </Text>
    </HStack>
  );
}

// Just the Icon, for use when space is limited.
export function AssetOriginIcon({ origin }: OriginProps) {
  const isGenerated = origin !== 'USER';
  return (
    <Icon
      as={isGenerated ? IoSparklesSharp : IoArrowUpCircleOutline}
      color={isGenerated ? 'secondary.dark' : 'primary.dark'}
      aria-hidden={true}
      boxSize='18px'
    />
  );
}


