import React from 'react';

import {
  Divider,
  Flex,
  Icon,
  Image,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { SlClose } from 'react-icons/sl'

import { avatar } from 'src/images';
import { appMaxWidth } from 'src/util/constants'

import { AutoScrollBox } from './AutoScrollBox';
import { MessageInput } from './MessageInput';
import { MessageList } from './MessageList';

export function ConversationModalContent() {
  const mobileDemoStyles = {
    maxWidth: appMaxWidth,
    margin: '0 auto,'
  };

  return (
    <ModalContent
      bg='grayscale.white'
      display='flex'
      flexDirection='column'
      px={4}
      sx={mobileDemoStyles}
    >
      <ModalHeader>
        <Flex
          align='center'
          justify='space-between'
          direction='row'
          my={4}
        >
          <Image src={avatar} alt='logo' boxSize='48px' />
          <Text textStyle='avatar' mx={2}>
            You're chatting with Agent Brandi
          </Text>
          <Spacer />
          <ModalCloseButton color='primary.medium'>
            <Icon as={SlClose} boxSize='1.5em' />
          </ModalCloseButton>
        </Flex>
        <Divider color='primary.light' />
      </ModalHeader>
      <AutoScrollBox flexGrow={1} p={4}>
        <MessageList />
      </AutoScrollBox>
      <ModalFooter>
        <MessageInput my={6} />
      </ModalFooter>
    </ModalContent>
  )
}
