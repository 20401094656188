import { createContext } from 'react';
import { Message } from './Message';

export type MessageHandler = (m: Message) => void;
export type ModalAction = (isOpen: boolean) => void;

export interface ConversationInterface {
  messages: Message[];
  pending: string[];
  isAiThinking: boolean;
  isModalOpen: boolean;
  sendMessage: MessageHandler;
  addMessage: MessageHandler;
  toggleModal: ModalAction;
}

export const ConversationContext = createContext<ConversationInterface>({} as ConversationInterface);
