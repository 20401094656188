import React, { ReactNode, PropsWithChildren } from 'react';

import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';

import { HeaderMenu } from 'src/components';
import { Path, useNavigateWithParams } from 'src/nav';

import { Footer } from '../layout/Footer'

interface HeaderFooterProps extends BoxProps {
  pageTitle?: string;
  // if missing, no back button.
  backTo?: Path;
  backLabel?: string;
  // Optional element in the top right corner
  topRightContent?: ReactNode,
  // Optional element in the top right corner
  banner?: ReactNode,
}

const sidePadding = 4;

export function HeaderFooterPage({
  pageTitle,
  backTo,
  backLabel = 'Go Back',
  topRightContent = null,
  banner = null,
  children,
  ...boxProps
}: PropsWithChildren<HeaderFooterProps>) {
  const navigate = useNavigateWithParams();
  return (<Flex direction='column' align='stretch' minHeight='100vh'>
    <HeaderMenu px={sidePadding} py={2} flex='0 0 fit-content' />
    <Box as='header' px={sidePadding} flex='0 0 fit-content' {...boxProps}>
      {!!backLabel &&
        <Flex justify='space-between' align='center'>
          {!!backTo && <Button size='lg' onClick={() => {navigate({to: backTo})}}>
            <Flex align='center' direction='row' color='primary.medium'>
              <Icon as={MdArrowBack} mr={1}/>
              <Text>{backLabel}</Text>
            </Flex>
          </Button>}
          {topRightContent}
        </Flex>
      }
      {!!pageTitle &&
        <Center m={4}>
          <Text as='h1' textStyle='titleForPage'>{pageTitle}</Text>
        </Center>
      }
      
    </Box>
    <Box as='main' position='relative' flex='1 0 0'>
      {banner}
      <Box px={sidePadding} width='100%'>
        {children}
      </Box>
    </Box>
    <Footer flex='0 0 fit-content' />
  </Flex>);
}
