import React from 'react';

import { MAX_SELECTED_COLORS }  from 'src/api/constants';
import { AssetTypes, ColorsAssetDocument } from 'src/db';
import { AddColorsAssetButton } from 'src/components/assets/addAsset';
import { ColorGrid } from 'src/components/assets';

import { AssetProps, DetailPage, RedirectToProps } from './DetailPage';
import { Path } from 'src/nav';

const redirectBack: RedirectToProps = {
  to: Path.slogan,
};

const redirectTo: RedirectToProps = {
  to: Path.value,
};

export function ColorsPage() {
  const RenderColor = ({asset}: AssetProps<ColorsAssetDocument>) => {
    return <ColorGrid colors={asset} m={8} minHeight='64px'/>;
  };

  return <DetailPage<ColorsAssetDocument>
    title='Colors'
    assetUiName='Color'
    assetType={AssetTypes.color}
    SelectedAsset={RenderColor}
    SuggestedAsset={RenderColor}
    selectionLimit={MAX_SELECTED_COLORS}
    AddAssetButton={AddColorsAssetButton}
    redirectTo={redirectTo}
    redirectBack={redirectBack}
  />
}
