import React from 'react';

import { AssetTypes } from 'src/db';
import { BrandStoryAssetValue } from 'src/db/model/assets/brandStoryAsset';
import { UpdateTextAssetButton, UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';

const TypedUpdateTextAssetButton = UpdateTextAssetButton;

const getValue = ({brandStories}: BrandStoryAssetValue): string => {
  return brandStories;
}

const parseText = (text: string): BrandStoryAssetValue => {
  return {brandStories: text};
};

export function UpdateBrandStoryAssetButton({asset, children, ...btnProps} : UpdateTextAssetButtonProps) {
  return (
    <TypedUpdateTextAssetButton
      assetId={asset.id}
      assetType={AssetTypes.brandStory}
      assetUiName='Brand Story'
      assetValue={getValue(asset.value as BrandStoryAssetValue)}
      parseText={parseText}
      {...btnProps}
    />
  );
}

