import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';

export interface ReportDocument {
  id: string;
  businessId: string;
  userId: string;
  content: string;
  createdAt: Date;
  modifiedAt: Date;
  report_type: string;
  references: string;
  summary: string;
  prompt: string;
  status: ReportStatus;
}

export enum ReportStatus {
  CREATING = 'CREATING',
  READY = 'READY',
  ERROR = 'ERROR',
  DELETED = 'DELETED'
}

export enum ReportType {
  MARKET_RESEARCH_ANALYSIS = 'market_research_analysis',
  SWOT_ANALYSIS = 'swot_analysis',
  BUSINESS_PLAN = 'business_plan',
}

export function getTitle(report: ReportDocument) {
  switch (report.status) {
    case ReportStatus.CREATING:
      return "Generating Report..."
    case ReportStatus.ERROR:
      return "Generating Report..."
    default:
      return report.report_type.split("_")
        .map(w => w.charAt(0).toUpperCase() + w.slice(1))
        .join(" ")
  }
}

export function getSummary(report: ReportDocument) {
  switch (report.status) {
    case ReportStatus.CREATING:
      return "Please wait. You report is being generated. This will take 5 to 10 minutes."
    case ReportStatus.ERROR:
      return "We encountered an issue while generating the report. \n Your credits have been refunded. Please try submitting your request again."
    default:
      return report.summary
  }
}

export function getShortSummary(report: ReportDocument) {
  return trim(getSummary(report))
}

function trim(text: string) {
  const maxLength = 50;
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  } else {
    return text;
  }
}

export const reportConverter: FirestoreDataConverter<ReportDocument> = {
  toFirestore(report: ReportDocument) {
    return {
      businessId: report.businessId,
      userId: report.userId,
      content: report.content,
      createdAt: report.createdAt,
      modifiedAt: report.modifiedAt,
      report_type: report.report_type,
      references: report.references,
      summary: report.summary,
      prompt: report.prompt,
      status: report.status
    };
  },

  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): ReportDocument {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      businessId: data.businessId,
      userId: data.userId,
      content: data.content,
      createdAt: data.createdAt.toDate(),
      modifiedAt: data.modifiedAt.toDate(),
      report_type: data.report_type,
      references: data.references,
      summary: data.summary,
      prompt: data.prompt,
      status: data.status
    };
  }
};