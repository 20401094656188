import { defineStyleConfig } from '@chakra-ui/react'
// TODO: Add colorscheme / variants.

// define the base component styles
const baseStyle = {
  borderRadius: 'md',
  bg: 'grayscale.black',
  color: 'grayscale.white',
  padding: '4px',
}

// export the component theme
export const Tooltip = defineStyleConfig({ baseStyle });
