import { AssetDocument, AssetDocumentConverter } from '../asset';

export interface AudienceAssetValue {
  targetAudience: string;
}

export type AudienceAssetDocument = AssetDocument<AudienceAssetValue>;

class AudienceAssetDocumentConverter extends AssetDocumentConverter<AudienceAssetValue> {
  override assetValueFromFirestore(assetValue: any) : AudienceAssetValue {
    return { targetAudience: assetValue.targetAudience };
  }

  override renderLabel(value: AudienceAssetValue): string {
    return value.targetAudience;
  }
}

export const audienceAssetConverter = new AudienceAssetDocumentConverter();
