import React, { useContext } from 'react';

import {
  Box,
  BoxProps,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react';

import {
  AssetDocument,
  AssetTypes,
  AssetsContext,
  AssetsContextProperties,
  useIsBrandKitLocked,
} from 'src/db';

import {
  DownloadBrandKitButton,
  LockBrandKitButton,
  UnlockBrandKitButton,
  CongratulationsModal,
} from 'src/components';
import { SearchParam, useSearchParam } from 'src/nav';
import { OPTIONAL_ASSETS } from 'src/api/constants';

const selectedOnly = (doc: AssetDocument<unknown>) => {
  return doc.selected;
};

export function BrandKitProgress({ ...boxProps }: BoxProps) {
  const assets = useContext(AssetsContext);
  const isLocked = useIsBrandKitLocked();
  const showCongratulationsModal = useSearchParam(SearchParam.congratulations);

  // Count the type as completed if at least one selected.
  const allAssetSelections = ([
    !!assets[AssetTypes.brandStory].collection.filter(selectedOnly).length,
    !!assets[AssetTypes.color].collection.filter(selectedOnly).length,
    !!assets[AssetTypes.font].collection.filter(selectedOnly).length,
    !!assets[AssetTypes.logo].collection.filter(selectedOnly).length,
    !!assets[AssetTypes.name].collection.filter(selectedOnly).length,
    !!assets[AssetTypes.slogan].collection.filter(selectedOnly).length,
    !!assets[AssetTypes.audience].collection.filter(selectedOnly).length,
    !!assets[AssetTypes.value].collection.filter(selectedOnly).length,
  ]);

  const completionPercent = allAssetSelections.filter((isComplete: boolean) => {
    return isComplete;
  }).length / allAssetSelections.length;

  const mandatoryAssets = Object.values(AssetTypes)
    .filter(asset => !OPTIONAL_ASSETS.includes(asset))
    .map((key) => key as keyof AssetsContextProperties);

  const mandatoryAssetsCompleted = mandatoryAssets.every(assetType => {
    const assetCollection = assets[assetType].collection as AssetDocument<unknown>[];
    return assetCollection.filter(selectedOnly).length > 0;
  });

  const uiCompletionPercent = Math.floor(completionPercent * 100);
  const isAllComplete = completionPercent === 1;


  return (<>
    {showCongratulationsModal && mandatoryAssetsCompleted && <CongratulationsModal />}
    {!isAllComplete && <Box minWidth='140px' {...boxProps}>
      <Flex
        direction='row'
        bg='grayscale.medium'
        borderRadius='lg'
        overflow='hidden'
        height='32px'
        width='100%'
      >
        <Flex
          align='center'
          direction='row'
          flex={`0 0 ${completionPercent * 100}%`}
          justify='center'
          bg='secondary.dark'
        >
          <Text
            textStyle='display4'
            color='grayscale.white'
            align='center'
          >
            {uiCompletionPercent}%
          </Text>
        </Flex>
        {!isAllComplete && <Spacer flex='1 0 8px' />}
      </Flex>
      <Flex
        textStyle='legend'
        direction='row'
        justify='space-between'
        width='100%'
      >
        <Text color='secondary.dark'>{uiCompletionPercent}% Complete</Text>
        <Text color='grayscale.dark'>Complete</Text>
      </Flex>
    </Box>}
    {mandatoryAssetsCompleted &&
      <Card bg='secondary.dark' p={4} {...boxProps}>
        <CardBody mb={4}>
          <Text
            align='center'
            color='grayscale.white'
            textStyle='display3'
          >
            Congratulations! Your AI Brand Kit is complete!
          </Text>
          <Text
            align='center'
            color='grayscale.white'
          >
            Finalize your brand identity and unlock daily action to grow your brand.
            You can update your brand later by unlocking it.
          </Text>
        </CardBody>
        <CardFooter>
          {isLocked
            ? <VStack align='center' width='100%'>
              <DownloadBrandKitButton margin='0 auto' /><UnlockBrandKitButton margin='0 auto' /></VStack>
            : <LockBrandKitButton margin='0 auto' />
          }
        </CardFooter>
      </Card>
    }
  </>)
}