import React, { SyntheticEvent, useState } from "react";

import {
  Button,
  ButtonProps,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  Image,
} from "@chakra-ui/react";

import { useBusinessId } from "src/db";
import { useApi } from "src/api";

interface AddSimilarLogoAssetProps extends ButtonProps {
  logoAsset: any;
}

export function AddSimilarLogoAsset({
  children,
  logoAsset,
  ...buttonProps
}: AddSimilarLogoAssetProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClickToGenerateSimilar = async () => {
    if (isSubmitting) { return; }
    setIsSubmitting(true);
    // Create a form to submit the image to the backend
    const htmlForm = await getImageForm(
      logoAsset.value.logo.imageInfo.imageUrl,
      businessId
    );
    const data = new FormData(htmlForm);
    api.addSimilarLogoAsset(data, surfaceKnownErrors).then(() => {
      onClose();
    }).finally(() => {
      setIsSubmitting(false);
    });
  };
  return (<>
    <Button
      {...buttonProps}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        onOpen();
      }}
    >
      {children}
    </Button>
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader>
          <Center>
            <Text textStyle="bodyLarge">Add Similar Logo</Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Should we generate one logo similar to this one?</Text>
          <Image
            src={logoAsset.value.logo.imageInfo.imageUrl}
            alt={
              logoAsset.explanation || `Logo for ${logoAsset.value.logo.name}`
            }
            boxSize="100%"
            objectFit="contain"
            loading="eager"
            mt={4}
            mb={4}
          />
          <Button
            colorScheme="primary"
            variant="fill"
            width="100%"
            mb={4}
            onClick={handleClickToGenerateSimilar}
            isLoading={isSubmitting}
          >Generate Similar</Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>);
}

async function getImageForm(
  imageUrl: string,
  businessId: string
): Promise<HTMLFormElement> {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const file = new File([blob], "logo.png", { type: "image/png" });
  const container = new DataTransfer();
  container.items.add(file);

  const form = document.createElement("form");

  const hiddenInputBusinessId = document.createElement("input");
  hiddenInputBusinessId.type = "hidden";
  hiddenInputBusinessId.name = "businessId";
  hiddenInputBusinessId.value = businessId;
  form.appendChild(hiddenInputBusinessId);

  const inputFile = document.createElement("input");
  inputFile.type = "file";
  inputFile.name = "file";
  inputFile.accept = "image/*";
  inputFile.files = container.files;
  form.appendChild(inputFile);

  return form;
}
