import { logger, LogSource } from 'src/util/logger';

export interface ApiResponse<Data> {
  status: 'success'|'error';
  data: Data,
  message: string;
  errorType?: string;
  errorUserMessage?: string;
}

/**
 * Convert a generic object to an ApiResponse.
 * 
 * Will throw an error if 
 */
export function toApiResponse(response: any): ApiResponse<unknown> {
  let errorMessage: string = '';
  if (typeof response.status !== 'string') {
    errorMessage = 'Invalid Response#status';
  } else if (typeof response.message !== 'string') {
    errorMessage = 'Invalid Response#message';
  } else if (response.data === undefined) {
    errorMessage = 'Invalid Response#data';
  }
  if (!errorMessage) {
    return {
      status: response.status,
      message: response.message,
      data: response.data,
      errorType: response.errorType || undefined,
      errorUserMessage: response.errorUserMessage || undefined,
    };
  } else {
    logger.severe(LogSource.API, 'Unparsable API Response', new Error(errorMessage));
    return { status: 'error', data: null, message: 'Unparsable API Response' };
  }
}
