import React, { SyntheticEvent, FormEvent, useRef, useState } from "react";
import {
  Button,
  ButtonProps,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Text,
  useDisclosure,
  Input,
  Alert,
  AlertIcon,
  FormControl,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";

import { useApi } from "src/api";
import { useBusinessId, useSelectedDate } from "src/db";

export function AddSocialMediaAssetButton({
  children,
  ...buttonProps
}: ButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [, setSelectedDate] = useSelectedDate();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (
      !(
        (e.target as HTMLFormElement).elements.namedItem(
          "file"
        ) as HTMLInputElement | null
      )?.value
    ) {
      return;
    }
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const data = new FormData(e.target as HTMLFormElement);
    api
      .addSocialMediaPostAsset(data, surfaceKnownErrors)
      .then(() => {
        setSelectedDate(new Date());
        onClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {children}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        initialFocusRef={inputRef}
      >
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>
            <Center>
              <Text textStyle="bodyLarge">Add Social Media Post</Text>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center padding={5}>
              <Text fontSize="md">
                Send us a photo of your product, store, or anything from your
                business
              </Text>
            </Center>
            <FormControl display="flex" alignItems="center">
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="businessId" value={businessId} />
                <Flex
                  direction="row"
                  align="center"
                  justify="space-between"
                  wrap="wrap"
                >
                  <RadioGroup defaultValue="yes">
                    <Stack direction="row" mb={5}>
                      <Radio name="changeBackground" value="yes">
                        Change the background
                      </Radio>
                      <Radio name="changeBackground" value="no">
                        Keep the background
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Spacer flex="0 0 8px" />
                  <Text fontSize="sm">(Optional)</Text>
                  <Input
                    name="postGoal"
                    type="text"
                    placeholder="What is your goal? Ex: 'Show new product'"
                    mb={5}
                    isDisabled={isSubmitting}
                  />
                  <Input
                    isDisabled={isSubmitting}
                    ref={inputRef}
                    type="file"
                    name="file"
                    accept="image/png, image/jpeg"
                  />
                  <Spacer flex="0 0 8px" />
                  <Button
                    type="submit"
                    variant="fill"
                    colorScheme="primary"
                    size="sm"
                    flex="0 0 fit-content"
                    isLoading={isSubmitting}
                  >
                    Add
                  </Button>
                </Flex>
              </form>
            </FormControl>
            {isSubmitting && (
              <Alert status="info" mt={5}>
                <AlertIcon />
                This might take a minute, but no worries! You can keep working
                on your brand and we'll show your new post in your feed as soon
                as it's ready.
              </Alert>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
