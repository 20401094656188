import { ActionDocument, ActionDocumentConverter } from '../action';

export interface RegisterDomainActionData {
  title: string
  url: string
}

export type RegisterDomainActionDocument = ActionDocument<RegisterDomainActionData>;

class RegisterDomainActionDocumentConverter extends ActionDocumentConverter<RegisterDomainActionData> {
  override actionDataToFirestore(actionData: any) : any {
    return {
      registerDomain: {
        title: actionData.tile,
        url: actionData.url,
      },
    };
  }

  override actionDataFromFirestore(brandActionData: any) : RegisterDomainActionData {
    return {
      title: brandActionData.registerDomain!.title,
      url: brandActionData.registerDomain!.url,
    };
  }
}

export const registerDomainActionConverter = new RegisterDomainActionDocumentConverter();
