import React, { SyntheticEvent, useCallback, useState } from 'react';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';

import { CheckoutItem, useApi } from 'src/api';
import { ProfileTier, useProfileTier, useProfileCredits, useBusinessId } from 'src/db';
import { CreditsIcon } from 'src/components';
import { Path, useNavigateWithParams } from 'src/nav';
import { logger, LogSource } from 'src/util/logger';

import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { RequireProfile } from './wrappers/RequireProfile';

export function CreditsPage() {
  return (
    <HeaderFooterPage pageTitle='Credits' >
      <RequireProfile excludeAnonymous={true} OrElse={<NoAccount/>}>
        <CreditsPageContent/>
      </RequireProfile>
    </HeaderFooterPage>
  );
}

function NoAccount() {
  return (<Alert status='error'>
    <AlertIcon />
    <AlertTitle>Account Not Found</AlertTitle>
    <AlertDescription>No user is logged in, or no profile was created</AlertDescription>
  </Alert>);
}

const profileTierName = (tier: ProfileTier): JSX.Element => {
  switch (tier) {
    case ProfileTier.FREE:
      return <><em>Free</em></>;
    case ProfileTier.PAY_AS_YOU_GO:
      return <><em>Brand Kit</em><br />(Pay as you go)</>;
    case ProfileTier.SUBSCRIPTION:
      return <><em>Brand Turbo</em><br />(Subscription)</>;
  }
};

const monthlyCreditAmount = (tier: ProfileTier): JSX.Element => {
  switch (tier) {
    case ProfileTier.FREE:
      return <em>None</em>;
    case ProfileTier.PAY_AS_YOU_GO:
      return <em>None</em>;
    case ProfileTier.SUBSCRIPTION:
      return <em>200</em>;
  }
};

function CreditsPageContent() {
  const [ api, surfaceKnownErrors ] = useApi();
  const profileTier = useProfileTier();
  const profileCredits = useProfileCredits();
  const businessId = useBusinessId();
  const navigate = useNavigateWithParams();
  const [ isRedirecting, setIsRedirecting ] = useState<boolean>(false);

  const addCredits = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (isRedirecting) { return; }
    setIsRedirecting(true);
    if (profileTier === ProfileTier.FREE) {
      api.createCheckoutSession({
        businessId: businessId!,
        checkoutItem: CheckoutItem.SUBSCRIPTION,
        paymentRedirectDomain: window.location.origin + Path.credits
      }, surfaceKnownErrors).then((response) => {
        window.location.assign(response.data.redirectUrl);
      }).finally(() => {
        setIsRedirecting(false);
      });
    } else if(profileTier === ProfileTier.SUBSCRIPTION || profileTier === ProfileTier.PAY_AS_YOU_GO) {
      api.createCheckoutSession({
        businessId: businessId!,
        checkoutItem: CheckoutItem.TOP_UP,
        paymentRedirectDomain: window.location.origin + Path.credits
      }, surfaceKnownErrors).then((response) => {
        window.location.assign(response.data.redirectUrl);
      }).finally(() => {
        setIsRedirecting(false);
      });
    } else {
      logger.error(LogSource.FIRESTORE, `Unknown ProfileTier: ${profileTier}`);
    }
  }, [
    api, surfaceKnownErrors,
    businessId, isRedirecting, profileTier
  ]);

  const goToPricing = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    navigate({ to: Path.pricing });    
  }, [ navigate ]);
  
  const goToAccount = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    navigate({ to: Path.account });    
  }, [ navigate ]);

  if (profileCredits === null) { return <></>; }

  return (<VStack width='100%' align='stretch' spacing={6} mb={6}>
    <Card
      bg='primary.veryLight'
      border='1px solid'
      borderColor='primary.dark'
      p={0} overflow='hidden'
    >
      <CardHeader
        bg='grayscale.white' p={4}
        borderBottomLeftRadius='24px'
        borderBottomRightRadius='24px'
      >
        <Flex direction='row' alignItems='center'>
          <VStack align='start' flex='1 0 0' gap={0}>
            <Text textStyle='body'>You currently have</Text>
            <Text textStyle='displayCreditsLarge'>{profileCredits} credits</Text>
          </VStack>
          <CreditsIcon textStyle='displayCreditsLarge' px={4} py={2} minWidth='80px'>{profileCredits}</CreditsIcon>
        </Flex>
      </CardHeader>
      <CardBody p={4}>
        <VStack>
        <Flex justify='space-between' width='100%'>
            <Text textStyle='body' align='left' flex='2 0 0'>Your current plan</Text>
            <Text textStyle='body' align='right' flex='1 0 0'>
              {profileTierName(profileTier!)}
            </Text>
          </Flex>
          <Flex justify='space-between' width='100%'>
            <Text textStyle='body' align='left' flex='2 0 0'>Your monthly credits</Text>
            <Text textStyle='body' align='right' flex='1 0 0'>
              {monthlyCreditAmount(profileTier!)}
            </Text>
          </Flex>
        </VStack>
      </CardBody>
    </Card>
    <Button
      colorScheme='primary'
      variant='fill'
      onClick={addCredits}
      isLoading={isRedirecting}
    >Add Credits</Button>
    <Button
      colorScheme='primary'
      variant='outline'
      onClick={goToPricing}
    >See Other Plans</Button>
    <Button
      colorScheme='primary'
      variant='outline'
      onClick={goToAccount}
    >See Account Details</Button>
  </VStack>);
}

