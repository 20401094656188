import { ActionDocument, ActionDocumentConverter } from '../action';
import { ImageInfo } from '../common';

export interface ShareSocialMediaPostActionData {
  postImageInfo: ImageInfo;
  caption: string;
}

export type ShareSocialMediaPostActionDocument = ActionDocument<ShareSocialMediaPostActionData>;

class ShareSocialMediaPostActionDocumentConverter extends ActionDocumentConverter<ShareSocialMediaPostActionData> {
  override actionDataToFirestore(actionData: any) : any {
    return {
      shareSocialMediaPost: {
        postImageInfo: actionData.postImageInfo,
        caption: actionData.caption,
      },
    };
  }

  override actionDataFromFirestore(brandActionData: any) : ShareSocialMediaPostActionData {
    return {
      postImageInfo: brandActionData.shareSocialMediaPost!.postImageInfo,
      caption: brandActionData.shareSocialMediaPost!.caption,
    };
  }
}

export const shareSocialMediaPostActionConverter = new ShareSocialMediaPostActionDocumentConverter();
