import { User } from "firebase/auth";
import { FirebaseError } from '@firebase/util';

import { backendConfig } from 'src/firebaseAndBackendConfig';

export enum LogLevel {
  NONE = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
  SEVERE = 4,
}

const logLevelName = (level: LogLevel) : string => {
  switch (level) {
    case LogLevel.NONE:
      return 'none';
    case LogLevel.INFO:
      return 'info';
    case LogLevel.WARN:
      return 'warn';
    case LogLevel.ERROR:
      return 'error';
    case LogLevel.SEVERE:
      return 'severe';
  }
}

export enum LogSource {
  // Anything direcetly from the UI code.
  // Usually these errors are cause by unhandled edge cases of UI state.
  APP = 'APP',
  // Unexpected error responses from the Talawa API.
  API = 'API',
  // Unexpected errors from the firebase Auth libary.
  AUTH = 'AUTH',
  // Unexpected errors from firestore queries,
  // or unexpected state in firestores.
  FIRESTORE = 'FIRESTOR',
  // Anything else, including console attemps from third party code.
  OTHER = 'OTHER',
}

export interface LoggerFirestoreContext {
  profileId?: string|null;
  businessId?: string|null;
}

interface AdditionalOptions {
  // Do not send to backend, regardless of severity.
  silent?: boolean; // default: false
  // Do not output to console, regardless of environment.
  skipConsole?: boolean; // default: false

  // For parody with Error and FirebaseError to quiet tslint.
  message?: string;
  code?: string;
  name?: string;
  stack?: string;
}
const defaultErrorOptions: AdditionalOptions = {
  silent: false,
  skipConsole: false,
} ;

export type ErrorOptions = Error|FirebaseError|AdditionalOptions;

interface OldConsole {
  log: (...messages: any) => void;
  info: (...messages: any) => void;
  warn: (...messages: any) => void;
  error: (...messages: any) => void;
}

const stringify = (message: any) => {
  switch (typeof message) {
    case 'undefined':
      return '<undefined>';
    case 'boolean':
    case 'number':
    case 'bigint':
    case 'string':
    case 'symbol':
    case 'function':
      return message.toString();
    default: { // 'object'
      if (!message) return '<null>';
      try {
        return JSON.stringify(message);
      } catch {
        return '<unparseable>'
      }
    }
  }
}

const consolidateMessages = (...messages: any) : [string, Error?] => {
  let e: Error|null = null;
  const message = [...messages].map((m: any) => {
    if (m instanceof Error) {
      e = m
    }
    return stringify(m);
  }).join(' ');
  if (!!e) {
    return [message, e];
  } else {
    return [message];
  }
}

class Logger {
  static logWebappError = '/logWebappError';
  static reportThreshold = LogLevel.ERROR;

  private currentUser: User|null = null;
  private firestoreContext: LoggerFirestoreContext = {};
  private loggerEndpoint: string;
  private oldConsole: OldConsole;

  updateUser(user: User|null) {
    this.currentUser = user;
  }

  updateFirestoreContext(newContext: LoggerFirestoreContext) {
    Object.assign(this.firestoreContext, newContext);
  }

  constructor(
    private baseUrl: string,
    private loggingToken: string,
    private isProduction: boolean
  ) {
    this.loggerEndpoint = baseUrl + Logger.logWebappError;
    const { log, info, warn, error } = window.console;
    this.oldConsole = { log, info, warn, error };
  
    window.console.log = (...messages: any) => {
      this.log(LogSource.OTHER, ...consolidateMessages(...messages));
    }

    window.console.info = (...messages: any) => {
      this.info(LogSource.OTHER, ...consolidateMessages(...messages));
    }

    window.console.warn = (...messages: any) => {
      this.warn(LogSource.OTHER, ...consolidateMessages(...messages));
    }

    window.console.error = (...messages: any) => {
      this.error(LogSource.OTHER, ...consolidateMessages(...messages));
    }
  }

  private _handleLog(
    logLevel: LogLevel,
    source: LogSource,
    message: string,
    errorOptions?: ErrorOptions,
  ) {
    const error = Object.assign({}, defaultErrorOptions, errorOptions);
    if (!(error as AdditionalOptions).skipConsole) {
      this._oldConsole(logLevel, message, errorOptions);
    }
    if ((error as AdditionalOptions).silent) { return; }
    
    if (logLevel >= Logger.reportThreshold && this.isProduction) {
      const request = this._createRequest(logLevel, source, message, error);
      this._sendLog(request);      
    }
  }

  private _oldConsole(
    logLevel: LogLevel,
    message: string,
    error?: ErrorOptions,
  ) {
    switch (logLevel) {
      case LogLevel.NONE:
        this.oldConsole.log(message, error);
        break;
      case LogLevel.INFO:
        this.oldConsole.info(message, error);
        break;
      case LogLevel.WARN:
        this.oldConsole.warn(message, error);
        break;
      case LogLevel.ERROR:
      case LogLevel.SEVERE:
        this.oldConsole.error(message, error);
        break;
    }
  }

  private _createRequest (
    logLevel: LogLevel,
    source: LogSource,
    message: string,
    error: ErrorOptions,
  ) : Record<string, any> {
    const body: Record<string, any> = {
      userId: this.currentUser?.uid || undefined,
      profileId: this.firestoreContext.profileId || undefined,
      businessId:  this.firestoreContext.businessId || undefined,
    };
    body.createdAt = Date.now();
    body.severity = logLevelName(logLevel);
    body.webappErrorSource = source;
    body.message = message;
    if (!!error) {
      body.errorMessage = error.message || undefined;
      body.errorCode = (error as FirebaseError).code || (error as Error).name || undefined;
      body.traceRoute = (error as FirebaseError).stack || (error as Error).stack || undefined;
    }
    return body;
  }

  private async _sendLog(body: Record<string, any>): Promise<void> {
    let authToken: string;
    if (!!this.currentUser) {
      authToken = await this.currentUser.getIdToken();
    } else {
      authToken = this.loggingToken;
    }
    try {
      const response = await fetch(this.loggerEndpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-type": "application/json",
        },
        mode: "cors",
      });
      if (!response.ok) {
        throw new Error(`${Logger.logWebappError} API Response was not OK`);
      }
    } catch (e) {
      this.oldConsole.error('Logging Error:\n', e);
    }
  }

  // TODO: Maybe hide console output in prod environment
  // but for now they're probably more useful than harmful.

  log(source: LogSource, message: string, error?: ErrorOptions) {
    this._handleLog(LogLevel.NONE, source, message, error);
  }

  info(source: LogSource, message: string, error?: ErrorOptions) {
    this._handleLog(LogLevel.INFO, source, message, error);
  }

  warn(source: LogSource, message: string, error?: ErrorOptions) {
    this._handleLog(LogLevel.WARN, source, message, error);
  }

  error(source: LogSource, message: string, error?: ErrorOptions) {
    this._handleLog(LogLevel.ERROR, source, message, error);
  }

  severe(source: LogSource, message: string, error?: ErrorOptions) {
    this._handleLog(LogLevel.SEVERE, source, message, error);
  }
}

export let logger: Logger;
let hasBeenSetup = false;

export function setupLoggerForEnv() {
  if (hasBeenSetup) {
    logger.error(LogSource.APP, 'Logger was already setup');
  } else {
    hasBeenSetup = true;
    logger = new Logger(
      backendConfig.domainUrl,
      backendConfig.loggingToken,
      process.env.NODE_ENV === 'production'
    );
  } 
}
