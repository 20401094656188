import { useContext } from 'react';
import { PayWallContext, PurchaseDetails } from './contexts';

export function useTriggerPayWall() {
  const { openPayWall, setDetails } = useContext(PayWallContext);
  return (details: PurchaseDetails) => {
    setDetails(details);
    openPayWall();
  };
}
