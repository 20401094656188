import React, { useCallback, useState } from 'react';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  HStack,
  Text,
  TextProps,
  useToast
} from '@chakra-ui/react';

import { ApiResponse, ApiMethods, useApi, useApiPending } from 'src/api';
import { useUser } from 'src/auth';
import { useProfile, useBusiness } from 'src/db';
import { Path, SearchParam, useNavigateWithParams } from 'src/nav';
import { useEffectLate } from 'src/util/async';

import { CreateBusinessRequest, CreateBusinessResponse } from 'src/api/messages/CreateBusiness';
import { AssetChecklist } from './AssetChecklist';
import { CreateBusinessPrefill } from './hooks';

interface CreateBusinessProps extends TextProps {
  prefill: CreateBusinessPrefill,
}
export function CreateBusiness({prefill, ...textProps}: CreateBusinessProps) {
  const navigate = useNavigateWithParams();
  const toast = useToast();
  const [api, surfaceKnownErrors] = useApi();
  // User does exist and will be available by the time of render.
  const userId = useUser(true)!.uid;
  // If Profile exists, it will be available by the time of render.
  const profile = useProfile();
  const isCreatingProfile = useApiPending(ApiMethods.CREATE_PROFILE);
  // Business should not initially exist at the time of render,
  // but may if the user hits the back button.
  const business = useBusiness();
  const isCreatingBusiness = useApiPending(ApiMethods.CREATE_BUSINESS);

  // Prevent the UI from repeatedly calling API in the event of a failure.
  const [hasBlockingError, setHasBlockingError] = useState<boolean>(false);
  const canCreateProfile = !hasBlockingError && !profile && !isCreatingProfile;
  const canCreateBusiness = !hasBlockingError && !!profile && !business && !isCreatingBusiness && !!prefill.description;

  const surfaceKnownErrorsAndBlock = useCallback((e: ApiResponse<any>) => {
    setHasBlockingError(true);
    surfaceKnownErrors(e);
  }, [setHasBlockingError, surfaceKnownErrors]);

  const createProfile = useCallback(() => {
    api.createProfile({ userId }, surfaceKnownErrorsAndBlock);
  }, [api, userId, surfaceKnownErrorsAndBlock]);

  // Note: Ensure profile is created before calling.
  const createBusinessAndNavigate = useCallback(() => {
    if (!prefill.description) {
      toast({
        status: 'error',
        title: 'Missing required param: `description`',
        duration: null,
      });
      setHasBlockingError(true);
    }

    const request: CreateBusinessRequest = {
      profileId: profile!.id,
      businessDescription: prefill.description!,
    };
    if (!!prefill.name) {
      request.businessName = prefill.name;
    }
    if (!!prefill.email) {
      request.emailOnBusinessCreated = prefill.email;
    }
    if (!!prefill.logoUrl) {
      request.businessLogoUrl = prefill.logoUrl;
    }
    api.createBusiness(request, surfaceKnownErrorsAndBlock).then((response: CreateBusinessResponse) => {
      const newBusinessId = response.data.businessId;
      navigate({
        to: Path.updateBrandStory,
        modifySearch: {
          [SearchParam.createBusinessPrefill]: null,
          [SearchParam.claimBusiness]: newBusinessId,
        }
      });
    });
  }, [api, profile, prefill, surfaceKnownErrorsAndBlock, navigate, toast]);

  const createEverything = useCallback(() => {
    if (canCreateProfile) {
      createProfile();
    } else if (canCreateBusiness) {
      createBusinessAndNavigate();
    }
  }, [
    canCreateProfile, canCreateBusiness, createProfile, createBusinessAndNavigate,
  ]);

  useEffectLate(createEverything);

  return (<>
    <Box mb={8} >
      <Text {...textProps}>
        <span style={{display: 'block'}}>
          Creating your AI-powered brand and custom brand agent. Just give us a moment...
        </span>
      </Text>
      {!!prefill.email && <Text>
        <span>Wait here or return via the link emailed to you when it's ready!</span>
        <span style={{display: 'block', textAlign: 'center'}}>
          <em>An email will be sent to: {prefill.email}</em>
        </span> 
      </Text>}
      {!prefill.email &&
        <Alert status='info' display='flex' flexDirection='column' alignItems='flex-start'>
          {/* <HStack>
            <AlertIcon/>
            <AlertTitle>No follow-up email</AlertTitle>
          </HStack> */}
          <AlertDescription>Do not navigate away from this page until finished, or you will lose your progress.</AlertDescription>
        </Alert>
      }
    </Box>
    <AssetChecklist flex='1 0 0'/>
  </>);
}
