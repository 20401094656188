import React, { SyntheticEvent, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Color, ColorResult, SketchPicker } from 'react-color';
import {
  BoxProps,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  StackDivider,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { MdOutlineEdit } from "react-icons/md";

import { useApi } from 'src/api';
import { useBusinessId } from 'src/db';
import { getTextColor } from 'src/util/color';

export function AddColorsAssetButton({children, ...buttonProps}: ButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

  const onSelection = (colorsHexCodes: string[]) => {
    if (isSubmitting) { return; }
    setIsSubmitting(true);
    api.addColorAsset({
      businessId, colorsHexCodes
    }, surfaceKnownErrors).then(() => {
      onClose();
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (<>
    <Button
      {...buttonProps}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        onOpen();
      }}
    >{children}</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent minHeight='96vh'>
        <ModalHeader p={0}>
          <Flex direction='row' justify='center' position='relative' my={4}>
            <Text textStyle='titleForSection'>Add Your Own</Text>
            <ModalCloseButton top={'50%'} bottom={'50%'} />
          </Flex>
          <Divider orientation='horizontal' borderColor='grayscale.medium'/>
        </ModalHeader>
        <ModalBody>
          <MultiColorPicker
            onSelection={onSelection}
            onCancel={onClose}
            isSubmitting={isSubmitting}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  </>);
}

const EditIcon = () => {
  return (<Icon
    as={MdOutlineEdit}
    boxSize='28px'
    borderRadius='full'
    color='grayscale.white'
    bg='rgba(0, 0, 0, 0.2)'
    p={1} mr={2}
  />);
}

// Clones the list while removing any falsy values.
const cloneWithoutNull = (colorList: Array<string|null>): Array<string> => {
  return colorList.reduce<Array<string>>((list, next) => {
    if (!!next) { list.push(next); } 
    return list;
  }, []);
}

// This component is the top level color picker that lets the user
// select between a color in their list to edit.
//
// Its `onSelection` callback passes in the list of all (5) colors.
interface MultiColorPickerProps extends BoxProps {
  onSelection: (colorList: string[]) => void;
  onCancel: () => void;
  isSubmitting: boolean;
}
function MultiColorPicker({
  onSelection, onCancel, isSubmitting, ...boxProps
}: MultiColorPickerProps) {
  const [ selectedIndex, setSelectedIndex ] = useState<number|null>(null);
  const [ colorsList, setColorsList ] = useState<string[]>([]);

  const handleSelection = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (isSubmitting || !colorsList.length) { return ; } 
    onSelection(colorsList);
  }, [onSelection, isSubmitting, colorsList]);

  const handleCancel = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    onCancel();
  }, [onCancel]);
  
  // Selects the target index, or the closest available.
  const editAtIndex = useCallback((idx: number) => {
    setSelectedIndex(Math.min(idx, colorsList.length));
  }, [colorsList]);

  const updateValue = useCallback((idx: number, newValue: string|null, ) => {
    setColorsList((oldList) => {
      const newList: Array<string|null> = [...oldList];
      if (!!newList[idx]) {
        newList[idx] = newValue;
      } else {
        newList.push(newValue);
      }
      return cloneWithoutNull(newList);
    });
  }, []);

  const getButtonProps = useCallback((idx: number): ButtonProps => {
    const baseProps: ButtonProps = {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      borderColor: 'grayscale.medium',
      textStyle: 'buttonXLarge',
      onClick: (e: SyntheticEvent) => {
        e.stopPropagation();
        editAtIndex(idx);
      }
    };

    if (!!colorsList[idx]) {
      return {
        ...baseProps,
        justifyContent: 'end',
        border: 'none',
        bg: `${colorsList[idx]}`,
        children: [<EditIcon />],
      }
    } else {
      return {
        ...baseProps,
        justifyContent: 'center',
        border: '1px solid', 
        bg: 'grayscale.light',
        children: [`${idx+1}`],
      };
    }
  }, [colorsList, editAtIndex]);

  if (selectedIndex === null) {
    return (<VStack spacing={0}>
      <Text textStyle='bodyLarge' align='center' mt={6} mb={6}>
        Set All Colors
      </Text>
      <Text textStyle='body2' align='center' mb={4}>
        Tap on each space to set a color
      </Text>
      <Button key={1} {...getButtonProps(0)} />
      <Button key={2} {...getButtonProps(1)} />
      <Button key={3} {...getButtonProps(2)} />
      <Button key={4} {...getButtonProps(3)} />
      <Button key={5} {...getButtonProps(4)} />
      <Button
        key='cancel'
        variant='link'
        colorScheme='primary'
        onClick={handleCancel}
      >Cancel</Button>
      <Divider orientation='horizontal' borderColor='grayscale.medium' mb={2}/>
      <Button
        key='submit'
        variant='fill'
        colorScheme='primary'
        width='100%'
        isDisabled={!colorsList.length}
        isLoading={isSubmitting}
        onClick={handleSelection}
      >Complete Color Palette</Button>
    </VStack>);
  } else {
    return (<VStack spacing={0} width='100%'>
      <Text textStyle='bodyLarge' align='center' mt={6} mb={6}>
        Set Color {selectedIndex+1}
      </Text>
      <Text textStyle='body2' align='center' mb={4}>
        Use the color slider to display a color family and tap on your preferred shade in the box
      </Text>
      <SelectedColorPicker 
        selectedColor={colorsList[selectedIndex] || undefined}
        onSelectColor={(color: string) => {
          updateValue(selectedIndex, color);
          setSelectedIndex(null);
        }}
        onRemove={() => {
          updateValue(selectedIndex, null);
          setSelectedIndex(null);
        }}
        onCancel={() => {
          setSelectedIndex(null);
        }}
        width='100%'
      />;
    </VStack>);
  }
}

// This component allows the user to edit a single color.
//
// Its `onSelect` callback passes in a single color
// (for parent component to edit its list with).
interface SelectedColorPickerProps extends BoxProps {
  selectedColor: string|undefined;
  onSelectColor: (color: string) => void;
  onRemove: () => void;
  onCancel: () => void;
}
function SelectedColorPicker({
  selectedColor, onSelectColor, onRemove, onCancel, ...boxProps
}: SelectedColorPickerProps) {
  const [ currentColor, setCurrentColor ] = useState<string|undefined>(selectedColor);

  const handleSelectColor = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (!currentColor) { return; }
    onSelectColor(currentColor);
  }, [onSelectColor, currentColor]);

  const handleRemove = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (!selectedColor) { return; }
    onRemove();
  }, [onRemove, selectedColor]);

  const handleCancel = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    onCancel();
  }, [onCancel]);

  return (<VStack spacing={0} width='100%'>
    <TalawaColorPicker
      currentColor={currentColor}
      onChangeColor={setCurrentColor}
    />;
      <HStack align='center' divider={<StackDivider borderColor='grayscale.medium' />}>
        {!!selectedColor && <Button
          key='remove'
          variant='ghost'
          color='system.error.dark'
          onClick={handleRemove}
        >Remove</Button>}
        <Button
          key='cancel'
          variant='ghost'
          colorScheme='primary'
          onClick={handleCancel}
        >Cancel</Button>
      </HStack>
      <Divider orientation='horizontal' borderColor='grayscale.medium' mb={2} />
      <Button
        variant='outline'
        width='100%'
        isDisabled={!currentColor}
        onClick={handleSelectColor}
        bg={!!currentColor ? currentColor : 'grayscale.light'}
        color={!!currentColor ? getTextColor(currentColor) : 'grayscale.dark'}
        _hover={{
          opacity: !!currentColor ? '0.9' : undefined,
        }}
      >Set Color</Button>
  </VStack>);
}

// This is a wrapper around the 3p Color picker widget.
//
// It just exposes the current color selection in a ref.
interface TalawaColorPickerProps extends BoxProps {
  currentColor: string|undefined;
  onChangeColor: (newColor: string) => void;
}
function TalawaColorPicker({currentColor, onChangeColor, ...boxProps}: TalawaColorPickerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [ pickerWidth, setPickerWidth] = useState<string>('0');
  const handleChange = useCallback((newColor: ColorResult, _e: any) => {
    const newHexColor: Color = newColor.hex;
    onChangeColor(newHexColor);
  }, [onChangeColor]);

  useLayoutEffect(() => {
    const { width } = containerRef.current!.getBoundingClientRect();
    setPickerWidth(`${width - 24}px`); // -the built in padding that can't be configured.
  }, [containerRef]);

  return (<Center ref={containerRef} width='100%' {...boxProps}>
    <SketchPicker
      color={currentColor}
      onChangeComplete={handleChange}
      disableAlpha={true}
      presetColors={[]}
      width={pickerWidth}
    />
  </Center>);
}
