import React from 'react';

import { BoxProps } from '@chakra-ui/react';

import { MAX_SELECTED_NAMES }  from 'src/api/constants';
import { AddNameAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, NameAssetDocument, nameAssetConverter } from 'src/db';
import { Path } from 'src/nav';

import { BrandKitCard } from './BrandKitCard'
import { ActionableTextAssets } from './ActionableTextAssets';
import { UpdateNameAssetButton } from 'src/components/assets/updateAsset';

export function NameCard({...boxProps}: BoxProps) {
  return (
    <BrandKitCard<NameAssetDocument>
      title='Name'
      assetUiName='Name'
      assetType={AssetTypes.name}
      firestoreConverter={nameAssetConverter}
      reviewPath={Path.name}
      SelectedAssets={ActionableTextAssets}
      SuggestedAssets={ActionableTextAssets}
      selectionLimit={MAX_SELECTED_NAMES}
      selectionLimitErrorMessage={
        `Limit of ${MAX_SELECTED_NAMES} Names. Remove one before adding another`
      }
      AddAssetButton={AddNameAssetButton}
      UpdateAssetButton={UpdateNameAssetButton}
      {...boxProps}
    />
  );
}
