import React, { useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

import { Path, SearchParam } from './fragments';
import { NavigateProps, useNavigateWithParams } from './hooks';


interface RedirectProps extends NavigateProps {
  // An (optional) message to show the user before redirecting.
  // Note: this may not render at all if the redirect is too fast.
  message?: string;
}

/**
 * Calls navigate in an effect.
 * 
 * Prefer calling navigate directly, but this is available for when a
 * returning a component is more comnvenient.
 */ 
export function Redirect({
  message = '',
  ...redirectProps
}: RedirectProps) {
  const navigate = useNavigateWithParams();

  useEffect(() => {
    navigate({...redirectProps});
  }, [ navigate, redirectProps ]);
  return (<>
    {!!message && <Text display='block'>
      <em>{message}</em>
    </Text>}
    <Text display='block'>
      {`Redirecting to ${redirectProps.to}...`}  
    </Text>
  </>);
}

// Convenience component for most common redirect use case.
export function RedirectThroughSignup() {
  const redirectParams = {
    [SearchParam.redirectTo]: useLocation().pathname,
  };

  return (<Redirect
    message='Login required'
    to={Path.signup}
    modifySearch={redirectParams}
  />);
}
