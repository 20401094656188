import React, { ChangeEvent, FormEvent, KeyboardEvent, useCallback, useContext, useEffect, useState, useRef } from 'react';

import {
  Box,
  BoxProps,
  Icon,
  IconButton,
  Textarea,
} from '@chakra-ui/react'
import { MdSend } from 'react-icons/md'

import { ConversationContext } from './context';


export function MessageInput({...boxProps}: BoxProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { sendMessage } = useContext(ConversationContext);
  const [value, setValue] = useState('');

  const onSend = useCallback(() => {
    const messageBody = value.trim();
    setValue('');
    sendMessage({
      source: 'human',
      body: messageBody,
    });
  }, [value, sendMessage]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }, []);

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a newline chrracter on the keyUp.
      onSend();
    }
  }, [onSend]);

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    onSend();
  }, [onSend]);

  useEffect(() => {
    inputRef.current?.setAttribute('enterkeyhint', 'send');
  }, [inputRef]);

  return (
    <Box width='100%' position='relative' {...boxProps}>
      <form onSubmit={handleSubmit}>
        <Textarea
          ref={inputRef}
          value={value}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          bg='grayscale.light'
          borderRadius='md'
          focusBorderColor='secondary.light'
          placeholder='Enter text here'
          textStyle='input'
          resize='none'
          rows={3}
        />
        <IconButton
          type='submit'
          variant='fill'
          position='absolute'
          bottom='12px'
          right='12px'
          boxSize='32px'
          icon={<Icon
            as={MdSend}
            style={{transform: 'rotate(-45deg)'}}
            boxSize='20px'
          />}
          aria-label='Send'
          isDisabled={!value}
          isRound={true}
          zIndex={1}
        ></IconButton>
      </form>
    </Box>
  );
}
