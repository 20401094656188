import { AssetDocument, AssetDocumentConverter } from "../asset";

export interface BrandStoryAssetValue {
  brandStories: string;
}

export type BrandStoryAssetDocument = AssetDocument<BrandStoryAssetValue>;

class BrandStoryAssetDocumentConverter extends AssetDocumentConverter<BrandStoryAssetValue> {
  override assetValueFromFirestore(assetValue: any): BrandStoryAssetValue {
    return { brandStories: assetValue.brandStories };
  }

  override renderLabel(value: BrandStoryAssetValue): string {
    return value.brandStories;
  }
}

export const brandStoryAssetConverter = new BrandStoryAssetDocumentConverter();
