import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react'

import { PayWallContext, PurchaseDetails, PayWallProps } from './contexts';

export function PayWallProvider({ children }: PropsWithChildren) {
  const [ details, setDetails ] = useState<PurchaseDetails>({});
  const {
    isOpen: isPayWallOpen,
    onOpen: openPayWall,
    onClose: closePayWall,
  } = useDisclosure();

  const props: PayWallProps = useMemo(() => {
    return {
      isPayWallOpen, openPayWall, closePayWall,
      details, setDetails,
    };
  }, [
    isPayWallOpen, openPayWall, closePayWall,
    details, setDetails,
  ]);

  return (
    <PayWallContext.Provider value={props}>
      {children}
    </PayWallContext.Provider>
  );
}
