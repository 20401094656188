import React, { useState, useEffect } from "react";

import { useApi } from "src/api";
import {
  UpdateAssetDataRequest,
  UpdateAssetDataResponse,
} from "src/api/messages/UpdateAssetData";
import { useSelectAsset } from "src/components/assets/actions";

import { AssetTypes, BrandStoryAssetDocument, useAssetContext } from "src/db";
import { Path, useNavigateWithParams } from "src/nav";

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";

export function UpdateBrandStoryPage() {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [brandStory, setBrandStory] = useState<BrandStoryAssetDocument | null>(
    null
  );
  const navigate = useNavigateWithParams();
  const { collection: assets } = useAssetContext<BrandStoryAssetDocument>(
    AssetTypes.brandStory
  );
  const selectAsset = useSelectAsset();

  useEffect(() => {
    if (isUpdating) {
      return;
    }
    // Randomly select one asset from assets
    const asset = assets
      ? assets[Math.floor(Math.random() * assets.length)]
      : null;
    if (asset !== undefined && asset !== null) {
      setBrandStory(asset);
    }
  }, [assets, isUpdating]);

  const [api, surfaceKnownErrors] = useApi();

  // Handle the button click by updating the brand story in "asset"
  const handleSaveAndContinue = () => {
    if (brandStory === undefined || brandStory === null) {
      return;
    }
    setIsUpdating(true);
    const requestData: UpdateAssetDataRequest = {
      businessId: brandStory.businessId,
      assetId: brandStory.id,
      assetType: AssetTypes.brandStory,
      assetData: {
        assetValue: {
          textToUpdate: brandStory["value"]["brandStories"],
        },
      },
    };
    api
      .updateAssetData(requestData, surfaceKnownErrors)
      .then((response: UpdateAssetDataResponse) => {
        selectAsset(brandStory)
          .then(() => {
            navigate({ to: Path.updateBusinessDifferentiator });
          })
          .catch((error) => {
            console.error(error);
          }).finally(() => {
            setIsUpdating(false);
          });
      })
      .catch(() => {
        setIsUpdating(false);
      });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newBrandStoryValue = event.target.value;
    setBrandStory(
      Object.assign({}, brandStory, {
        value: { brandStories: newBrandStoryValue },
      })
    );
  };

  return (
    <HeaderFooterPage
      pageTitle="Review and Refine"
      backTo={Path.home}
      backLabel="Brand Hub"
    >
      <Card mb={12} p={6}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center">
            Brand Story
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2}>
              Your brand story captures what you stand for. Refine the draft
              below.
            </Text>
            {brandStory && !isUpdating ? (
              <Textarea
                placeholder="Write your brand story here"
                size="lg"
                width="100%"
                height="300px"
                resize="none"
                value={brandStory.value.brandStories}
                onChange={handleInputChange}
              />
            ) : (
              <Text textStyle="bodySmall" align="center">
                Your brand story is loading...
              </Text>
            )}
          </VStack>
        </CardBody>
        <CardFooter>
          <Button
            colorScheme="primary"
            variant="fill"
            minWidth="200px"
            mx="auto"
            onClick={handleSaveAndContinue}
            isLoading={isUpdating || brandStory === null}
          >
            Save And Continue
          </Button>
        </CardFooter>
      </Card>
    </HeaderFooterPage>
  );
}
