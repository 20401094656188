import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'

import { PayWallContext } from './contexts';
import { HandleCheckoutResponse } from './HandleCheckoutResponse';
import { PayWallContent } from './PayWallContent';

export function PayWall() {
  const props = useContext(PayWallContext);
  return (<>
    <HandleCheckoutResponse />
    <Modal
      isOpen={props.isPayWallOpen}
      onClose={props.closePayWall}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <PayWallContent {...props}/>
    </Modal>
  </>);
}
