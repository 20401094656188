import React from 'react';

import { BoxProps } from '@chakra-ui/react';

import { MAX_SELECTED_SLOGANS }  from 'src/api/constants';
import { AddSloganAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, SloganAssetDocument, sloganAssetConverter } from 'src/db';
import { Path } from 'src/nav';

import { BrandKitCard } from './BrandKitCard'
import { ActionableTextAssets } from './ActionableTextAssets';
import { UpdateSloganAssetButton } from 'src/components/assets/updateAsset';

export function SloganCard({...boxProps}: BoxProps) {
  return (
    <BrandKitCard<SloganAssetDocument>
      title='Slogan'
      assetUiName='Slogan'
      assetType={AssetTypes.slogan}
      firestoreConverter={sloganAssetConverter}
      reviewPath={Path.slogan}
      SelectedAssets={ActionableTextAssets}
      SuggestedAssets={ActionableTextAssets}
      selectionLimit={MAX_SELECTED_SLOGANS}
      selectionLimitErrorMessage={
        `Limit of ${MAX_SELECTED_SLOGANS} Slogans. Remove one before adding another`
      }
      AddAssetButton={AddSloganAssetButton}
      UpdateAssetButton={UpdateSloganAssetButton}
      {...boxProps}
    />
  );
}
