import { useContext } from 'react';
import { Api } from './Api';
import { ApiMethods } from './ApiMethods';
import { ApiContext, ApiPendingContext } from './contexts';
import { ErrorSurfacer, useSurfaceKnownErrors } from './util/errors';

export function useApi(): [Api, ErrorSurfacer] {
  return [useContext(ApiContext), useSurfaceKnownErrors()];
}

export function useApiPending(method: ApiMethods): boolean {
  return useContext(ApiPendingContext).has(method);
}
