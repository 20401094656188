import {extendBaseTheme, theme as baseTheme} from '@chakra-ui/react'

import { Button } from './Button';
import { Card } from './Card';
import { Divider } from './Divider';
import { Tag } from './Tag';
import { Tooltip } from './Tooltip';
import colors from './colors';
import textStyles from './textStyles';

// To reduce JS payload size, only include components we use.
const { 
  Alert, // For Toast component
  Avatar,
  Checkbox,
  Drawer,
  Form,
  FormLabel,
  Input,
  Link,
  List,
  Modal,
  Popover,
  Progress,
  Radio,
  Skeleton,
  Spinner,
  Tabs,
  Textarea, 
} = baseTheme.components

const customShadows = {
  section: '0px 4px 56px 0px rgba(133, 193, 193, 0.50)',
  card: '0px 4px 20px 0px rgba(21, 142, 153, 0.20)',
  // based on secondary.dark, #FEA16D
  highlightCard: '0px 4px 20px 0px rgba(254, 161, 109, 0.60)',
  fab: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}

const extendedTheme = extendBaseTheme({
  components: {
    Alert, // For Toast component
    Avatar,
    Button,
    Checkbox,
    Card,
    Divider,
    Drawer,
    Form,
    FormLabel,
    Input,
    Link,
    List,
    Modal,
    Radio,
    Skeleton,
    Spinner,
    Tabs,
    Tag,
    Textarea, 
    Tooltip,
    Popover,
    Progress: {
      ...Progress,
      baseStyle: {
        filledTrack: {
          bg: 'linear-gradient(90deg, var(--chakra-colors-secondary-light) 0%, var(--chakra-colors-primary-dark) 100%)'
        },
        track: {
          bg: 'grayscale.white',
          borderRadius: 'md',
        },
      },
    },
  },
  colors,
  textStyles,
  shadows: {
    ...baseTheme.shadows,
    ...customShadows,
  },
  styles: {
    global: {
      body: {
        color: colors.text.body,
      },
      em: {
        fontStyle: 'normal',
        fontWeight: '700',
      },
      '#root': {
        backgroundColor: colors.grayscale.offwhite,
        boxShadow: 'section',
      }
    },
  },
});
export default extendedTheme;
