import React from 'react';

import { Center, Tag, TagLabel } from '@chakra-ui/react';

import { MAX_SELECTED_SLOGANS }  from 'src/api/constants';
import { AddSloganAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, SloganAssetDocument } from 'src/db';

import { AssetProps, DetailPage, RedirectToProps } from './DetailPage';
import { Path } from 'src/nav';

const redirectBack: RedirectToProps = {
  to: Path.name,
};

const redirectTo: RedirectToProps = {
  to: Path.color,
};

export function SloganPage() {
  return <DetailPage<SloganAssetDocument>
    title='Slogans'
    assetUiName='Slogan'
    assetType={AssetTypes.slogan}
    SelectedAsset={SelectedSlogan}
    SuggestedAsset={SuggestedSlogan}
    selectionLimit={MAX_SELECTED_SLOGANS}
    selectionLimitErrorMessage={
      `Maximum of ${MAX_SELECTED_SLOGANS} selected Slogans. Unselect one before selecting another`
    }
    AddAssetButton={AddSloganAssetButton}
    redirectTo={redirectTo}
    redirectBack={redirectBack}
  />
}

function SelectedSlogan({ asset }: AssetProps<SloganAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.slogan}</TagLabel>
    </Tag>
  </Center>);
}

function SuggestedSlogan({ asset }: AssetProps<SloganAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.slogan}</TagLabel>
    </Tag>
  </Center>);
}
