import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; 
import { Path, SearchParam } from './fragments';

type ModifySearch = Partial<Record<SearchParam, string|null>>;

export interface NavigateProps {
  // The path to rediect to
  // This will replace the current path,
  // but preserve any SearchParams, unless specified below
  to: Path;
  
  // SearchParam (in the format {name: value}) to add before navigating.
  //
  // If a value is null, it will be removed.
  modifySearch?: ModifySearch;
}

/**
 * Navigate to a given path
 * 
 * SearchParam in the URL will be preserved, unless specified
 * in `modifySearch`.
 */
export function useNavigateWithParams() {
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();

  return useCallback(({
    to, modifySearch = {},
  }: NavigateProps) => {
    Object.entries(modifySearch).forEach(([name, value]) => {
      if (!!value) {
        searchParams.set(name, value);
      } else {
        searchParams.delete(name);
      }
    });
    navigate({
      pathname: to,
      search: `?${searchParams}`,
    });
  }, [navigate, searchParams]);
}

/**
 * Convenience hook for retrieving a single SearchParam value.
 */
export function useSearchParam(param: SearchParam): string|null {
  const [ searchParams ] = useSearchParams();
  return searchParams.get(param) || null;
}

type UpdateSearchParamFn = (previous: string|null) => string|null;

/**
 * Convenience hook for retrieving a single SearchParam value,
 * as well as an updateState method that will apply to the URL.
 */
export function useSearchParamState(searchParam: SearchParam)
    : [string|null, (newValueOrUpdate: string|null|UpdateSearchParamFn) => void] {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const currentValue = searchParams.get(searchParam);
  const setValue = useCallback((newValueOrUpdate: string|null|UpdateSearchParamFn) => {
    let newValue: string|null;
    if (typeof newValueOrUpdate === 'function') {
      newValue = (newValueOrUpdate as UpdateSearchParamFn)(currentValue);
    } else {
      newValue = (newValueOrUpdate as string|null);
    }
    setSearchParams((previous) => {
      if (newValue === currentValue) { return previous; }
      if (!!newValue) {
        previous.set(searchParam, newValue);
      } else {
        previous.delete(searchParam); 
      }
      return previous;
    });
  }, [searchParam, setSearchParams, currentValue]);
  return [currentValue, setValue];
}

/**
 * Convenience hook for the `redirectTo` Param with a default,
 * already typed as a Path.
 */
export function useRedirectParam(defaultPath: Path): Path {
  const paramValue = useSearchParam(SearchParam.redirectTo);
  if (!!paramValue) {
    return paramValue as Path;
  } else {
    return defaultPath;
  }
}

/**
 * Helper function to create a `ModifySearch` Record that removes
 * all of the specified params.
 */
export function withOutSearchParams(removals: SearchParam[]) : ModifySearch {
  const modifySearch: ModifySearch = {};
  removals.forEach((name) => {
    modifySearch[name] = null;
  });
  return modifySearch;
}
