import React from 'react';

import { Center } from '@chakra-ui/react';

import { MAX_SELECTED_FONTS } from 'src/api/constants';
import { AssetTypes, FontAssetDocument } from 'src/db';
import { FontSample } from 'src/components/assets';

import { AssetProps, DetailPage, RedirectToProps } from './DetailPage';
import { Path } from 'src/nav';
import { AddFontAssetButton } from 'src/components/assets/addAsset/AddFontAsset';

const redirectBack: RedirectToProps = {
  to: Path.audience,
};
const redirectTo: RedirectToProps = {
  to: Path.logo,
};

export function FontPage() {

  const RenderFont = ({ asset, idx }: AssetProps<FontAssetDocument>) => {
    return (<Center width='100%' p={4}>
      <FontSample
        font={asset}
        size='32px'
      />
    </Center>)
  };

  return <DetailPage<FontAssetDocument>
    title='Fonts'
    assetUiName='Font'
    assetType={AssetTypes.font}
    SelectedAsset={RenderFont}
    SuggestedAsset={RenderFont}
    selectionLimit={MAX_SELECTED_FONTS}
    AddAssetButton={AddFontAssetButton}
    redirectTo={redirectTo}
    redirectBack={redirectBack}
  />
}
