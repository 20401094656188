import React from 'react';

import { Center, Tag, TagLabel } from '@chakra-ui/react';

import { MAX_SELECTED_VALUES } from 'src/api/constants';
import { AddValueAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, ValueAssetDocument } from 'src/db';

import { AssetProps, DetailPage, RedirectToProps } from './DetailPage';
import { Path, SearchParam } from 'src/nav';

const redirectBack: RedirectToProps = {
  to: Path.color
};

const redirectTo: RedirectToProps = {
  to: Path.brandkit,
  modifySearch: {
    [SearchParam.congratulations]: "true",
  }
};

export function ValuePage() {
  return <DetailPage<ValueAssetDocument>
    title='Values'
    assetUiName='Value'
    assetType={AssetTypes.value}
    SelectedAsset={SelectedValue}
    SuggestedAsset={SuggestedValue}
    selectionLimit={MAX_SELECTED_VALUES}
    selectionLimitErrorMessage={
      `Maximum of ${MAX_SELECTED_VALUES} selected Values. Unselect one before selecting another`
    }
    AddAssetButton={AddValueAssetButton}
    redirectTo={redirectTo}
    redirectBack={redirectBack}
  />
}

function SelectedValue({ asset }: AssetProps<ValueAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{ display: 'inline-flex' }}>{asset.value.brandValue}</TagLabel>
    </Tag>
  </Center>);
}

function SuggestedValue({ asset }: AssetProps<ValueAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{ display: 'inline-flex' }}>{asset.value.brandValue}</TagLabel>
    </Tag>
  </Center>);
}
