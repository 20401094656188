import React from 'react';

import { BoxProps } from '@chakra-ui/react'

import { Message } from './chat/Message';
import { ConversationContext } from './chat/context';
import { useSimpleConversation } from './chat/hooks';
import { MessageBox } from './chat/MessageBox';


interface SimpleMessageBoxProps extends BoxProps {
  messages: Message[];
}

/**
 * Styled message box for displaying a non-interactive list of messages
 */
export function SimpleMessageBox({messages, ...boxProps}: SimpleMessageBoxProps) {
  const conversation = useSimpleConversation(messages);
  return (<ConversationContext.Provider value={conversation}>
    <MessageBox {...boxProps} />
  </ConversationContext.Provider>);
}
