import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import {
  Button,
  ButtonProps,
  Popover,
  PopoverTrigger,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  useToast,
} from '@chakra-ui/react';

import { useAuth } from 'src/auth';
import { Path, useNavigateWithParams } from 'src/nav';

interface ResetPasswordButtonProps extends ButtonProps {
  // If email missing or empty, will show a warning instead resetting.
  email?: string;

  // Runs when the outer button is clicked.
  // Can be used to prompt the user for an email first.
  onOpen?: () => void; 

  // Send the user to /logout after resetting. Defaults to true.
  logoutAfter?: boolean;
}
export function ResetPasswordButton({
  email = '',
  onOpen = () => {},
  logoutAfter = true,
  children,
  ...buttonProps
} : ResetPasswordButtonProps) {
  const toast = useToast();
  const navigate = useNavigateWithParams();
  const [auth, errorHandler] = useAuth();
  const [isResetting, setIsRessetting] = useState<boolean>(false);

  const resetPassword = () => {
    if (isResetting) { return; }
    if (!email) { return }
    setIsRessetting(true);
    sendPasswordResetEmail(auth, email).then(() => {
      toast({
        status: 'info',
        description: 'Sent Reset Link',
      });
      if (logoutAfter) {
        navigate({to: Path.logout});
      }
    }).catch(errorHandler).finally(() => {
      setIsRessetting(false);
    });
  };

  return (<Popover>
    <PopoverTrigger>
      <Button
        variant='link'
        colorScheme='primary'
        onClick={() => {onOpen();}}
        {...buttonProps}
      >{children || 'Reset Password?'}</Button>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverCloseButton />
      <PopoverHeader textStyle='titleForWidget'>Reset Password</PopoverHeader>
      <PopoverBody>
        {!!email ?
          <p>Are you sure you want to reset the password for <em>{email}</em>&nbsp;?</p> :
          <p><em>Enter an email first</em></p>
        }
      </PopoverBody>
      {!!email && <PopoverFooter>
        <Button
          variant='outline'
          colorScheme='primary'
          isLoading={isResetting}
          loadingText='Resetting'
          onClick={() => resetPassword()}
        >Send Reset Link</Button>
      </PopoverFooter>}
    </PopoverContent>
  </Popover>);
}
