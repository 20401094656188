import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)

type Size = 'md'|'sm'|'lg';

// Note: Chakra UI doesn't appear to offer any type checking
// for custom color schemes or variants

// `colorScheme` is either 'primary' or 'secondary'. 'primary' is default.
// Currently no implementation for system ('error'|'warn'|'success') schemes.

// `variants` are one of 'solid'|'outline'|'subtle'. 'subtle' is default.

function textStyle(size: Size) {
  switch(size) {
    case 'sm':
      // PLACEHOLDER. No current example in figma
      return 'chipSmall';
    case 'lg':
      // PLACEHOLDER. No current example in figma
      return 'buttonLarge';
    case 'md': 
    default:
      return 'chip';
  }
}

const sizes = {
  md: definePartsStyle({ container: {
    textStyle: textStyle('md'),
    borderRadius: 'lg',
    p: '12px',
  }}),
  // PLACEHOLDER. No current example in figma
  sm: definePartsStyle({ container: {
    textStyle: textStyle('sm'),
    borderRadius: 'md',
    p: '8px',
  }}),
  // PLACEHOLDER. No current example in figma
  lg: definePartsStyle({ container: {
    textStyle: textStyle('lg'),
    borderRadius: 'xl',
    p: '16px',
  }}),
};

const fillVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return { container: {
    bg: `${c}.dark`,
    color: 'grayscale.white',
  }}
});

const outlineVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return { container: {
    bg: 'grayscale.white',
    color: `${c}.dark`,
    borderColor: `${c}.dark`,
    borderStyle: 'solid',
    borderWidth: '1px',
  }}
});

const subtleVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return { container: {
    bg: 'grayscale.light',
    color: `${c}.dark`,
  }}
});

const baseStyle = definePartsStyle({});

export const Tag = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: {
    fill: fillVariant,
    outline: outlineVariant,
    subtle: subtleVariant,
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
    variant: 'subtle',
  },
});
