import {
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Icon,
  VStack,
  HStack,
} from '@chakra-ui/react'

import { SlClose } from 'react-icons/sl'

import { PayWallProps } from './contexts';
import {Path} from 'src/nav'

import { useApi } from 'src/api';
import { useBusinessId } from 'src/db';
import { CheckoutItem } from 'src/api/messages/CreateCheckoutSession';
import { useCallback, useState } from 'react';

export function PayWallContent({ closePayWall, details }: PayWallProps) {
  const [api, surfaceKnownErrors] = useApi();

  const businessId = useBusinessId()!;
  const checkoutItem = CheckoutItem.SUBSCRIPTION;
  const [isRedirecting, setRedirecting] = useState(false);

  const goToSubscriptionStripeCheckout = useCallback(() => {
    if (isRedirecting) { return }
    setRedirecting(true);
    api.createCheckoutSession({
      businessId: businessId!,
      checkoutItem: checkoutItem!,
      paymentRedirectDomain: window.location.origin + Path.home
    }, surfaceKnownErrors).then((response) => {
      window.location.assign(response.data.redirectUrl);
    }).finally(() => {
      closePayWall(); 
      setRedirecting(false);
    });
  }, [
    api, surfaceKnownErrors,
    businessId, checkoutItem, isRedirecting, closePayWall
  ]);

  return (<ModalContent
    bg='grayscale.white'
    display='flex'
    flexDirection='column'
    px={4}
  >
    <ModalCloseButton color='primary.medium'>
      <Icon as={SlClose} boxSize='1.5em' />
    </ModalCloseButton>
    <ModalHeader>
      <Text align='center' textStyle='display2'>You've Used Your Free Credits</Text>
    </ModalHeader>
    <ModalBody>
      <VStack>
        <HStack alignItems='stretch'>
            <VStack align='center'>
              <Text align='center' textStyle='body' mb={4}>
                Keep building your Brand Kit. Get Daily Actions to grow your brand. 
                Manage your brand on and offline with a subscription or brand kit bundle. 
              </Text>
              <Text align='center' textStyle='body2'>
                Subscribe (recommended) or Pay as you go to unlock these actions
              </Text>
              <Button
                variant='fill'
                width='100%'
                mb={4}
                isLoading={isRedirecting}
                loadingText='Creating checkout...'
                onClick={() => {goToSubscriptionStripeCheckout(); }}
              >Proceed to Checkout</Button>
              <Text align='center' textStyle='reminder'>
                Happy with what we've shown you already?
              </Text>
              <Text align='center' textStyle='bodyLarge' mb={2}>
                Subscribe or purchase a Brand Kit to download 
                and use these assets however you like.
              </Text>
            </VStack>
        </HStack>
      </VStack>
    </ModalBody>
  </ModalContent>);
}
