import React, { PropsWithChildren, useContext } from "react";

import { BusinessContext } from 'src/db';

import { AwaitProfile } from './AwaitProfile'
import { LargeProgress } from './LargeProgress';

/**
 * Ensures children will have access to a loaded BusinessContext
 * However, the context may be null.
 */
export function AwaitBusiness({children} : PropsWithChildren) {
  const businessContext = useContext(BusinessContext);

  return <AwaitProfile>
    {businessContext.isLoading ?  <LargeProgress /> : children }
  </AwaitProfile>
}
