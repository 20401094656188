import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const baseProps: TextProps = {
  as: 'span',
  align: 'center',
  background: '#EF7A6C',
  borderBottomLeftRadius: '1em',
  borderTopRightRadius: '1em',
  color: '#FFFFFF',
  display: 'inline-block',
  textStyle: 'displayCreditsIcon',
  px: 2, py: 1,
  minWidth: '40px',
}

export function CreditsIcon({ children, ...textProps }: TextProps ) {
  return <Text {...{...baseProps, ...textProps}}>{children} Credits</Text>;
}
