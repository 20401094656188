import React from 'react';
import { Button, ButtonProps, Text } from '@chakra-ui/react';

import { useBusiness, useBusinessName } from 'src/db';

export function DownloadBrandKitButton({...buttonProps} : ButtonProps) {
  const business = useBusiness()!;
  const businessName = useBusinessName() || 'Brand Kit';
  const downloadUrl = business.brandKitZipInfo?.zipUrl || '';

  if (!downloadUrl) {
    return (<Text width='100%' textAlign='center'>
      Creating Download File...
    </Text>);
  }
  return (<Button
    as='a'
    href={downloadUrl}
    download={businessName}
    colorScheme='primary'
    variant='fill'
    minWidth='200px'
    mx='auto'
    {...buttonProps}
  >Download Brand Kit</Button>);
}
