import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';

import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Progress,
  Spacer,
  Text,
  TextProps,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { MdCheckCircleOutline } from 'react-icons/md';

import { useApi } from 'src/api';
import { DownloadImageButton, ShareImageButton, canUseNavigatorShare, useClipboardWithToast } from 'src/components';

import { ShareSocialMediaPostActionDocument } from 'src/db';

interface SocialMediaPostProps extends BoxProps {
  post: ShareSocialMediaPostActionDocument;
}
export function ShareSocialMediaPost({ post, ...boxProps }: SocialMediaPostProps) {
  const image = post.actionData.postImageInfo.imageUrl;
  const caption = post.actionData.caption;
  const { isOpen, onOpen, onClose, } = useDisclosure(); 

  const openInspector = useCallback((e: SyntheticEvent) => {
    if (post.isPreview) { return; }
    e.stopPropagation();
    onOpen();
  }, [post, onOpen]);

  return (<>
    <Box width='100%' {...boxProps}>
      <VStack align='center' width='100%'>
        <Box bg='grayscale.dark' position='relative' width='100%' >
          <Image
            src={image}
            alt={caption}
            role='button'
            maxHeight='160px'
            mx='auto'
            onClick={openInspector}
          />
        </Box>
        <Text color='grayscale.black' textStyle='label'>{caption}</Text>
      </VStack>
    </Box>
    <InpectorModal post={post} isInspectorOpen={isOpen} closeInspector={onClose}/>
  </>);
}

// Just the Image. No caption or review options.
export function SocialMediaPostPreview({ post, ...boxProps }: SocialMediaPostProps) {
  const image = post.actionData.postImageInfo.imageUrl;
  const caption = post.actionData.caption;
  const { isOpen, onOpen, onClose, } = useDisclosure(); 

  const openInspector = useCallback((e: SyntheticEvent) => {
    if (post.isPreview) { return; }
    e.stopPropagation();
    onOpen();
  }, [post, onOpen]);

  return (<>
    <Box bg='grayscale.dark' position='relative' width='100%' {...boxProps}>
      <Image
        src={image}
        alt={caption}
        role='button'
        maxHeight='160px'
        mx='auto'
        onClick={openInspector}
      />
    </Box>
    <Text>{shorterCaption(caption)}</Text>
    <InpectorModal post={post} isInspectorOpen={isOpen} closeInspector={onClose}/>
  </>);
}

function shorterCaption(caption: string) : string {
  const maxChars = 50;
  if (caption.length > maxChars) {
    return caption.slice(0, maxChars) + "..."
  }
  else {
    return caption
  }
}

interface InpectorModalProps {
  post: ShareSocialMediaPostActionDocument;
  isInspectorOpen: boolean;
  // Should set `isInspectorOpen` to false;
  closeInspector: () => void;
}
function InpectorModal({post, isInspectorOpen, closeInspector} : InpectorModalProps) {
  return (
    <Modal
      isOpen={isInspectorOpen}
      onClose={closeInspector}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text textStyle='titleForSection' align='center'>Share</Text>
        </ModalHeader>
        <ModalCloseButton top='16px' right='16px'/>
        <ModalBody mb={6}>
          <InspectSocialMediaPost post={post} onComplete={() => { closeInspector(); }}/>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export interface InspectSocialMediaPostProps extends SocialMediaPostProps {
  // Function to run after downloading or sharing.
  onComplete?: () => void;
}
export function InspectSocialMediaPost({post, onComplete}: InspectSocialMediaPostProps) {
  const imageUrl = post.actionData.postImageInfo.imageUrl;
  const caption = post.actionData.caption;

  const [ api, surfaceKnownErrors ] = useApi();
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const editAreaRef = useRef<HTMLParagraphElement>(null);

  const [ nextCaption, setNextCaption ] = useState<string>('');
  const { onCopy } = useClipboardWithToast(caption, 'Caption copied');
  const [ isEditMode, setIsEditMode ] = useState<boolean>(false);
  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);
  const [ isCompleting, setIsCompleting ] = useState<boolean>(false);

  const enterEditMode = () => {
    setIsEditMode(true);
    setTimeout(() => {
      editAreaRef.current?.focus();
    }, 0);
  };

  const exitEditMode = () => {
    setIsEditMode(false);
    setTimeout(() => {
      editButtonRef.current?.focus();
    }, 0);
  };

  const hasNextCaption = !!nextCaption && nextCaption !== caption;

  const updateCaption = () => {
    if (!hasNextCaption) { return; }
    setIsUpdating(true);
    api.updateBrandActionSocialMediaPost({
      businessId: post.businessId,
      brandActionId: post.id,
      socialMediaPostDataToUpdate: {
        caption: nextCaption
      },
    }, surfaceKnownErrors).finally(() => {
      setIsUpdating(true);
      exitEditMode();
    });
  };

  const captionStyleProps: TextProps = {
    textStyle: 'label',
    color: 'grayscale.black',
    bg: 'grayscale.light',
    p: 2,
  }

  const markComplete = useCallback(() => {
    if (isCompleting) { return; }
    setIsCompleting(true);
    api.completeBrandAction({
      businessId: post.businessId,
      brandActionId: post.id,
      isCompleted: true
    }, surfaceKnownErrors).finally(() => {
      setIsCompleting(false);
      if (!!onComplete) { onComplete(); }
    });
  }, [post, api, surfaceKnownErrors, isCompleting, onComplete]);

  return (<VStack>
    <Box bg='grayscale.dark' width='100%' >
      <Image
        src={imageUrl}
        alt={caption}
        width='100%'
        maxWidth='400px'
        mx='auto'
      />
    </Box>
    {isEditMode ?
      <Text
        key='next'
        {...captionStyleProps}
        ref={editAreaRef}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={(e) => {
          setNextCaption(e.currentTarget.textContent || '');
        }}
      >{caption}</Text> :
      <Text
        key='current'
        {...captionStyleProps}
      >{caption}</Text>
    }
    {isEditMode ?
      <Flex direction='row' width='100%'>
        <Button
          key='save'
          variant='fill'
          colorScheme='primary'
          flexGrow={1}
          isDisabled={!hasNextCaption}
          isLoading={isUpdating}
          onClick={() => {
            updateCaption();
          }}
        >Save</Button>
        <Spacer width='8px'/>
        <Button
          key='cancel'
          variant='outline'
          colorScheme='primary'
          flexGrow={1}
          isDisabled={isUpdating}
          onClick={() => {
            exitEditMode();
          }}
        >Cancel</Button>
      </Flex> :
      <Flex direction='row' width='100%'>
        <Button
          key='edit'
          variant='outline'
          colorScheme='primary'
          flexGrow={1}
          ref={editButtonRef}
          onClick={() => {
            enterEditMode();
          }}
        >Edit</Button>
        <Spacer width='8px'/>
        <Button
          key='copy'
          variant='fill'
          colorScheme='primary'
          flexGrow={1}
          onClick={onCopy}
        >Copy</Button>
      </Flex>
    }
    <Divider my={4}/>
    {isCompleting ? <Progress width='100%' isIndeterminate={true} /> : 
      <CompleteButtonStack
        imageUrl={imageUrl}
        caption={caption}
        fileName={post.id}
        onComplete={markComplete}
      />
    }
  </VStack>);
}

interface CompleteButtonStackProps {
  imageUrl: string;
  caption: string;
  fileName: string;
  onComplete: () => void;
}

function CompleteButtonStack({
  imageUrl, caption, fileName, onComplete
} : CompleteButtonStackProps) {
  return <>
    {canUseNavigatorShare() && <ShareImageButton
      variant='fill'
      colorScheme='primary'
      imageUrl={imageUrl}
      caption={caption}
      width='100%'
      onComplete={onComplete}
    >Share Post</ShareImageButton>}
    <DownloadImageButton
      variant='outline'
      colorScheme='primary'
      imageUrl={imageUrl}
      fileName={fileName}
      width='100%'
      onComplete={onComplete}
      textDecoration='underline'
    >Download Image</DownloadImageButton>
    <Button
      variant='outline'
      colorScheme='primary'
      width='100%'
      onClick={onComplete}
    >
      <Icon as={MdCheckCircleOutline} />&nbsp;
      <span>Mark Complete</span>
    </Button>
  </>
}