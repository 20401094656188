import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';
import { ImageInfo } from './common';

export enum ProfileTier {
  FREE = 'free',
  PAY_AS_YOU_GO = 'pay_as_you_go',
  SUBSCRIPTION = 'subscription',
}

export interface ProfileInfo {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  profileImageInfo?: ImageInfo;
}

interface HasAccepted {
  accepted: boolean;
  version?: string;
}

export interface ProfileDocument {
  id: string;
  userId: string;
  tier: ProfileTier;

  profileInfo: ProfileInfo;   

  termsOfService: HasAccepted;
  privacyPolicy: HasAccepted;
}

export const profileConverter: FirestoreDataConverter<ProfileDocument> = {
  toFirestore (document: ProfileDocument) {
    return {
      userId: document.userId,
    };
  },

  fromFirestore (snapshot: DocumentSnapshot, options: SnapshotOptions): ProfileDocument{
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      userId: data.userId,
      tier: data.tier as ProfileTier,
      profileInfo: (!!data.profileInfo ? data.profileInfo : {}),
      termsOfService: (!!data.termsOfService?.accepted ?
        data.termsOfService : { accepted: false }),
      privacyPolicy: (!!data.privacyPolicy?.accepted ?
        data.privacyPolicy : { accepted: false }),
    };
  },
};
