import { useEffect } from 'react';

import {
  useClipboard,
  useToast
} from '@chakra-ui/react';

export function useClipboardWithToast(value: string, message?: string) {
  const toast = useToast();
  const clipboardProps = useClipboard(value);
  
  useEffect(() => {
    if (!clipboardProps.hasCopied) { return ;}
    toast({
      description: message || 'Copied to Clipboard'
    });
  }, [message, toast, clipboardProps.hasCopied]);

  return clipboardProps;
}