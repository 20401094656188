import React from "react";

import { Progress } from '@chakra-ui/react';

export function LargeProgress() {
  return (<Progress
    isIndeterminate={true}
    width='50%'
    my={8} mx='auto'
  />);
}
