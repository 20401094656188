import React from 'react';

import {
  Box,
  BoxProps,
  Card,
  Flex,
  VStack,
} from '@chakra-ui/react';

import { AssetOriginTag } from 'src/components/assets';
import { AssetActions } from 'src/components/assets/actions';
import { AssetTypes, ColorsAssetDocument, colorsAssetConverter } from 'src/db';
import { AddColorsAssetButton } from 'src/components/assets/addAsset';
import { ColorGrid } from 'src/components/assets';
import { Path } from 'src/nav';

import { AssetsProps, BrandKitCard } from './BrandKitCard'

export function ColorsCard({...boxProps}: BoxProps) {

  const SuggestedColors = ({assets}: AssetsProps<ColorsAssetDocument>) => {
    return (<Box width='100%'>
      {assets.map((colors) => {
        return (<Card key={colors.id} width='100%' p={4} mb={2}>
          <VStack justify='center' align='stretch'>
            <ColorGrid colors={colors} width='100%'/>
            <Flex direction='row' justify='space-between'> 
              <AssetOriginTag origin={colors.origin}/>
              <AssetActions asset={colors}/>
            </Flex>
          </VStack>
        </Card>);
      })}
    </Box>)
  };
  
  // No difference in display on the asset itself.
  const SelectedColors = SuggestedColors;

  return (
    <BrandKitCard<ColorsAssetDocument>
      title='Colors'
      assetType={AssetTypes.color}
      firestoreConverter={colorsAssetConverter}
      reviewPath={Path.color}
      SelectedAssets={SelectedColors}
      SuggestedAssets={SuggestedColors}
      AddAssetButton={AddColorsAssetButton}
      {...boxProps}
    />
  );
}
