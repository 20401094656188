import React, { useEffect, useRef } from 'react';
import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import { IoMdClose, IoMdHeart } from 'react-icons/io'
import { swipingHand } from 'src/images';

const storageKey = 'viewedLogoTutorial';

export function HowItWorksButton({...buttonProps}: ButtonProps) {
  const ctaRef = useRef<HTMLButtonElement>(null);
  const localStorage = window.localStorage;
  const hasViewedTutorial = localStorage.getItem(storageKey);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!hasViewedTutorial) {
      onOpen();
      localStorage.setItem(storageKey, 'true');
    }
  }, [localStorage, hasViewedTutorial, onOpen]);

  return (<>
    <Button
      {...buttonProps}
      onClick={() => { onOpen() }}
    >How It Works</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={ctaRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text textStyle='titleForSection' align='center'>How Logo Selection Works</Text>
        </ModalHeader>
        <ModalBody>
          <Text textStyle='display3' align='center' mb={2}>
            1. Move Logos
            <Text textStyle='body'>(or use the buttons)</Text>
          </Text>
          <Flex
            direction='row'
            justify='center'
            align='start'
            mb={2}
          >
            <VStack>
              <IconButton
                as='div'
                role='image'
                icon={<Icon
                  as={IoMdClose}
                  boxSize='48px'
                  color={'grayscale.white'} 
                  style={{strokeWidth: '1px'}}
                />}
                aria-label='Dislike'
                bg={'secondary.dark'}
                boxSize='80px'
                color='grayscale.white'
                isRound={true}
              ></IconButton>
              <Text textStyle='body2' align='center' color='secondary.dark'>
                To the left that you&nbsp;
                <em>don't like</em>
              </Text>
            </VStack>
            <Image
              src={swipingHand}
              alt='Swiping hand'
            />
            <VStack>
              <IconButton
                as='div'
                role='image'
                icon={<Icon
                  as={IoMdHeart}
                  boxSize='48px'
                  color={'grayscale.white'} 
                  style={{strokeWidth: '1px'}}
                />}
                aria-label='Like'
                bg={'primary.light'}
                boxSize='80px'
                isRound={true}
              ></IconButton>
              <Text textStyle='body2' align='center' color='primary.dark'>
                To the right that you&nbsp;
                <em>do like</em>
              </Text>
            </VStack>
          </Flex>
          <Divider mt={5} mb={5} />
          <Text textStyle='display3' align='center' mb={2}>
            2. Select your favorite logo from your Likes
          </Text>
          <Text textStyle='body2' align='center'>
          All your likes and dislikes are always available below the swiper. You can change your answers at any time.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            ref={ctaRef}
            variant='fill'
            colorScheme='primary'
            width='100%'
            onClick={() => { onClose() }}
          >Got It!</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
}
