import React from "react";

import { BoxProps } from "@chakra-ui/react";

import { MAX_SELECTED_BRAND_STORIES } from "src/api/constants";
import { AddBrandStoryAssetButton } from "src/components/assets/addAsset";
import { AssetTypes, BrandStoryAssetDocument, brandStoryAssetConverter} from "src/db";
import { Path } from "src/nav";

import { BrandKitCard } from "./BrandKitCard";
import { ActionableTextAssets } from "./ActionableTextAssets";
import { UpdateBrandStoryAssetButton } from "src/components/assets/updateAsset";

export function BrandStoryCard({ ...boxProps }: BoxProps) {
  return (
    <BrandKitCard<BrandStoryAssetDocument>
      title="Brand Story"
      assetUiName="Brand Story"
      assetType={AssetTypes.brandStory}
      firestoreConverter={brandStoryAssetConverter}
      reviewPath={Path.brandStory}
      SelectedAssets={ActionableTextAssets}
      SuggestedAssets={ActionableTextAssets}
      selectionLimit={MAX_SELECTED_BRAND_STORIES}
      selectionLimitErrorMessage={`Limit of ${MAX_SELECTED_BRAND_STORIES} Brand Stories. Remove one before adding another`}
      AddAssetButton={AddBrandStoryAssetButton}
      UpdateAssetButton={UpdateBrandStoryAssetButton}
      {...boxProps}
    />
  );
}
