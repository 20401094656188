import { PropsWithChildren, useContext } from "react";
import { collection, orderBy, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import {
  FirestoreContext, ReportsContext, ReportsContextProperties,
  unavailableReportsContext,
} from './contexts';
import { useBusinessId } from './hooks';
import { ReportDocument, reportConverter } from "./model/report";

const brandReports = 'reports';

export function ReportsProvider({ children }: PropsWithChildren) {
  const businessId = useBusinessId();
  return (
    !businessId ?
      <ReportsContext.Provider value={unavailableReportsContext}>
        {children}
      </ReportsContext.Provider> :
      <ReportsProviderInner businessId={businessId}>
        {children}
      </ReportsProviderInner>
  );
}

interface ReportsProviderInnerProps {
  businessId: string;
}

export function ReportsProviderInner({
  businessId, children
}: PropsWithChildren<ReportsProviderInnerProps>) {
  const fs = useContext(FirestoreContext);
  const businessIdFilter = where('businessId', '==', businessId);
  const [
    reportCollection, reportIsLoading,
  ] = useCollectionData<ReportDocument>(
    query<ReportDocument>(
      collection(fs, brandReports)
        .withConverter<ReportDocument>(
          reportConverter
        ),
      businessIdFilter,
      orderBy('createdAt', 'desc')
    )
  );

  const providedValue: ReportsContextProperties = {
    collection: reportCollection || [],
    isLoading: reportIsLoading
  }

  return (
    <ReportsContext.Provider value={providedValue}>
      {children}
    </ReportsContext.Provider>
  );
}