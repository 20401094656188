import React, { useContext, PropsWithChildren } from "react";

import { BusinessContext } from 'src/db';
import { RedirectThroughSignup } from 'src/nav';

import { AwaitBusiness } from './AwaitBusiness';
import { Requirement } from './Requirement';
import { RequireProfile } from './RequireProfile';
import { RequireBusinessProps } from './Props';

/**
 * Ensures children will have access to a loaded and non-null BusinessContext
 */
export function RequireBusiness({...requireProfileProps} : RequireBusinessProps) {
  return (
    <RequireProfile {...requireProfileProps}>
      <AwaitBusiness>
        <RequireBusinessInner {...requireProfileProps} />
      </AwaitBusiness>
    </RequireProfile>
  );
}

function RequireBusinessInner({...requirementProps} : RequireBusinessProps) {
  const business = useContext(BusinessContext).business!;
  const hasBusiness = !!business;
  
  return <Requirement {...requirementProps} metRequirement={hasBusiness} />;
}

/**
 * Convenience export for the most common use case
 */
export function RequireBusinessWithSignup({ children } : PropsWithChildren) {
  return (
    <RequireBusiness
      excludeAnonymous={true}
      withAcceptedTerms={true}
      OrElse={<RedirectThroughSignup />}
    >
      {children}
    </RequireBusiness>
  );
}

/**
 * Special case for checking only if a business exists,
 * and nothing else in the heirarcy.
 * 
 * This is used for pages in the invitation workflow. 
 */
export function RequireBusinessOnly({ ...requirementProps } : RequireBusinessProps) {
  return (
    <AwaitBusiness>
      <RequireBusinessInner {...requirementProps}/>
    </AwaitBusiness>
  );
}
